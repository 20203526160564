/* App */
export const CLEAR_REDIRECT_TAB_ID = 'CLEAR_REDIRECT_TAB_ID';

/* BlockedClient */
export const SET_BLOCK_CLIENTS = 'SET_BLOCK_CLIENTS';
export const SET_BLOCKABLE_INTERFACES = 'SET_BLOCKABLE_INTERFACES';

/* Client */
export const SET_CLIENTS = 'SET_CLIENTS';

/* FaultyDeviceReplacement */
export const SET_RESTORE_DEVICE = 'SET_RESTORE_DEVICE';

/* Initial */
export const EDIT_INITIAL_SETTING = 'EDIT_INITIAL_SETTING';
export const SET_MACHINE_INFO = 'SET_MACHINE_INFO';
export const SET_REGION = 'SET_REGION';
export const SET_ACCOUNT = 'SET_ACCOUNT';
export const SET_DISPLAY_LANGUAGE = 'SET_DISPLAY_LANGUAGE';
export const SET_INIT_PATH_NAME = 'SET_INIT_PATH_NAME';
export const SET_SATELLITE_LIST = 'SET_SATELLITE_LIST';
export const SET_INIT_MIRO_LIST = 'SET_INIT_MIRO_LIST';
export const SET_STEP = 'SET_STEP';
export const SET_IS_DEVICE_REBOOTING = 'SET_IS_DEVICE_REBOOTING';
export const SET_REDIRECTING = 'SET_REDIRECTING';

/* Parental Control */
export const SET_PARENTAL_CONTROL_CLIENTS = 'SET_PARENTAL_CONTROL_CLIENTS';
export const SET_ROLES = 'SET_ROLES';
export const START_EDITING = 'START_EDITING';

/* Quwan */
export const SET_CONNECTION_STATUS = 'SET_CONNECTION_STATUS';
export const SET_ORGANIZATIONS = 'SET_ORGANIZATIONS';
export const SET_SITES = 'SET_SITES';
export const SET_REGIONS = 'SET_REGIONS';
export const SET_SETTINGS = 'SET_SETTINGS';
export const SET_DEVICE_ON_QUNMS = 'SET_DEVICE_ON_QUNMS';

/* Cloud */
export const SET_CLOUD_SERVICE = 'SET_CLOUD_SERVICE';

/* QuNMS */
export const SET_DPI_APP = 'SET_DPI_APP';
export const SET_DPI_CLIENT_OSS = 'SET_DPI_CLIENT_OSS';

/* QoS */
export const SET_USER_RULES = 'SET_USER_RULES';
export const SET_SEGMENT_RULES = 'SET_SEGMENT_RULES';
export const SET_PRIORITY_PERCENTAGES = 'SET_PRIORITY_PERCENTAGES';
export const SET_REBOOT_REQUIRED = 'SET_REBOOT_REQUIRED';

/* Air Gap */
export const SET_AIR_GAP_DEVICES = 'SET_AIR_GAP_DEVICES';
export const SET_AIR_GAP_PORT_IDS = 'SET_AIR_GAP_PORT_IDS';

/* Terms of Use */
export const SET_TERMS_OF_USE_INFO = 'SET_TERMS_OF_USE_INFO';
