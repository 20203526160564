import { Nat } from '@/services';
import { mergeDataWithKeys } from '@/common/utilities';

const storeState = {
  algConfigs: [],
  algConsts: {
    configDataKeys: ['type', 'enabled'],
  },
};

const storeGetters = {
  findAlgConfig: (state) => (type) => {
    const config = state.algConfigs.find((item) => item.type === type);

    return config || null;
  },
};

const mutations = {
  initAlgConfigs(state, algConfigs) {
    state.algConfigs = algConfigs;
  },
};

const actions = {
  async getAlgConfigs({ commit }) {
    const algRes = await Nat.getAlg();

    commit('initAlgConfigs', algRes.result);
  },
  async putAlgConfig({ state }, algConfig) {
    await Nat.putAlg([algConfig]);
  },
  async toggleAlgConfigEnabled({ state, getters, dispatch }, type) {
    const config = getters.findAlgConfig(type);
    const newConfig = mergeDataWithKeys(state.algConsts.configDataKeys, config, {});

    newConfig.enabled = !newConfig.enabled;
    await dispatch('putAlgConfig', newConfig);
  },
};

export default {
  namespaced: true,
  state: storeState,
  getters: storeGetters,
  mutations,
  actions,
};
