/* eslint-disable import/prefer-default-export */
import {
  convertMacAddressToInteger,
  convertIntegerToMacAddress,
} from './network';

/**
 * Convert the MAC address of the backhaul interface to MAC address of WAN interface
 * @param {string} backhaulMacAddress - MAC address of the backhaul interface
 * @returns {string} MAC address of the WAN interface
 */
export function convertBackhaulToWanMacAddress(backhaulMacAddress) {
  const decimalInteger = convertMacAddressToInteger(backhaulMacAddress);

  if (Number.isNaN(decimalInteger)) {
    return '';
  }

  // The backhaul MAC address is the third of five interfaces.
  const MINIMUM_BACKHAUL_INTEGER = 2;

  // 48: Total bits the MAC address needs
  const MAXIMUM_MAC_ADDRESS = (2 ** 48) - 1;

  // MAC addresses need to be reserved for the two interfaces following the backhaul interface.
  const MAXIMUM_BACKHAUL_INTEGER = MAXIMUM_MAC_ADDRESS - 2;

  if (decimalInteger < MINIMUM_BACKHAUL_INTEGER || decimalInteger > MAXIMUM_BACKHAUL_INTEGER) {
    return '';
  }

  // Display the MAC address of first interface(WAN) as node MAC address
  const macNumber = convertMacAddressToInteger(backhaulMacAddress) - 2;

  return convertIntegerToMacAddress(macNumber).toUpperCase();
}
