import { IPv4 } from 'ipaddr.js';
import { Base64 } from 'js-base64';
import {
  get as getCookie,
  set as setCookie,
  remove as removeCookie,
} from 'js-cookie';
import { cloneDeep } from 'lodash';
import { CUSTOM_ERROR_TYPE } from '@/common/consts';
import NOTIFY_LEVEL from '@/enums/NotifyLevel';
import PROMISE_STATUS from '@/enums/PromiseStatus';
import SVG_EYE_OFF from '@/assets/icons/ic_component_login_eye_off.svg';
import SVG_EYE_ON from '@/assets/icons/ic_component_login_eye_on.svg';
import { setTokenStartingUsingTime } from './apiTokenMonitor';

const TOKEN_COOKIE_NAME = 'miroToken';
const TOKEN_INIT_COOKIE_NAME = 'isInitToken';

const MIN_PORT_NUMBER = 1;
const MAX_PORT_NUMBER = 65535;
const MAXIMUM_PORTS_LENGTH = 15;
const INTEGER_RANGE_PATTERN = /^\d+-\d+$/;
const FW_VERSION_PATTERN = /^(?:\d+\.){3}\d+$/;
const MAC_ADDRESS_PATTERN = /^[0-9A-Fa-f]{2}([-:][0-9A-Fa-f]{2}){5}$/;

export const rootFontSizeVariable = '--default-font-size';

// regex got from https://vuejs.org/v2/cookbook/form-validation.html#Using-Custom-Validation
export const EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * Get eye on or off icon
 *
 * @param {'text'|'password'} type - type of input field
 * @returns {Object} svg icon
 */
export function getEyeIcon(type) {
  return (type === 'text') ? SVG_EYE_OFF : SVG_EYE_ON;
}

/**
 * Check MAC address value valid
 *
 * @returns {boolean} True for valid MAC address
 */
export function checkMacAddressValid(value) {
  if (MAC_ADDRESS_PATTERN.test(value)) {
    return true;
  }

  return false;
}

/**
 * Check IP value valid.
 * note: 120.100.023.21 is not valid IP
 * @returns {boolean} True for valid IP
 */
export function checkIpValid(value) {
  return IPv4.isValidFourPartDecimal(value);
}

/**
 * Check whether current authentication token is from Initialize
 * @returns {boolean} True if current authentication token is from Initialize
 */
export function getIsInit() {
  return getCookie(TOKEN_INIT_COOKIE_NAME) === 'true';
}

/**
 * Set flag to mark current authentication token is from Initialize
 * @param {boolean} [flag] - "true" to set flag as true, undefined to clear flag
 * @returns {undefined}
 */
export function setIsInit(flag) {
  if (flag) {
    setCookie(TOKEN_INIT_COOKIE_NAME, flag.toString());
  } else {
    removeCookie(TOKEN_INIT_COOKIE_NAME);
  }
}

/**
 * Delete legacy password cookie
 */
export function deleteLegacyPasswordCookie() {
  removeCookie('miroPW');
  removeCookie('miroRemme');
}

/**
 * Get token for API authentication
 *
 * @returns {string} Token for API authentication
 */
export function getAuthorization() {
  return getCookie(TOKEN_COOKIE_NAME);
}

/**
 * Store token for API authentication in cookie
 *
 * @param {string} token - Token for API authentication
 * @returns {undefined}
 */
export function setAuthorization(token) {
  setCookie(TOKEN_COOKIE_NAME, token);
  setTokenStartingUsingTime(Date.now());
}

/**
 * Clear the stored token for API authentication from cookie
 *
 * @returns {undefined}
 */
export function clearAuthorization() {
  removeCookie(TOKEN_COOKIE_NAME);
}

/**
 * Indicate whether user is authenticated
 *
 * @returns {boolean} True if user is authenticated
 */
export function hasAuthorization() {
  return !!getAuthorization();
}

/**
 * Convert bits to readable string with different network rate unit
 *
 * @param {number} bits
 * @returns {Object} converted num and unit
 */
export function bitToNetworkSize(bits) {
  const units = ['bps', 'Kbps', 'Mbps', 'Gbps', 'Tbps', 'Pbps', 'Ebps', 'Zbps', 'Ybps'];
  const exp = Math.log(bits) / Math.log(1024) | 0;
  const value = (bits / (1024 ** exp)).toFixed(2);

  return {
    num: value,
    unit: units[exp],
  };
}

/**
 * Convert bytes to readable string with different network rate unit
 *
 * @param {number} bytes
 * @returns {string} converted rate
 */
export function getNetworkRate(bytes) {
  const rate = bitToNetworkSize(bytes * 8);

  return `${rate.num} ${rate.unit}`;
}

export function Mb1000ToUnitSize(Mb1000) {
  const unit = ['M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
  let value = 0;
  let i = 0;

  for (; i < unit.length; i++) {
    value = Mb1000 / (1000 ** i);

    if (value < 1000) break;
  }

  return {
    num: Math.round(value * 100) / 100,
    unit: unit[i], /* , unitStr:unitStr[i] */
  };
}

/**
 * Compare the versions and control whether to compare full versions.
 * This function refers the implementation of .sort()
 * Doc: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
 * @param {string} ver1 - The version 1
 * @param {string} ver2 - The version 2
 * @param {boolean} checkFullVersion - If true, compare the full version.
 *                                     Take the version "a.b.c.d" as an example:
 *                                     If true, compare "a.b.c.d", else only compare "a.b.c".
 * @returns {number} 1: ver1 > ver2, 0: ver1 = ver2, -1: ver1 < ver2, NaN: invalid format
 */
function handleVersionCompare(ver1, ver2, checkFullVersion) {
  if (!FW_VERSION_PATTERN.test(ver1) || !FW_VERSION_PATTERN.test(ver2)) {
    return NaN;
  }
  const array1 = ver1.split('.');
  const array2 = ver2.split('.');

  // the full version is "a.b.c.d", only check "a.b.c" if don't check ths full version
  for (let i = 0; i < array1.length + (checkFullVersion ? 0 : -1); i++) {
    const i1 = parseInt(array1[i], 10);
    const i2 = parseInt(array2[i], 10);

    if (i1 > i2) {
      return 1;
    }

    if (i1 < i2) {
      return -1;
    }
  }

  return 0;
}

/**
 * Compare the versions.
 * @param {string} ver1 - The version 1
 * @param {string} ver2 - The version 2
 * @returns {number} 1: ver1 > ver2, 0: ver1 = ver2, -1: ver1 < ver2, NaN: invalid format
 */
export function versionCompare(ver1, ver2) {
  return handleVersionCompare(ver1, ver2, false);
}

/**
 * Check whether the version of firmware 1 is older than firmware 2
 * @param {string} fwVersion1 - The version of firmware 1 to check
 * @param {string} fwVersion2 - The version of firmware 2 to check
 * @returns {boolean} True if the version of firmware 1 is older than firmware 2
 */
export function checkFwVersionIsOlder(fwVersion1, fwVersion2) {
  return versionCompare(fwVersion1, fwVersion2) === -1;
}

/**
 * Check whether the full version of firmware 1 is older than firmware 2
 * @param {string} fwVersion1 - The version of firmware 1 to check
 * @param {string} fwVersion2 - The version of firmware 2 to check
 * @returns {boolean} True if the full version of firmware 1 is older than firmware 2
 */
export function checkFullFwVersionIsOlder(fwVersion1, fwVersion2) {
  return handleVersionCompare(fwVersion1, fwVersion2, true) === -1;
}

/**
 * Convert IP to integer for comparison
 * Base on: https://gist.github.com/jppommet/5708697
 * Bitwise Operators ref:
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Bitwise_Operators
 *
 * @param {string} ip - IP address to convert (x.x.x.x)
 * @returns {number} IP value
 */
export function convertIpToInt(ip) {
  if (checkIpValid(ip)) {
    const chunks = ip.split('.');
    const ipInt = chunks.reduce((accu, chunk) => {
      // shift 8 bit to add next chunk of IP
      const shiftedInt = accu << 8;

      return shiftedInt + parseInt(chunk, 10);
    }, 0);

    // shifted integer with leading value of 1 will be consider negative. Make it positive.
    return ipInt >>> 0;
  }

  return 0;
}

/**
 * Check whether IP is in subnet
 *
 * @param {string} ip - IP to check
 * @param {string} subnetIp - IP of subnet
 * @param {string|number} subnetMask - Mask of subnet
 * @returns {boolean} True if IP belongs to subnet
 */
export function isIpInSubnet(ip, subnetIp, subnetMask) {
  if (checkIpValid(ip) && checkIpValid(subnetIp) && subnetMask) {
    return IPv4.parse(ip).match(IPv4.parse(subnetIp), subnetMask);
  }

  return false;
}

/**
 * Indicate whether given string is a valid IP network
 *
 * Format: x.x.x.x/xx
 * - 1st part (x.x.x.x) must be a valid IP address
 * - 2nd part (xx) must be a valid subnet mask value (0-32)
 *
 * @param {string} value - IP network to validate
 * @returns {boolean} True if valid IP network
 */
export function isIpNetwork(value) {
  if (typeof value === 'string') {
    const network = value.split('/');

    if (network.length === 2) {
      const mask = parseInt(network[1], 10);

      return checkIpValid(network[0])
        && !Number.isNaN(mask)
        && mask >= 0
        && mask <= 32;
    }
  }

  return false;
}

/**
 * Convert port string to integer for comparison
 *
 * @param {string} port - Port to convert
 * @returns {number} Port value
 */
export function convertPortToInt(port) {
  return Number(port);
}

/**
 * Indicate whether given string is a valid port
 *
 * @param {string} port - Port to validate
 * @returns {boolean} True if valid port
 */
export function isPort(port) {
  const portValue = convertPortToInt(port);

  if (!Number.isInteger(portValue)) {
    return false;
  }

  return MIN_PORT_NUMBER <= portValue && portValue <= MAX_PORT_NUMBER;
}

/**
 * Indicate whether given string is a valid range of ports
 *
 * Format: x-y
 * - 1st part (x) must be a valid port
 * - 2nd part (y) must be a valid port greater than 1st one
 *
 * @param {string} value - Port range to validate
 * @returns {boolean} True if valid port range
 */
export function isPortRange(value) {
  if (INTEGER_RANGE_PATTERN.test(value)) {
    const range = value.split('-');

    return isPort(range[0])
      && isPort(range[1])
      && convertPortToInt(range[0]) < convertPortToInt(range[1]);
  }

  return false;
}

/**
 * Indicate whether given string is a valid range of IPs
 *
 * Format: x.x.x.x-y.y.y.y
 * - 1st part (x.x.x.x) must be a valid IP address
 * - 2nd part (y.y.y.y) must be a valid IP address greater than 1st one
 *
 * @param {string} value - IP range to validate
 * @returns {boolean} True if valid IP range
 */
export function isIpRange(value) {
  if (typeof value === 'string') {
    const range = value.split('-');

    if (range.length === 2) {
      return checkIpValid(range[0])
        && checkIpValid(range[1])
        && convertIpToInt(range[0]) < convertIpToInt(range[1]);
    }
  }

  return false;
}

/**
 * Check whether ports format is correct:
 * - single port (x)
 * - list of ports separated by comma (x,y,z)
 * - range of ports separated by hyphen (x-y)
 *
 * @private
 * @param {string} value - Value to check
 * @returns {boolean} True if ports format is correct
 */
export function validatePorts(value) {
  try {
    const ports = value.split(',');

    // Invalid if too many ports in list
    if (ports.length > MAXIMUM_PORTS_LENGTH) {
      return false;
    }

    // Single port or range of ports
    return ports.every((port) => isPort(port) || isPortRange(port));
  } catch (err) {
    return false;
  }
}

/**
 * Check whether string is ASCII:
 *
 * @param {string} str - string to check
 * @returns {boolean} True if string is ASCII
 */
export function isASCII(str) {
  // eslint-disable-next-line
  return /^[\x00-\x7F]*$/.test(str);
}

/**
 * Get displayed protocol string
 *
 * @param {string} value - protocol value to transform
 * @returns {string} displayed protocol string
 */
export function getProtocolDisplayed(value) {
  if (typeof value !== 'string') {
    return '';
  }

  if (value === 'all') {
    return 'TCP + UDP';
  }

  return value.toUpperCase();
}

/**
 * Check if there is any overlapping port
 *
 * expected port value syntax
 * - single port (x)
 * - range of ports separated by hyphen (x-y)
 *
 * @param {string} valueA - 1st port value to compare
 * @param {string} valueB - 2nd port value to compare
 * @returns {boolean} True if overlapping port exists
 */
export function validateOverlappingPort(valueA, valueB) {
  const rangeA = valueA.split('-').map((port) => Number(port));
  const rangeB = valueB.split('-').map((port) => Number(port));

  if (rangeA.length === 1 && rangeB.length === 1) {
    return valueA === valueB;
  }

  if (rangeA.length === 1 && rangeB.length > 1) {
    return valueA >= rangeB[0] && valueA <= rangeB[1];
  }

  if (rangeA.length > 1 && rangeB.length === 1) {
    return valueB >= rangeA[0] && valueB <= rangeA[1];
  }

  return (rangeA[0] >= rangeB[0] && rangeA[0] <= rangeB[1])
    || (rangeA[1] >= rangeB[0] && rangeA[1] <= rangeB[1])
    || (rangeA[0] <= rangeB[0] && rangeA[1] >= rangeB[1]);
}

/**
 * Calc password score. (0 ~ 4 point(pt))
 * password score:
 *  - length < 6: fixed 0 pt
 *  - contains numbers: +1 pt
 *  - contains lower case letters: +1 pt
 *  - contains upper case letters: +1 pt
 *  - contains special char(not letter or number): +1 pt
 *  - lenth < 12: up to 3 pt
 *  - length >= 12: up to 4 pt
 * @param {string} password
 * @returns {number} score of password
 */
export function calcPasswordScore(password) {
  if (password.length < 6) {
    return 0;
  }
  let score = 0;

  if (/\d/.test(password)) {
    score += 1;
  }

  if (/[a-z]/.test(password)) {
    score += 1;
  }

  if (/[A-Z]/.test(password)) {
    score += 1;
  }

  if (/[^a-zA-Z0-9]/.test(password)) {
    score += 1;
  }

  if (password.length < 12) {
    return Math.min(3, score);
  }

  return Math.min(4, score);
}

/**
 * Validate security of pre-share key of QVPN.
 * @param {string} value
 * @returns {string|boolean} True if valid, else error message
 */
export function validateQvpnShareKey(value) {
  if (!isASCII(value)) {
    return 'ID_PASSWORD_ASCII_ONLY_ERR';
  }

  if (value.length < 8 || value.length > 16) {
    return 'ID_QVPN_SHARE_KEY_ERR1';
  }

  if (!/\d/.test(value)) {
    return 'ID_QVPN_SHARE_KEY_ERR2';
  }

  if (!/[a-zA-Z]/.test(value)) {
    return 'ID_QVPN_SHARE_KEY_ERR3';
  }

  if (calcPasswordScore(value) <= 2) {
    return 'ID_QVPN_USER_PASSWORD_NOT_SECURE_ERR';
  }

  return true;
}

/**
 * Validate whether the format of password is valid
 *
 * @param {string} password - Password to validate
 * @returns {boolean} True if the password is valid
 */
export function validatePassword(password) {
  const MIN_PASSWORD_LENGTH = 8;
  const MAX_PASSWORD_LENGTH = 63;

  return isASCII(password)
    && (password.length >= MIN_PASSWORD_LENGTH && password.length <= MAX_PASSWORD_LENGTH)
    && /\d/.test(password)
    && /[a-zA-Z]/.test(password)
    && calcPasswordScore(password) > 2;
}

/**
 * Overwrite values of target object from source object with given keys
 * @param {string[]} keys - keys of source and target object. ex: "aa", "aa.bb.cc"
 * @param {Object} srcItem - source object
 * @param {Object} tarItem - target object
 * @returns {Object} target object
 */
export function mergeDataWithKeys(keys, srcItem, tarItem) {
  /**
   * the upper layer will be sorted in the front
   * ex: ["aa.bb", "aa.bb.cc", "aa"] will be sorted to ["aa", "aa.bb", "aa.bb.cc"]
   */
  keys = keys.slice().sort((item1, item2) => item1.split('.').length - item2.split('.').length);

  /**
   * if the object has been merged, it's all properties will be skip.
   * ex: ["aa", "aa.bb"]. "aa.bb" will be skip because "aa" has been merged.
   */
  const mergedKeysSet = new Set();

  keys.forEach((key) => {
    const keyParts = key.split('.');
    const lastPart = keyParts.pop();
    let srcData = srcItem;
    let tarData = tarItem;
    const abort = keyParts.some((part, idx) => {
      srcData = srcData[part];
      tarData = tarData[part];

      return tarData === undefined || mergedKeysSet.has(keyParts.slice(0, idx + 1).join('.'));
    });

    if (abort) {
      return;
    }
    const data = srcData[lastPart];

    tarData[lastPart] = typeof data === 'object' ? cloneDeep(data) : data;
    mergedKeysSet.add(key);
  });

  return tarItem;
}

/**
 * check given subnet not overlap target subnet.
 * @param {string} ip - IP address
 * @param {string|number} mask - subnet mask
 * @param {string} targetIp - target IP address
 * @param {string|number} targetMask - target subnet mask
 * @returns {boolean} True if subnet not overlap target subnet
 */
export function subnetNotOverlapSubnet(ip, mask, targetIp, targetMask) {
  return !isIpInSubnet(targetIp, ip, mask) && !isIpInSubnet(ip, targetIp, targetMask);
}

/**
 * Replace "<br>" in the given text to "\n"
 * @param {string} text - text to replace
 * @returns {string} replaced text
 */
export function convertLinebreak(text) {
  if (typeof text !== 'string') {
    return '';
  }

  return text.replace(/<br>/g, '\n');
}

/**
 * Check WireGuard private key is valid
 * @param {string} value - private key value
 * @returns {boolean} True if private key is valid
 */
export function validateWireguardPrivateKey(value) {
  if (!Base64.isValid(value)) {
    return false;
  }

  return value.length === 44;
}

/**
 * Check IPv4 IP in IP range
 * @param {string} ip4Address - IPv4 address
 * @param {string} startIp - start IP of IP range
 * @param {string} endIp - end IP of IP range
 * @returns {boolean} True if IP in IP range
 */
export function ip4AddressInRange(ip4Address, startIp, endIp) {
  if (!checkIpValid(ip4Address) || !checkIpValid(startIp) || !checkIpValid(endIp)) {
    return false;
  }
  const ipInt = convertIpToInt(ip4Address);
  const startIpInt = convertIpToInt(startIp);
  const endIpInt = convertIpToInt(endIp);

  return ipInt >= startIpInt && ipInt <= endIpInt;
}

/**
 * Check value is valid integer
 * @param {any} value - value to check
 * @returns True if value is integer
 */
export function isInteger(value) {
  if (value === undefined || value === null) {
    return false;
  }

  return (typeof value === 'number' && Number.isInteger(value))
    || /^\d+$/.test(value.toString());
}

/**
 * Check whether the value is a plain object
 * @param {any} value - Value to check
 * @returns {boolean} True if the value is a plain object
 */
export function isPlainObject(value) {
  return Object.prototype.toString.call(value) === '[object Object]';
}

/**
 * Run async function with retry
 * @param {() => Promise<boolean>} handler - The handler to retry.
 *                                           It will retry if returned value is `false`.
 * @param {Object} options
 * @param {number} options.maxRetries - maximum number of retries
 * @param {number} options.retryDelay - delay of every retry
 * @param {() => boolean | null} options.checkCancel - If returned value is `true`, retry will stop.
 * @returns {Promise<undefined>}
 */
export async function asyncWithRetry(handler, {
  maxRetries = 5,
  retryDelay = 3000,
  checkCancel = null,
} = {}) {
  let success = false;
  let lastError = null;
  const retry = async () => {
    if (checkCancel?.()) {
      success = true;

      return;
    }

    try {
      const value = await handler();

      if (value !== false) {
        success = true;
      }
    } catch (error) {
      lastError = error;
    } finally {
      maxRetries -= 1;
    }
  };

  await retry();

  if (!success) {
    await new Promise((resolve) => {
      const nextRetry = async () => {
        await retry();

        if (success || maxRetries < 0) {
          resolve();
        } else {
          setTimeout(nextRetry, retryDelay);
        }
      };

      nextRetry();
    });
  }

  if (!success && lastError) {
    throw lastError;
  }
}

/**
 * Get client IP pool of VPN server
 * @param {string} tunnelIp - tunnel IP
 * @returns {string} range of client IP pool
 */
export function getTunnelIpPool(tunnelIp) {
  if (!checkIpValid(tunnelIp)) {
    return '';
  }
  const startIpArray = tunnelIp.split('.');

  startIpArray[3] = '2';
  const endIpArray = startIpArray.slice();

  endIpArray[3] = '254';

  return `${startIpArray.join('.')} ~ ${endIpArray.join('.')}`;
}

/**
 * Convert milliseconds to seconds.
 * @param {number} milliseconds - Milliseconds to be converted
 * @returns {number} Seconds
 */
export function convertMillisecondToSecond(milliseconds) {
  return Math.floor(milliseconds / 1000);
}

/**
 * Convert seconds to milliseconds.
 * @param {number} seconds - Seconds to be converted
 * @returns {number} Converted milliseconds
 */
export function convertSecondToMillisecond(seconds) {
  if (!isInteger(seconds) || seconds < 0) {
    return 0;
  }

  return seconds * 1000;
}

/**
 * Convert pixels to rem.
 * (reference: https://stackoverflow.com/a/42769683)
 * @param {number} px - The pixel value of CSS to be converted
 * @returns {number} The rem value of CSS converted from pixel
 */
export function convertPixelsToRem(px) {
  if (typeof px !== 'number') {
    return 0;
  }

  let rootFontSize = getComputedStyle(document.documentElement)
    .getPropertyValue(rootFontSizeVariable);

  if (!rootFontSize) {
    rootFontSize = '16'; // 16 is the default font size of root element

    document.documentElement.style.setProperty(rootFontSizeVariable, rootFontSize);
  }

  return px / parseFloat(rootFontSize);
}

/**
 * Create the new timer for redirecting to new IP.
 * @param {string} newIP - The new IP to redirect
 * @param {number} delay - The delay of redirecting
 * @returns {number} The ID of new timer for redirecting to new IP
 */
export function createRedirectToNewIpTimer(newIp, delay = 15000) {
  return setTimeout(async () => {
    /**
     * Fetch favicon.png to check connection.
     * Retry every 10 seconds, redirect if successful.
     * If retry 10 times (after 100 seconds), it will unconditionally redirect.
     */
    const pingUrl = `${window.location.protocol}//${newIp}/favicon.png`;

    await asyncWithRetry(async () => {
      try {
        await fetch(pingUrl);

        return true;
      } catch (error) {
        return false;
      }
    }, {
      maxRetries: 10,
      retryDelay: 10000,
    });

    window.location.href = `${window.location.protocol}//${newIp}`;
  }, delay);
}

/**
 * Error type of custom message
 * @param {string} name - Name of error
 * @param {string|string[]} message - Message of error
 * @param {string} [type] - Type of error
 * @returns {void}
 */
export function CustomMessageError(name, message, type = NOTIFY_LEVEL.ERROR) {
  this.name = name;
  this.message = message;
  this.type = type;
}

/**
 * Handles a set of promises and calls a callback function with the resolved values.
 * Throws the first encountered error if any promise is rejected.
 * @param {Promise<any>[]} promises - List of promises to be settled.
 * @param {Function} [callback] - Function to be called with the resolved values of the promises.
 * @throws {Error} The first encountered error reason if any promise is rejected.
 * @returns {Promise<void>}
 */
export async function handleSettledPromises(promises, callback) {
  if (!Array.isArray(promises)) {
    // To be the same format as the error of the API service
    // Use CustomMessageError instead of new Error
    throw new CustomMessageError(CUSTOM_ERROR_TYPE.SETTLED_PROMISE_ERROR, 'Promises must be an array');
  }

  const resolvedResults = await Promise.allSettled(promises);
  const errors = [];
  const values = [];

  resolvedResults.forEach((result) => {
    values.push(result.value);

    if (result.status === PROMISE_STATUS.REJECTED) {
      errors.push(result.reason);
    }
  });

  if (typeof callback === 'function') {
    callback(values);
  }

  if (errors.length) {
    // UI displays the error message with modal component
    // So, display only one error message at one time
    throw new CustomMessageError(CUSTOM_ERROR_TYPE.SETTLED_PROMISE_ERROR, errors[0].message);
  }
}
