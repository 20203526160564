import { cloneDeep } from 'lodash';
import { Wireless } from '@/services';
import { mergeDataWithKeys } from '@/common/utilities';

const storeState = {
  bands: [],
  editedBandsState: {
    bands: [],
    bandSteer: false,
  },
  bandsStatus: [],
  bandCurrentChannel: {
    '5-1': 0,
  },
  bandSteer: false,
  editMode: false,
  bandConsts: {
    dataKeys: ['bands', 'bandSteer'],
  },
  bandEnums: {
    band: {
      2.4: '2.4',
      '5-1': '5-1',
      '5-2': '5-2',
    },
  },
};

const storeGetters = {
  editedBands(state) {
    return state.editMode ? state.editedBandsState : null;
  },
  editedOriginalBands(state) {
    const data = {
      bands: state.bands,
      bandSteer: state.bandSteer,
    };

    return state.editMode ? data : null;
  },
  getBandStatus: (state) => (band) => {
    const status = state.bandsStatus.find((item) => item.band === band);

    return status;
  },
};

const mutations = {
  initBands(state, { bandSteer, bands }) {
    state.editedBandsState = {
      bands: cloneDeep(bands),
      bandSteer,
    };
    state.bands = bands;
    state.bandSteer = bandSteer;
  },
  setBandsStatus(state, bandsStatus) {
    state.bandsStatus = bandsStatus;
  },
  startEditing(state) {
    state.editMode = true;
  },
  endEditing(state) {
    state.editMode = false;
  },
  modifyBands(state, editedConfig) {
    mergeDataWithKeys(state.bandConsts.dataKeys, editedConfig, state.editedBandsState);
  },
  setBandCurrentChannel(state, { band, value }) {
    state.bandCurrentChannel[band] = value;
  },
};

const actions = {
  async getBands({ commit }) {
    const bandsRes = await Wireless.getBands();

    commit('initBands', bandsRes.result);
  },
  async getBandsStatus({ commit }) {
    const bandsStatusRes = await Wireless.getBandsStatus();

    commit('setBandsStatus', bandsStatusRes.result);
  },
  async putEditedBands({ state }) {
    const bandsConfig = mergeDataWithKeys(state.bandConsts.dataKeys, state.editedBandsState, {});

    await Wireless.putBands(bandsConfig);
    await Wireless.postRestart();
  },
  applyEditing({ commit }, editedConfig) {
    commit('modifyBands', editedConfig);
  },
};

export default {
  namespaced: true,
  state: storeState,
  getters: storeGetters,
  mutations,
  actions,
};
