/* eslint-disable import/prefer-default-export */

import { cloneDeep } from 'lodash';
import LAN_DEFAULT_GATEWAY from '@/enums/LanDefaultGateway';
import LAN_IP4_TYPE from '@/enums/LanIp4Type';
import LAN_IP6_TYPE from '@/enums/LanIp6Type';

/**
 * Handle the LAN interface config of API request data.
 * @param {Object} lan - LAN interface config of the network interface
 * @param {boolean} interfaceEnabled - The enabled/disabled state of the network interface
 * @returns {Object} The new LAN interface config after handling
 */
export function handleLanRequest(lan, interfaceEnabled) {
  lan = cloneDeep(lan);

  if (lan.ip6Type === LAN_IP6_TYPE.RELAY
    || lan.ip6AddrAutoConfig.autoConfigType === '') {
    delete lan.ip6AddrAutoConfig;
  }

  lan.ip4Type = interfaceEnabled ? LAN_IP4_TYPE.STATIC : LAN_IP4_TYPE.DISABLED;
  lan.dhcpService = {
    serviceType: lan.dhcpService.serviceType,
    startIp: lan.dhcpService.startIp,
    endIp: lan.dhcpService.endIp,
    leaseTime: lan.dhcpService.leaseTime,
    dnsServers: lan.dhcpService.dnsServers,
    reservedIps: lan.dhcpService.reservedIps,
    relayServers: lan.dhcpService.relayServers,
    routers: lan.dhcpService.defaultGatewayType === LAN_DEFAULT_GATEWAY.AUTO
      ? []
      : [lan.dhcpService.defaultGatewayIp],
  };

  return lan;
}
