
<div id="app">
  <transition
    name="component-fade"
    mode="out-in"
  >
    <router-view v-if="isRouterDisplayed" />
  </transition>

  <DialogContainer
    v-for="dialog in dialogs"
    :key="dialog.type"
    :type="dialog.type"
    :config="dialog.config"
  />
  <AppLoading />
  <ToastBase />
  <AppConfirm />

  <ModalConfirm
    id="redirect_warning"
    type="help"
    message="ID_REDIRECT_MSG"
    confirm-label="ID_REDIRECT_QTS"
    cancel-label="ID_REDIRECT_QUROUTER"
    @confirm="redirectQts"
  />
  <FirmwareUpdateConfirm
    :qurouter-version="qurouterVersion"
    :force-update="forceUpdate"
  />
  <QpkgUpdateConfirm :force-update="forceUpdate" />

  <!-- The order of the notification modal is behind the confirmation modal -->
  <ModalNotify
    id="notify"
    :visible="$store.state.Plugin.Notify.visible"
    :message="$store.state.Plugin.Notify.text"
    :type="$store.state.Plugin.Notify.type"
    @cancel="$store.state.Plugin.Notify.hidden"
  />
</div>
