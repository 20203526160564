/* eslint-disable import/prefer-default-export */

const HAS_NEW_TERMS_OF_USE_VERSION = 'hasNewTermsOfUseVersion';
const SHOULD_CHECK_TERMS_OF_USE_VERSION = 'shouldCheckTermsOfUseVersion';
const versionPattern = /^\d{8}$/;

export const OFFICIAL_TERMS_OF_USE_LINK = 'https://www.qnap.com/go/legal/qnap-software-terms-of-use';
export const OFFICIAL_PRIVACY_POLICY_LINK = 'https://www.qnap.com/go/legal/qnap-privacy-policy';

/**
 * Convert the consented version format to the milliseconds
 * The consented version consists of the year, month and day
 * @param {string|number} version - Version of the Terms of Use user consented
 * @returns {number} Milliseconds of the date time
 */
export function formatVersion(version) {
  const DEFAULT_VALUE = 0;
  const stringifyVersion = String(version);

  if (!versionPattern.test(stringifyVersion)) {
    return DEFAULT_VALUE;
  }

  const formattedDate = `${stringifyVersion.slice(0, 4)}/${stringifyVersion.slice(4, 6)}/${stringifyVersion.slice(6, 8)}`;

  return new Date(formattedDate).getTime() || DEFAULT_VALUE;
}

/**
 * Check whether the official version is newer than the consented version
 * @param {string} consentedVersion - Version consented by the user
 * @param {string} officialVersion - Official version of the Terms of Use
 * @returns {boolean} True if the official version is newer than the consented version
 */
export function checkNewTermsOfUseVersion(consentedVersion, officialVersion) {
  const consentedVersionTime = formatVersion(consentedVersion);
  const termsOfUseOfficialVersionTime = formatVersion(officialVersion);

  return termsOfUseOfficialVersionTime > consentedVersionTime;
}

/**
 * Get the state of whether there is a new version of the Terms of Use from local storage.
 * @returns {boolean} True if there is a new version of the Terms of Use
 */
export function getNewTermsOfUseVersionState() {
  return localStorage.getItem(HAS_NEW_TERMS_OF_USE_VERSION) === 'true';
}

/**
 * Store the state of whether there is a new version of the Terms of Use in local storage.
 * @param {boolean} state - The state of whether there is a new version of the Terms of Use
 * @returns {void}
 */
export function setNewTermsOfUseVersionState(state) {
  const formattedState = String(state) || '';

  localStorage.setItem(HAS_NEW_TERMS_OF_USE_VERSION, formattedState);
}

/**
 * Get the state of whether to check the version of the Terms of Use from local storage.
 * @returns {boolean} True if it's necessary to check the version of the Terms of Use
 */
export function getShouldCheckTermsOfUseVersionState() {
  return localStorage.getItem(SHOULD_CHECK_TERMS_OF_USE_VERSION) === 'true';
}

/**
 * Store the state of whether to check the version of the Terms of Use in local storage.
 * @param {boolean} state - The state of whether to check the version of the Terms of Use
 * @returns {void}
 */
export function setShouldCheckTermsOfUseVersionState(state) {
  const formattedState = String(state) || '';

  localStorage.setItem(SHOULD_CHECK_TERMS_OF_USE_VERSION, formattedState);
}
