
<ValidationProvider
  ref="provider"
  v-slot="{ validated, valid, errors }"
  :rules="rules"
  :disabled="disabled"
  tag="div"
  :immediate="immediate"
>
  <slot
    :validated="validated"
    :valid="valid"
  />
  <TipInputMessage
    v-if="validated && !valid && invalidMessage"
    :message="invalidMessage"
  />
  <TipInputMessage
    v-else-if="validated && !valid && errors.length !== 0 && errors[0]"
    :message="errors[0]"
  />
</ValidationProvider>
