import apiPath from '../apiPath';
import { qFetch } from '../utilities';

export default {
  /**
   * Get IPTV config.
   * @returns {Promise<Object>}
   */
  async getIptvSetting() {
    return qFetch.get(apiPath.v2.IptvSetting);
  },
  /**
   * Update IPTV config.
   * @param {Object} config - IPTV config
   * @returns {Promise<Object>}
   */
  async patchIptvSetting(config) {
    return qFetch.patch(apiPath.v2.IptvSetting, { data: config });
  },
};
