
<div
  class="q-input-radio-wrapper"
  :class="{
    'no-label': noLabel,
  }"
>
  <input
    :id="innerId"
    v-model="innerValue"
    :name="name"
    :value="value"
    :disabled="disabled"
    type="radio"
    class="q-input-radio"
  >
  <label
    :for="innerId"
    class="df ai-center q-input-radio-label"
  >
    <slot>
      <div v-if="label">{{ $t(label) }}</div>
    </slot>
  </label>
</div>
