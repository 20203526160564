import {
  USER_SESSION_DISABLED,
  SYSTEM_SESSION_DISABLED,
} from '@/common/debugMode';
import apiPath from './apiPath';
import { qFetch } from './utilities';

const QUWAN_WMD_NEIGH_PROBING = {
  SKIPPED: '1',
  NOT_SKIPPED: '0',
};

export default {
  /**
   * Get the basic information of server
   * @returns {Promise<Object>} Basic information of server
   */
  async getBasicInfo() {
    return qFetch.get(apiPath.BasicInfo);
  },

  /**
   * Export the system log into a file that can be downloaded
   * @param {string} fileName - The filename of downloaded file
   * @returns {Promise<void>}
   */
  async dumpLog(fileName) {
    await qFetch.download(apiPath.DumpLog, fileName);
  },

  /**
   * Get the setting of NCSI
   * @returns {Promise<Object>} Setting of NCSI
   */
  async getNcsiSetting() {
    const { result } = await qFetch.get(apiPath.Ncsid);

    return {
      intervalTime: Number(result.period),
      timeout: Number(result.timeout),
      retryInterval: Number(result.retry_interval),
      retryCount: Number(result.retry_count_max),
    };
  },

  /**
   * Create a new setting for NCSI
   * @param {Object} param - New setting of NCSI
   * @param {Object} param.intervalTime - Interval time for the NCSI updates
   * @param {Object} param.timeout - Timeout for the NCSI connection
   * @param {Object} param.retryInterval - Interval time for the NCSI to reconnection
   * @param {Object} param.retryCount - Maximum amount for the NCSI to reconnection
   * @returns {Promise<void>}
   */
  async createNcsiPeriod({
    intervalTime,
    timeout,
    retryInterval,
    retryCount,
  }) {
    const payload = {
      data: {
        period: String(intervalTime),
        timeout: String(timeout),
        retry_interval: String(retryInterval),
        retry_count_max: String(retryCount),
      },
    };

    await qFetch.post(apiPath.Ncsid, payload);
  },

  /**
   * Get the setting of the server session
   * @returns {Promise<Object>} Setting of the server session
   */
  async getSessionSetting() {
    const { result } = await qFetch.get(apiPath.SessionLimitation);

    return {
      blockTimeout: result.block_timeout,
      maxUserSession: result.max_session,
      maxSystemSession: result.warn_session,
      isUserSessionEnabled: result.max_session !== USER_SESSION_DISABLED,
      isSystemSessionEnabled: result.warn_session !== SYSTEM_SESSION_DISABLED,
    };
  },

  /**
   * Update the setting of the server session
   * @param {Object} sessionSetting - Session setting for update
   * @returns {Promise<void>}
   */
  async updateSessionSetting(sessionSetting) {
    await qFetch.post(apiPath.SessionLimitation, {
      block_timeout: sessionSetting.blockTimeout,
      max_session: sessionSetting.isUserSessionEnabled
        ? sessionSetting.maxUserSession
        : USER_SESSION_DISABLED,
      warn_session: sessionSetting.isSystemSessionEnabled
        ? sessionSetting.maxSystemSession
        : SYSTEM_SESSION_DISABLED,
    });
  },
  /**
   * Get the setting of QuwanWmd.
   * @returns {Promise<Object>} - The setting of QuwanWmd
   */
  async getQuwanWmdSetting() {
    const { result } = await qFetch.get(apiPath.v2.QuwanWmdParams);

    return {
      isWanProbeEnabled: result.skip_neigh_probing === QUWAN_WMD_NEIGH_PROBING.NOT_SKIPPED,
    };
  },
  /**
   * Update the setting of QuWAN WMD.
   * @param {Object} setting - The new setting of QuWAN WMD
   * @returns {Promise<void>}
   */
  async updateQuwanWmdSetting(setting) {
    await qFetch.post(apiPath.v2.QuwanWmdParams, {
      data: {
        skip_neigh_probing: setting.isWanProbeEnabled
          ? QUWAN_WMD_NEIGH_PROBING.NOT_SKIPPED : QUWAN_WMD_NEIGH_PROBING.SKIPPED,
      },
    });
  },
  /**
   * Get the DPI enabled setting.
   * @returns {Promise<Object>} The DPI enabled setting
   */
  async getDpiEnabledSetting() {
    const { result } = await qFetch.get(apiPath.v2.DpiEnabled);

    return result;
  },
  /**
   * Update the DPI enabled setting.
   * @param {Object} payload - The data for updating the DPI enabled setting
   * @returns {Promise<void>}
   */
  async updateDpiEnabledSetting(payload) {
    await qFetch.put(apiPath.v2.DpiEnabled, { data: payload });
  },
  /**
   * Get the DPI version information.
   * @returns {Promise<string>} The DPI version
   */
  async getDpiVersion() {
    const { result } = await qFetch.get(apiPath.v2.DpiVersion);

    return result;
  },
  /**
   * Get the PHY link setting.
   * @returns {Promise<Object>} The PHY link setting
   */
  async getPhyLinkSetting() {
    const { result } = await qFetch.get(apiPath.v2.PhyLinkParams);

    return {
      waitTime: parseInt(result.wait_sec, 10),
    };
  },
  /**
   * Update the PHY link setting.
   * @param {Object} setting - The PHY link setting
   * @returns {Promise<void>}
   */
  async updatePhyLinkSetting(setting) {
    await qFetch.post(apiPath.v2.PhyLinkParams, {
      data: {
        wait_sec: setting.waitTime.toString(),
      },
    });
  },
};
