<template>
  <ValidationProvider
    :id="innerId"
    ref="provider"
    v-slot="{ validated, valid, errors }"
    :rules="rules"
    :disabled="disabled"
    tag="div"
  >
    <div
      class="df ai-center flex-wrap"
      :class="stacked ? 'space-y-10-px' : 'space-x-10-px'"
      role="radiogroup"
    >
      <div
        v-for="(option, idx) in options"
        :key="option.value"
        :class="{
          'w-full': stacked,
          'pr-30-px': idx !== options.length - 1,
        }"
      >
        <slot
          :name="`label(${option.value})`"
          :input-id="`${innerId}__${option.value}`"
          :input-name="innerId"
          :option="option"
        >
          <div class="df ai-center">
            <InputRadio
              :id="`${innerId}__${option.value}`"
              v-model="innerValue"
              :name="innerId"
              :value="option.value"
              :label="option.label"
              :disabled="option.disabled"
              :class="$slots[`label-end(${option.value})`] ? 'mr-10-px' : ''"
            />
            <slot :name="`label-end(${option.value})`" />
          </div>
        </slot>
        <div
          v-if="$slots[`label-bottom(${option.value})`] && stacked"
          class="pl-25-px pt-10-px"
        >
          <slot :name="`label-bottom(${option.value})`" />
        </div>
      </div>
    </div>
    <TipInputMessage
      v-if="validated && !valid && errors.length !== 0"
      :message="errors[0]"
    />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import TipInputMessage from '@/components/Tip/TipInputMessage.vue';
import InputRadio from './InputRadio.vue';

let qInputRadioGroupAutoIncreasementId = 0;

/**
 * vee-validate is unused for this component now,
 * need to confirm ValidationProvider behavior in the future.
 */
export default {
  name: 'InputRadioGroup',
  components: {
    ValidationProvider,
    TipInputMessage,
    InputRadio,
  },
  model: {
    prop: 'checked',
    event: 'input',
  },
  props: {
    checked: {
      type: [Number, String, Boolean],
      required: true,
    },
    options: {
      type: Array,
      required: true,
      validator(value) {
        return value.every((option) => ['number', 'string', 'boolean'].includes(typeof option.value));
      },
    },
    rules: {
      type: Object,
      default: null,
    },
    id: {
      type: String,
      default: '',
    },
    stacked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    qInputRadioGroupAutoIncreasementId += 1;

    return {
      defaultId: `__Q_INPUT_RADIO_GROUP__${qInputRadioGroupAutoIncreasementId}`,
    };
  },
  computed: {
    innerValue: {
      get() {
        return this.checked;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    innerId() {
      return this.id || this.defaultId;
    },
  },
  watch: {
    checked() {
      const { provider } = this.$refs;

      if (provider && provider.flags.validated) {
        this.$nextTick(() => {
          this.$refs.provider.validate();
        });
      }
    },
  },
};
</script>
