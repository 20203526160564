<template>
  <div
    class="q-col"
    :class="rootClassList"
    :style="rootStyle"
  >
    <slot />
  </div>
</template>

<script>
import { isInteger } from '@/common/utilities';

export default {
  name: 'QCol',
  props: {
    alignSelf: {
      type: String,
      default: null,
      validator(value) {
        return ['start', 'center', 'end', 'baseline', 'stretch', null].includes(value);
      },
    },
    cols: {
      type: [String, Number],
      default: null,
      validator(value) {
        return value === null || value === 'auto' || isInteger(value);
      },
    },
    offset: {
      type: [String, Number],
      default: 0,
      validator(value) {
        return isInteger(value);
      },
    },
    flexWidth: {
      type: String,
      default: '',
    },
  },
  computed: {
    rootClassList() {
      const classList = [];

      if (this.alignSelf !== null) {
        classList.push(`q-col-self-${this.alignSelf}`);
      }

      if (this.offset.toString() !== '0') {
        classList.push(`q-col-offset-${this.offset}`);
      }

      if (this.cols !== null && this.cols.toString() !== '0') {
        classList.push(`q-col-${this.cols}`);
      }

      return classList;
    },
    rootStyle() {
      if (this.flexWidth) {
        return `max-width: ${this.flexWidth}; flex: 0 0 ${this.flexWidth};`;
      }

      return null;
    },
  },
};
</script>
