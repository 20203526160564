
<div
  class="df ai-start"
  :class="{
    'fz-14': size === 'sm',
    'tip-message-backdrop': backdrop,
    [`tip-message-backdrop-${type}`]: backdrop,
  }"
>
  <div
    class="flex-shrink-0 mr-10-px"
    :class="icon"
  />
  <div
    class="ws-pl flex-fluid"
    :class="textColor"
  >
    <slot>{{ innerText }}</slot>
  </div>
</div>
