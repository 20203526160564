const state = {
  isAccountInfoTimerSuspended: false,
};

const mutations = {
  setIsAccountInfoTimerSuspended(state, isSuspended) {
    state.isAccountInfoTimerSuspended = isSuspended;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
