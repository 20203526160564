
<transition
  name="fade"
  appear
>
  <div
    v-if="innerVisible"
    :id="id"
    class="q-modal-wrapper"
    :class="{ 'q-modal-wrapper-resizing': resizing }"
    @mousemove="wrapperOnMouseMove"
    @mouseup="endResizing"
  >
    <div
      class="q-modal-container"
      :class="containerClassList"
      :style="containerStyle"
    >
      <div
        class="q-modal-content"
        :class="`q-modal-theme-${theme}`"
        :style="contentStyle"
      >
        <header
          v-if="!noTitle"
          class="q-modal-header"
          :class="headerClassList"
        >
          <div>{{ $t(title) }}</div>
          <ButtonIcon
            v-if="!force"
            class="q-modal-button-close"
            icon="ic_close"
            label="ID_CLOSE"
            @click="closeModal"
          />
        </header>
        <div
          v-if="$slots.default"
          class="q-modal-body"
        >
          <slot name="modal-body-before" />
          <div :class="customBodyClass || 'ptb-20-px plr-15-px'">
            <slot />
          </div>
          <ButtonIcon
            v-if="noTitle && !force"
            class="q-modal-button-close inner-body"
            icon="ic_close"
            label="ID_CLOSE"
            @click="closeModal"
          />
        </div>
        <slot
          name="modal-footer-wrapper"
          :cancel="closeModal"
        >
          <footer
            v-if="!hideFooter"
            class="q-modal-footer"
          >
            <div class="df ai-center jc-end">
              <slot
                name="modal-footer"
                :cancel="closeModal"
              >
                <slot name="modal-footer-start" />
                <ButtonText
                  :label="submitLabel"
                  :disabled="submitDisabled"
                  @click="submit"
                />
                <ButtonText
                  v-if="showCancelButton && !force"
                  label="ID_CANCEL"
                  theme="secondary"
                  @click="closeModal"
                />
              </slot>
            </div>
          </footer>
        </slot>
      </div>
      <div
        class="q-modal-container-left"
        @mousedown="startResizing($event, -1)"
      />
      <div
        class="q-modal-container-right"
        @mousedown="startResizing($event, 1)"
      />
    </div>
  </div>
</transition>
