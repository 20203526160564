<template>
  <div>
    <ModalConfirm
      :id="id"
      title="ID_FW_UPDATE_TITLE"
      force
    >
      <div class="space-y-10-px">
        {{ `${$t('ID_FIRMWARE_CUR_VER')} ${qurouterVersion}` }}
        <TipMessage
          type="warning"
          text="ID_FW_UPDATE_DESC"
        />
        <TipMessage text="ID_FW_UPDATE_HINT" />
        <TipMessage text="ID_QPKG_INCOMPATIBILITY_DESC" />
      </div>
      <template #modal-footer="{ cancel }">
        <ButtonText
          class="mr-10-px"
          :label="supportLiveUpdate ? 'ID_HORA_INIT_UPDATE' : 'ID_QPKG_REDIRECT_ROUTER_BTN'"
          @click="updateAction"
        />
        <ButtonText
          v-if="!forceUpdate"
          theme="secondary"
          label="ID_HORA_INIT_SKIP"
          @click="cancel"
        />
      </template>
    </ModalConfirm>
    <FirmwareBackupFailedConfirmModal />
  </div>
</template>

<script>
import SERVICES from '@/services';
import FirmwareUpgrade from '@/mixins/FirmwareUpgrade';
import ButtonText from '@/components/Button/ButtonText.vue';
import ModalConfirm from '@/components/Modal/ModalConfirm.vue';
import TipMessage from '@/components/Tip/TipMessage.vue';
import FirmwareBackupFailedConfirmModal from './Firmware/FirmwareSetting/FirmwareBackupFailedConfirmModal.vue';

export default {
  name: 'FirmwareUpdateConfirm',
  components: {
    ButtonText,
    ModalConfirm,
    TipMessage,
    FirmwareBackupFailedConfirmModal,
  },
  mixins: [FirmwareUpgrade],
  props: {
    qurouterVersion: {
      type: String,
      required: true,
    },
    forceUpdate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      id: 'firmware_update_confirm',
    };
  },
  computed: {
    supportLiveUpdate() {
      return this.qurouterVersion.startsWith('2.');
    },
  },
  methods: {
    /**
     * Update the version of QuRouter
     * @returns {Promise<void>}
     */
    async updateAction() {
      if (this.supportLiveUpdate) {
        this.$modal.hide(this.id);

        try {
          await this.updateFirmwareByRemote();
        } catch (error) {
          this.handleFirmwareUpgradeError(error);
        }
      } else {
        const { result } = await SERVICES.INITIAL.getLanInfo();

        if (result.lan_list && result.lan_list.length > 0) {
          const routerIp = result.lan_list[0].ip4;

          window.open(`http://${routerIp}`, '_parent');
        }
      }
    },
  },
};
</script>
