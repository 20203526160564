import { System } from '@/services';
import { mergeDataWithKeys } from '@/common/utilities';

/**
 * Format AP network config from API to GUI
 *
 * @param {Object} data - original AP network config
 * @returns {Object} Formatted config
 */
function handleApNetworkResponse(data) {
  return {
    ip4Address: data.IP4_ADDR,
    ip4DnsServers: data.IP4_DNS.split(','),
    ip4Gateway: data.IP4_GW,
    ip4Prefix: data.IP4_PREFIX,
    ip4Type: data.IP4_TYPE.toLowerCase(),
    enableStp: data.STP !== 0,
  };
}

/**
 * Format AP network config from GUI to API
 *
 * @param {Object} data - GUI AP network config
 * @returns {Object} Formatted config
 */
function handleApNetworkRequest(data) {
  return {
    IP4_ADDR: data.ip4Address,
    IP4_DNS: data.ip4DnsServers.join(','),
    IP4_GW: data.ip4Gateway,
    IP4_PREFIX: data.ip4Prefix,
    IP4_TYPE: data.ip4Type.toUpperCase(),
    STP: data.enableStp ? 1 : 0,
  };
}

const storeState = {
  apNetworkConfig: {},
  apNetworkConsts: {
    configDataKeys: [
      'ip4Address',
      'ip4DnsServers',
      'ip4Gateway',
      'ip4Prefix',
      'ip4Type',
      'enableStp',
    ],
  },
};

const mutations = {
  initApNetworkConfig(state, apNetworkConfig) {
    state.apNetworkConfig = apNetworkConfig;
  },
  modifyApNetworkConfig(state, apNetworkConfig) {
    mergeDataWithKeys(state.apNetworkConsts.configDataKeys, apNetworkConfig, state.apNetworkConfig);
  },
};

const actions = {
  async getApNetwork({ commit }) {
    const data = await System.getApModeNetworkInfo();

    commit('initApNetworkConfig', handleApNetworkResponse(data.result));
  },
  async putEditedApNetworkConfig({ state }) {
    const payload = handleApNetworkRequest(state.apNetworkConfig);

    await System.putApModeNetworkInfo(payload);
  },
  applyEditing({ commit }, editedConfig) {
    commit('modifyApNetworkConfig', editedConfig);
  },
};

export default {
  namespaced: true,
  state: storeState,
  mutations,
  actions,
};
