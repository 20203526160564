import { RemoteSupport } from '@/services';

const state = {
  websiteInfo: {
    qnapService: 'https://service.qnap.com',
  },
  enabled: false,
  contactInfo: {
    email: '',
    ticketId: '',
  },
  localAccount: {
    account: '',
    password: '',
    privateKey: '',
  },
};

const actions = {
  async getWebsiteInfo({ commit }) {
    const res = await RemoteSupport.getWebsiteInfo();

    commit('setWebsiteInfo', res.result);
  },
  async getSupportStatus({ commit }) {
    const res = await RemoteSupport.getSupportStatus();

    commit('setSupportStatus', res.result.enabled);

    if (res.result.enabled) {
      commit('storeLocalAccount', res.result);
    }
  },
  async checkContactInfo({ commit }, contactInfo) {
    await RemoteSupport.checkContactInfo(contactInfo);
    commit('storeContactInfo', contactInfo);
  },
  async createLocalSupportAccount({ commit, state }) {
    const res = await RemoteSupport.createLocalSupportAccount(state.contactInfo);

    commit('storeLocalAccount', res.result);
  },
  async startRemoteSupport({ commit }) {
    await RemoteSupport.startRemoteSupport();
    commit('setSupportStatus', true);
  },
  async stopRemoteSupport({ commit }) {
    await RemoteSupport.stopRemoteSupport();
    commit('setSupportStatus', false);
  },
};

const mutations = {
  setWebsiteInfo(state, info) {
    state.websiteInfo = info;
  },
  setSupportStatus(state, value) {
    state.enabled = value;
  },
  storeContactInfo(state, payload) {
    state.contactInfo.email = payload.email || '';
    state.contactInfo.ticketId = payload.ticketId || '';
  },
  storeLocalAccount(state, payload) {
    state.localAccount.account = payload.account || '';
    state.localAccount.password = payload.password || '';
    state.localAccount.privateKey = payload.privateKey || '';
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
