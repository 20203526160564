<template>
  <div
    class="q-row"
    :class="rootClassList"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'QRow',
  props: {
    alignV: {
      type: String,
      default: null,
      validator(value) {
        return ['start', 'center', 'end', 'baseline', 'stretch', null].includes(value);
      },
    },
    alignH: {
      type: String,
      default: null,
      validator(value) {
        return ['start', 'center', 'end', 'around', 'between', null].includes(value);
      },
    },
    noGutters: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    rootClassList() {
      const classList = [];

      if (this.alignH !== null) {
        classList.push(`q-row-h-${this.alignH}`);
      }

      if (this.alignV !== null) {
        classList.push(`q-row-v-${this.alignV}`);
      }

      if (this.noGutters) {
        classList.push('q-row-no-gutters');
      }

      return classList;
    },
  },
};
</script>
