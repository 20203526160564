import { cloneDeep, sortBy } from 'lodash';
import SERVICES from '@/services';
import LOG_ACTIONS from '@/enums/LogActions';
import { i18n } from '@/lang';

const LOG_ACTION_TEXT_MAPPING = {
  [LOG_ACTIONS.LOGOUT]: 'ID_VPN_LOG_LOGOUT',
  [LOG_ACTIONS.LOGIN_OK]: 'ID_VPN_LOG_LOGIN_OK',
  [LOG_ACTIONS.LOGIN_FAILED]: 'ID_VPN_LOG_LOGIN_FAILED',
  [LOG_ACTIONS.UNKNOWN]: 'ID_VPN_LOG_UNKNOWN',
};

const state = {
  vpnClientOpenVpn: [],
  vpnClientOpenVpnConfig: {},
  vpnClientEnable: false,
  vpnClientCurrentProfile: '',
};

const storeGetters = {
  profileList(state) {
    const list = state.vpnClientOpenVpn.map((data) => ({ value: data.id, text: data.ProfileName }));

    if (state.vpnClientCurrentProfile === '') {
      list.unshift({ value: '', text: '--' });
    }

    return list;
  },
  isProfileListFull(state, getters) {
    const { profileList } = getters;

    if (profileList.length > 0) {
      /**
       * The default option '--' for unselect state should not be counted in length limitation.
       * And it will disappear after user select any other profile.
       */
      const limit = (profileList[0].value === '') ? 10 : 9;

      return profileList.length > limit;
    }

    return false;
  },
  getLogActionText: () => (action) => {
    const messageId = LOG_ACTION_TEXT_MAPPING[action];

    return i18n.t(messageId);
  },
};

const mutations = {
  // convert received object (vpnClientOpenVpnConfig) into displayed array (vpnClientOpenVpn)
  setVpnClient(state, payload) {
    state.vpnClientOpenVpnConfig = cloneDeep(payload.Configs);
    state.vpnClientEnable = false;
    state.vpnClientOpenVpn = [];
    state.vpnClientCurrentProfile = '';

    if (payload && payload.Configs) {
      Object.keys(payload.Configs.OpenVPN).forEach((key) => {
        const data = {
          config: payload.Configs.OpenVPN[key],
        };

        data.config.id = key;

        if (data.config.Addresses) {
          data.config.Addresses = data.config.Addresses.join(',');
        }

        if (payload.Status[key]) {
          data.config.Country = payload.Status[key].Country;
          data.config.PublicIP = payload.Status[key].PublicIP;
          data.config.RemoteIP = payload.Status[key].RemoteIP;
        }
        data.config.Password = atob(payload.Configs.OpenVPN[key].Password);
        data.config.protocol = 'OpenVPN';

        if (payload.Configs.OpenVPN[key].Enable) {
          state.vpnClientEnable = true;
        }
        state.vpnClientOpenVpn.push(data.config);

        if (data.config.Meta && data.config.Meta.selected) {
          state.vpnClientCurrentProfile = data.config.id;
        }
      });
      state.vpnClientOpenVpn = sortBy(state.vpnClientOpenVpn, ['CreateTime']);
    }
  },
};

const actions = {
  async getVpnClient({ commit }) {
    const data = await SERVICES.VPNCLIENT.getVpnClient();

    commit('setVpnClient', data.result);
  },
};

export default {
  namespaced: true,
  state,
  getters: storeGetters,
  mutations,
  actions,
};
