import store from '@/store';

export default {
  install(Vue) {
    const resolves = new Map();

    Vue.prototype.$modal = {
      show(id) {
        store.commit('Plugin/Modal/show', id);
      },
      hide(id) {
        store.commit('Plugin/Modal/hide', id);

        if (resolves.has(id)) {
          resolves.get(id)();
          resolves.delete(id);
        }
      },
      showAndWait(id) {
        store.commit('Plugin/Modal/show', id);

        return new Promise((resolve) => { resolves.set(id, resolve); });
      },
    };
  },
};
