import store from '@/store';

export default {
  install(Vue) {
    Vue.prototype.$dialog = {
      open(type, config) {
        store.commit('Plugin/Dialog/OPEN_DIALOG', { type, config });
      },
      close(type) {
        store.commit('Plugin/Dialog/CLOSE_DIALOG', { type });
      },
      closeAll() {
        store.commit('Plugin/Dialog/CLOSE_ALL_DIAlOG');
      },
    };
  },
};
