import { BlockedClient } from '@/services';
import { SET_BLOCK_CLIENTS, SET_BLOCKABLE_INTERFACES } from '@/store/mutation-types';
import { MAX_BLOCKED_CLIENT } from '@/common/client';

const state = {
  blockedClients: [],
  blockableInterfaces: [],
};

const getters = {
  isBlockListFull(state) {
    return state.blockedClients.length >= MAX_BLOCKED_CLIENT;
  },
};

const actions = {
  async getBlockClients({ commit }) {
    const blockedClients = await BlockedClient.getAll();

    commit(SET_BLOCK_CLIENTS, blockedClients);
  },

  async createBlockedClient({ dispatch, commit }, blockedClient) {
    await BlockedClient.create(blockedClient);
    await dispatch('getBlockClients');
  },

  async patchBlockedClient({ dispatch, commit }, blockedClient) {
    await BlockedClient.patch(blockedClient);
    await dispatch('getBlockClients');
  },

  async deleteBlockedClient({ dispatch, commit }, blockedClient) {
    await BlockedClient.delete(blockedClient);
    await dispatch('getBlockClients');
  },

  async getBlockableInterfaces({ commit }) {
    const blockableInterfaces = await BlockedClient.getBlockableInterfaces();

    commit(SET_BLOCKABLE_INTERFACES, blockableInterfaces);
  },
};

const mutations = {
  [SET_BLOCKABLE_INTERFACES](state, blockableInterfaces) {
    state.blockableInterfaces = blockableInterfaces;
  },
  [SET_BLOCK_CLIENTS](state, blockedClients) {
    state.blockedClients = blockedClients;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
