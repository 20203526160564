
<div
  id="loading-spinner"
  :style="style"
>
  <div id="yellow" />
  <div id="green" />
  <div id="cyan" />
  <div id="white" />
</div>
