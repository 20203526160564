import _ from 'lodash';
import SERVICES from '@/services';

const state = {
  usbInfo: [],
  ftpServer: {
    enabled: false,
    concurrentConn: '',
    encoding: '',
    wanConnEnabled: false,
    localLink: [],
    remoteLink: [],
  },
  ftpUsers: [],
  ftpUsernameList: [],
};

const getters = {};

const actions = {
  async getUsbInfo({ commit }) {
    const res = await SERVICES.USB.getUsbInfo();

    commit('setUsbInfo', res.result);
  },
  async getFtpServer({ commit }) {
    const ftpServer = await SERVICES.USB.getFtpServer();

    commit('setFtpServer', ftpServer);
  },
  async putFtpServer({ state, dispatch }, payload) {
    await SERVICES.USB.putFtpServer({
      ...state.ftpServer,
      ...payload,
    });
    await dispatch('getFtpServer');
  },
  async getFtpUser({ commit }) {
    const res = await SERVICES.USB.getFtpUser();

    commit('setFtpUser', res.result);
  },
  async putFtpUser({ commit }, payload) {
    return SERVICES.USB.putFtpUser(payload);
  },
  async deleteFtpUser({ commit }, payload) {
    return SERVICES.USB.deleteFtpUser(payload);
  },
  async postFtpUser({ commit }, payload) {
    return SERVICES.USB.postFtpUser(payload);
  },
  async putFtpDb({ commit }) {
    return SERVICES.USB.putFtpDb();
  },
};

const mutations = {
  setUsbInfo(state, payload) {
    state.usbInfo = payload.usbInfoList.map((obj, index) => ({
      name: `usb${index + 1}`,
      usb_index: index + 1,
      ...obj,
    }));
  },
  setFtpServer(state, payload) {
    Object.keys(payload).forEach((key, index) => {
      if (Object.prototype.hasOwnProperty.call(payload, key)) {
        state.ftpServer[key] = payload[key];
      }
    });
  },
  setFtpUser(state, payload) {
    state.ftpUsers = payload.sort(function (a, b) {
      return a.id - b.id;
    });
    state.ftpUsernameList = _.map(payload, 'username');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
