import apiPath from './apiPath';
import { qFetch } from './utilities';

export default {
  async getDdnsInfo() {
    return qFetch.get(apiPath.DdnsInfo);
  },
  async putDdns(payload) {
    return qFetch.put(apiPath.Ddns, payload);
  },
  async updateDdnsInfo() {
    return qFetch.put(apiPath.DdnsInfo);
  },
  async getDdnsWan() {
    return qFetch.get(apiPath.DdnsWan);
  },
};
