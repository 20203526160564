import { AirGap } from '@/services';
import {
  SET_AIR_GAP_DEVICES,
  SET_AIR_GAP_PORT_IDS,
} from '@/store/mutation-types';

const state = {
  airGapDevices: [],
  airGapPortIds: [],
};

const actions = {
  async fetchAirGapDevices({ rootGetters, commit }) {
    if (!rootGetters['Profile/supportAirGap']) {
      return;
    }

    const devices = await AirGap.getDevices();

    commit(SET_AIR_GAP_DEVICES, devices);
  },

  async fetchAirGapPortIds({ rootGetters, commit }) {
    if (!rootGetters['Profile/supportAirGap']) {
      return;
    }
    const ids = await AirGap.getPortIds();

    commit(SET_AIR_GAP_PORT_IDS, ids);
  },
};

const mutations = {
  [SET_AIR_GAP_DEVICES](state, devices) {
    state.airGapDevices = devices;
  },
  [SET_AIR_GAP_PORT_IDS](state, ids) {
    state.airGapPortIds = ids;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
