export const CHART_COLORS = [
  '#9DDFF8',
  '#F7E225',
  '#F89DB4',
  '#BBBB7A',
  '#CB75A8',
  '#3D60A9',
  '#9C69F0',
  '#623116',
  '#DBA030',
];

export function getPieDataColor(index) {
  return index < CHART_COLORS.length
    ? CHART_COLORS[index]
    : CHART_COLORS[CHART_COLORS.length - 1];
}
