<template>
  <DialogForm
    type="QuwanAddRegion"
    title="ID_QUWAN_DIALOG_REGION_ADD_TITLE"
    submit-label="ID_ADD"
    width="460"
    height="300"
    @submit="submit"
  >
    <q-row
      class="fz-14 mb-10-px"
      align-v="center"
    >
      <q-col flex-width="8rem">
        {{ $t('ID_QUWAN_DIALOG_REGION_ADD_TYPE_LABEL') }}
      </q-col>
      <q-col>
        <InputRadioGroup
          id="quwan-add-region-type"
          v-model="regionSetting.domainName"
          :options="options"
          :rules="{ required: true }"
          disabled
        />
      </q-col>
    </q-row>
    <q-row
      class="fz-14"
      align-v="center"
    >
      <q-col flex-width="8rem">
        {{ $t('ID_QUWAN_DIALOG_REGION_REGION_LABEL') }}
      </q-col>
      <q-col>
        <InputText
          id="quwan-settings-region-name"
          v-model="regionSetting.groupName"
          :rules="{
            required: true,
            excluded: {
              list: regionNames,
              message: 'ID_ERROR_MESSAGE_DUPLICATED_REGION_NAME',
            },
            max: {
              length: 64,
              message: $t('ID_DESCRIPTION_FIELD_MAX_LENGTH_MSG', { max: 64 }),
            },
          }"
        />
      </q-col>
    </q-row>
  </DialogForm>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import REGION_DOMAIN_TYPE from '@/enums/RegionDomainType';
import DialogForm from '@/components/Dialog/DialogForm.vue';
import InputRadioGroup from '@/components/Input/InputRadioGroup.vue';
import InputText from '@/components/Input/InputText.vue';

export default {
  name: 'QuwanAddRegionDialog',
  components: {
    DialogForm,
    InputText,
    InputRadioGroup,
  },
  props: {
    organizationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      regionSetting: {
        groupName: '',
        domainName: '',
      },
      options: [
        { label: 'ID_QUWAN_DIALOG_REGION_ADD_TYPE_GLOBAL', value: REGION_DOMAIN_TYPE.GLOBAL },
        { label: 'ID_QUWAN_DIALOG_REGION_ADD_TYPE_CHINA', value: REGION_DOMAIN_TYPE.CHINA },
      ],
    };
  },
  computed: {
    ...mapState('Initial', ['machineInfo']),
    ...mapState('Quwan', ['regions']),

    regionNames() {
      return this.regions.map((region) => region.regionName);
    },
  },
  created() {
    this.regionSetting.domainName = this.machineInfo.region;
  },
  methods: {
    ...mapActions('Quwan', ['createRegion']),

    async submit() {
      try {
        this.$loading.show();

        await this.createRegion({
          organizationId: this.organizationId,
          regionSetting: this.regionSetting,
        });

        this.$dialog.close('QuwanAddRegion');
      } catch (error) {
        this.$notify(error.type, error.message);
      } finally {
        this.$loading.hide();
      }
    },
  },
};
</script>
