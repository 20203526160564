
<div>
  <ModalConfirm
    :id="id"
    title="ID_FW_UPDATE_TITLE"
    force
  >
    <div class="space-y-10-px">
      {{ `${$t('ID_FIRMWARE_CUR_VER')} ${qurouterVersion}` }}
      <TipMessage
        type="warning"
        text="ID_FW_UPDATE_DESC"
      />
      <TipMessage text="ID_FW_UPDATE_HINT" />
      <TipMessage text="ID_QPKG_INCOMPATIBILITY_DESC" />
    </div>
    <template #modal-footer="{ cancel }">
      <ButtonText
        class="mr-10-px"
        :label="supportLiveUpdate ? 'ID_HORA_INIT_UPDATE' : 'ID_QPKG_REDIRECT_ROUTER_BTN'"
        @click="updateAction"
      />
      <ButtonText
        v-if="!forceUpdate"
        theme="secondary"
        label="ID_HORA_INIT_SKIP"
        @click="cancel"
      />
    </template>
  </ModalConfirm>
  <FirmwareBackupFailedConfirmModal />
</div>
