
<!-- Set title prop to avoid to set HTML attribute and show tooltip because of v-bind="$attrs" -->
<DialogBase
  :type="type"
  :title="title"
  v-bind="$attrs"
>
  <template #title>
    <slot name="title" />
  </template>

  <FormBase
    class="flex-fluid df fd-column ov-auto"
    @submit="$emit('submit')"
  >
    <div class="flex-fluid df fd-column plr-35-px ov-auto pt-20-px">
      <slot />
    </div>

    <div class="df jc-end ai-center plr-30-px pt-20-px pb-25-px">
      <ButtonText
        :label="submitLabel"
        :theme="submitTheme"
        class="mr-20-px"
        type="submit"
      />
      <ButtonText
        :label="cancelLabel"
        theme="secondary"
        @click="cancel"
      />
    </div>
  </FormBase>
</DialogBase>
