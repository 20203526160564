
<LoadingBase
  id="app-loading"
  :visible="visible"
  :text="displayText"
  :detail="detail"
  :show-backdrop="showBackdrop"
  :wrapper-key="key"
  :cancel="cancel ? handleCancel : null"
/>
