<template>
  <!-- Set null title to avoid to set HTML attribute and show tooltip because of v-bind="$attrs" -->
  <component
    :is="type"
    :type="type"
    :title="null"
    v-bind="{...config}"
  />
</template>

<script>
import QosPercentageChart from '@/views/Quwan/Qos/QosPercentageChartDialog.vue';
import QuwanAddRegion from '@/views/Quwan/QuwanAddRegionDialog.vue';
import QuwanBehindNatHintDialog from '@/views/Quwan/QuwanBehindNatHintDialog.vue';
import QuwanCloudDisconnectedReasons from '@/views/Quwan/QuwanCloudDisconnectedReasonsDialog.vue';
import VpnSettingsQbeltInstruction from '@/views/Vpn/Settings/Qbelt/VpnSettingsQbeltInstructionDialog.vue';
import VapExplanationDialog from '@/views/Wireless/VapExplanationDialog.vue';

export default {
  name: 'DialogContainer',
  components: {
    VpnSettingsQbeltInstruction,
    QosPercentageChart,
    QuwanAddRegion,
    QuwanCloudDisconnectedReasons,
    QuwanBehindNatHintDialog,
    VapExplanationDialog,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    config: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
