import NOTIFY_LEVEL from '@/enums/NotifyLevel';

const state = {
  hidden: () => {},
  visible: false,
  text: '',
  type: NOTIFY_LEVEL.ERROR,
};

const mutations = {
  show(state, options) {
    state.hidden = options.hidden;
    state.visible = true;
    state.text = options.text;
    state.type = options.type;
  },
  hide(state) {
    state.visible = false;
    state.text = '';
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
