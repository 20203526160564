<template>
  <ModalNotifyBase
    :id="id"
    :visible="visible"
    :message="message"
    :confirm-label="label"
    :type="type"
    @confirm="$emit('confirm')"
    @cancel="$emit('cancel')"
    @after-hide="$emit('after-hide')"
  >
    <template #default>
      <slot />
    </template>
    <template #message>
      <slot name="message" />
    </template>
    <template #message-extra>
      <slot name="message-extra" />
    </template>
    <template #modal-footer="{ cancel }">
      <slot
        name="modal-footer"
        :cancel="cancel"
      />
    </template>
  </ModalNotifyBase>
</template>

<script>
import ModalNotifyBase from './ModalNotifyBase.vue';

export default {
  name: 'ModalNotify',
  components: {
    ModalNotifyBase,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
    message: {
      type: [String, Array],
      default: null,
    },
    type: {
      type: String,
      default: 'error',
    },
    label: {
      type: String,
      default: 'ID_OK',
    },
  },
};
</script>
