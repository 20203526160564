import CLIENT_CONNECTION_TYPE from '@/enums/ClientConnectionType';
import apiPath from '../apiPath';
import { qFetch } from '../utilities';

export default {
  /**
   * Get all client information, including both online and offline clients
   * @returns {Promise<Object>} Pagination info of client list
   */
  async getClients() {
    const { result } = await qFetch.get(apiPath.v2.Clients);

    return result.clientsData.map((client) => {
      const displayedIp = client.ip || client.dhcpIp;

      return {
        ...client,
        displayedIp,
        blockable: client.connectionType !== CLIENT_CONNECTION_TYPE.ETHERNET || !!displayedIp,
      };
    });
  },

  /**
   * Get the traffic info of the client
   * @returns {Promise<Object>} Traffic info of the client
   */
  async getClientTraffic(clientId) {
    const { result } = await qFetch.get(apiPath.v2.getClientTraffic(clientId));

    return result;
  },

  /**
   * Update description of a client
   * @param {Object} clientConfig
   * @param {number} clientConfig.id - client id
   * @param {string} clientConfig.value - new description
   * @returns {Promise}
   */
  async patchClient(clientConfig) {
    await qFetch.patch(`${apiPath.v2.Clients}/${clientConfig.id}`, {
      data: {
        description: clientConfig.value,
      },
    });
  },
  /**
   * Delete a client
   * @param {Object} clientConfig
   * @param {number} clientConfig.id - client id
   * @returns {Promise}
   */
  async deleteClient(clientConfig) {
    await qFetch.delete(`${apiPath.v2.Clients}?mode=single&idMin=${clientConfig.id}`);
  },
  /**
   * Delete all offline clients
   * @returns {Promise}
   */
  async deleteAllOfflineClient() {
    await qFetch.delete(`${apiPath.v2.Clients}?mode=all&filterBy=disconnected`);
  },
};
