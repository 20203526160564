
<DialogHint
  name="QuwanBehindNatHintDialog"
  :title="$t('ID_QUWAN_BEHIND_NAT_HINT_TITLE')"
>
  <div class="fz-14 pr-55-px">
    <p>{{ $t("ID_QUWAN_BEHIND_NAT_HINT_MSG_START") }}</p>
    <ButtonLink
      path-name="ServicePort"
      label="ID_MENU_SERVICE_PORT_MANAGEMENT"
      @before-navigate="$dialog.close('QuwanBehindNatHintDialog')"
    />
  </div>
</DialogHint>
