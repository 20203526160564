import store from '@/store';
import NOTIFY_LEVEL from '@/enums/NotifyLevel';

export default {
  install(Vue) {
    const notifyHandler = (type, options) => {
      options = (typeof options === 'string' || Array.isArray(options)) ? { text: options } : options;
      options.type = type || NOTIFY_LEVEL.ERROR;

      // skip this notification if the text is empty
      if (!options.text) {
        return Promise.resolve();
      }

      return new Promise((resolve) => {
        options.hidden = () => {
          store.commit('Plugin/Notify/hide');
          resolve(true);
        };

        store.commit('Plugin/Notify/show', options);
      });
    };

    const notify = function (type, options) {
      return notifyHandler(type, options);
    };

    notify.info = (options) => notifyHandler(NOTIFY_LEVEL.INFO, options);
    notify.warn = (options) => notifyHandler(NOTIFY_LEVEL.WARN, options);
    notify.error = (options) => notifyHandler(NOTIFY_LEVEL.ERROR, options);
    Vue.prototype.$notify = notify;
  },
};
