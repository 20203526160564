import { IPv4, IPv6 } from 'ipaddr.js';
import { range } from 'lodash';
import {
  checkIpValid,
  Mb1000ToUnitSize,
  convertIpToInt,
  isInteger,
  checkMacAddressValid,
} from '@/common/utilities';
import LAN_DHCP_SERVICE_TYPE from '@/enums/LanDhcpServiceType';
import LINE_RATE from '@/enums/LineRate';
import PORT_LINK_STATUS from '@/enums/PortLinkStatus';
import { i18n } from '@/lang';

// Pattern of validate FQDN format
// Based on: https://stackoverflow.com/questions/18011182/regular-expression-to-validate-fqdn
const FQDN_PATTERN = /(?=^.{1,254}$)(^(?:(?!\d|-)[a-z0-9-]{0,62}[a-z0-9]\.?)+(?:[a-z]{2,})$)/i;

/**
 * Ensure type of value from payload which type must be number is number.
 * @param {any} value
 * @returns {number} numeric value
 */
function handlePayloadNumber(value) {
  if (typeof value === 'string') {
    return parseFloat(value);
  }

  if (typeof value !== 'number') {
    return 0;
  }

  return value;
}

export const DEFAULT_SUBNET_PREFIX = 24;
export const CONFLICT_IGNORED_PREFIX = 32;
export const IPSEC_PORTS = ['500', '4500'];
export const DEFAULT_DISPLAYED_VALUE = '--';

/**
 * Compare two IPv6 address
 * The return value format is referenced from compareFunction of Array.prototype.sort
 * @param {string} ip - IPv6 format
 * @param {string} comparedIp - IPv6 format
 * @returns {number} greater: 1, equal: 0, less: -1
 */
export function compareIpv6Address(ip, comparedIp) {
  const { parts } = IPv6.parse(ip);
  const comparedParts = IPv6.parse(comparedIp).parts;
  let res = 0;

  parts.find((part, idx) => {
    const comparedPart = comparedParts[idx];

    if (part > comparedPart) {
      res = 1;

      return true;
    }

    if (part < comparedPart) {
      res = -1;

      return true;
    }

    return false;
  });

  return res;
}

/**
 * Convert a decimal number to a MAC address
 * @param {number} decimal - The decimal number to be convert to MAC address
 * @returns {string} MAC address converted from the decimal number
 */
export function convertIntegerToMacAddress(decimal) {
  if (!Number.isInteger(decimal) || decimal < 0) {
    return '';
  }

  // Check if the decimal number exceeds the maximum value for 48 bit
  // Subtract 1 because of the number starts from 0
  const maxDecimalNumber = (2 ** 48) - 1;

  if (decimal > maxDecimalNumber) {
    return '';
  }

  const RADIX = 16; // hexadecimal
  const MAC_ADDRESS_NUMBER_AMOUNT = 12; // xx:xx:xx:xx:xx:xx
  const PAD_STRING = '0';
  const hexString = decimal.toString(RADIX);
  const paddedHexString = hexString.padStart(MAC_ADDRESS_NUMBER_AMOUNT, PAD_STRING);

  return paddedHexString.replace(/(\w{2})(?=\w{2})/g, '$1:');
}

/**
 * Convert a MAC address to a decimal number
 * @param {string} macAddress - MAC address to be converted to decimal number
 * @returns {number} The decimal number converted from MAC address
 */
export function convertMacAddressToInteger(macAddress) {
  if (!checkMacAddressValid(macAddress)) {
    return NaN;
  }

  const RADIX = 16; // hexadecimal
  const formattedMacAddress = macAddress.replace(/:/g, '');

  return parseInt(formattedMacAddress, RADIX);
}

/**
 * Get the DNS IP to be displayed on GUI
 * @param {string[]} dnsList - List of DNS IPs
 * @returns {string} DNS IP to be displayed on GUI
 */
export function getDisplayedDns(dnsList) {
  if (!Array.isArray(dnsList) || !dnsList.length) {
    return DEFAULT_DISPLAYED_VALUE;
  }

  return dnsList.join(', ');
}

/**
 * Get the DHCP range to be displayed on GUI
 * @param {Object} lanPort - LAN port with DHCP range
 * @returns {string} DHCP range to be displayed on GUI
 */
export function getDisplayedDhcpRange(lanPort) {
  if (!lanPort?.dhcpService) {
    return DEFAULT_DISPLAYED_VALUE;
  }

  if (lanPort.dhcpService.serviceType !== LAN_DHCP_SERVICE_TYPE.SERVER) {
    return DEFAULT_DISPLAYED_VALUE;
  }

  if (!lanPort.dhcpService.startIp || !lanPort.dhcpService.endIp) {
    return DEFAULT_DISPLAYED_VALUE;
  }

  return `${lanPort.dhcpService.startIp} - ${lanPort.dhcpService.endIp}`;
}

/**
 * Get the link rate to be displayed on GUI
 * @param {Object} port - Port with the link rate
 * @returns {string} The link rate to be displayed on GUI
 */
export function getDisplayedLinkRate(port) {
  if (!port || port.status !== PORT_LINK_STATUS.UP) {
    return DEFAULT_DISPLAYED_VALUE;
  }

  if (typeof port.linkRate !== 'number' || Number.isNaN(port.linkRate) || port.linkRate < 0) {
    return DEFAULT_DISPLAYED_VALUE;
  }

  const { num, unit } = Mb1000ToUnitSize(port.linkRate);

  return `${num}${unit}`;
}

/**
 * Convert IPv6 part from ipaddr.js to binary string,
 *  then calc number of continuously zeros from end.
 * ex:
 *  - part: 32000
 *  - part to binary: 0111 1101 0000 0000
 *  - continuously zeros from end: 0000 0000
 *  - return: 8
 * @param {number} part - part from ipaddr.parse
 * @returns {number} number of continuously zeros from end
 */
function getIpv6PartBinaryZeroLengthFromEnd(part) {
  const partToBinary = part.toString(2).padStart(16, '0')
    .split('').reverse()
    .join('');
  const matchZero = partToBinary.match(/^0+/);

  return matchZero === null ? 0 : matchZero[0].length;
}

/**
 * Convert IPv6 part from ipaddr.js to binary string,
 *  then calc number of continuously zeros from start.
 * ex:
 *  - part: 3111
 *  - part to binary: 0000 1100 0010 0111
 *  - continuously zeros from start: 0000
 *  - return: 4
 * @param {number} part - part from ipaddr.parse
 * @returns {number} number of continuously zeros from start
 */
function getIpv6PartBinaryZeroLengthFromStart(part) {
  const partToBinary = part.toString(2).padStart(16, '0');
  const matchZero = partToBinary.match(/^0+/);

  return matchZero === null ? 0 : matchZero[0].length;
}

/**
 * Get length of IPv6 prefix.
 * ex: "1111:1111:1111:1111:0f00::"
 *  - binary of "0f00": "0000 1111 0000 0000"
 *  - length of "0000 1111": 8
 *  - prefix length: 16 * 3 + 8 = 56
 * @param {string} value - Ipv6 format
 * @returns {number} length of IPv6 prefix
 */
function getIpv6PrefixLength(value) {
  const reversedParts = IPv6.parse(value).parts.reverse();
  const start = reversedParts.findIndex((part) => part !== 0);

  if (start === -1) {
    return 0;
  }

  return reversedParts.slice(start + 1)
    .reduce((cur) => cur + 16, 16 - getIpv6PartBinaryZeroLengthFromEnd(reversedParts[start]));
}

/**
 * Get length of IPv6 suffix.
 * ex: "::0f00:1111:1111:1111:"
 *  - binary of "0f00": "0000 1111 0000 0000"
 *  - length of "1111 0000 0000": 12
 *  - suffix length: 12 + 16 * 3 = 60
 * @param {string} value - Ipv6 format
 * @returns {number} length of IPv6 suffix
 */
function getIpv6SuffixLength(value) {
  const { parts } = IPv6.parse(value);
  const start = parts.findIndex((part) => part !== 0);

  if (start === -1) {
    return 0;
  }

  return parts.slice(start + 1)
    .reduce((cur) => cur + 16, 16 - getIpv6PartBinaryZeroLengthFromStart(parts[start]));
}

/**
 * Normalize IPv6 address
 * @param {string} ip - IPv6 IP address
 * @returns {string} normalized IP
 */
export function normalizeIpv6Ip(ip) {
  return IPv6.isValid(ip) ? IPv6.parse(ip).toString() : ip;
}

/**
 * validate subnet id is in valid range and not be repeated with other VLAN setting
 * @param {string} value - subnet id
 * @param {string|number} params.pdLength - length of DHCP-PD
 * @param {string[]} params.otherSubnetIds - subnet id can't be repeated with
 *                                           subnet id of other VLAN setting
 * @param {string} param.realSubnetId - if realSubnetId is given, value will replace by realSubnetId
 * @returns {boolean|string} True if valid subnet id, else error message
 */
export function validateIpv6SubnetId(value, { pdLength, otherSubnetIds = [], realSubnetId }) {
  if (realSubnetId !== undefined) {
    value = realSubnetId;
  }

  if (IPv6.isValid(value)) {
    pdLength = handlePayloadNumber(pdLength);
    value = normalizeIpv6Ip(value);
    otherSubnetIds = otherSubnetIds
      .filter((subnetId) => IPv6.isValid(subnetId))
      .map((subnetId) => normalizeIpv6Ip(subnetId));

    if (otherSubnetIds.includes(value)) {
      return 'ID_IPV6_VLAN_SUBNET_ID_USED_ERR';
    }
    const subnetIdLen = getIpv6SuffixLength(value);

    if (subnetIdLen + pdLength > 64) {
      if (pdLength === 64) {
        return 'ID_IPV6_VLAN_SUBNET_ID_RANGE_ZERO_ERR';
      }
      const rangeMax = parseInt(Array(64 - pdLength).fill('1').join(''), 2)
        .toString(16)
        .replace(/f{4}/g, 'ffff:');

      return i18n.t('ID_IPV6_VLAN_SUBNET_ID_RANGE_ERR', { min: 0, max: rangeMax });
    }

    return true;
  }

  return 'ID_IPV6_VLAN_PREVIEW_INVALID';
}

/**
 * validate length of suffix.
 * @param {string} value - IPv6 format
 * @param {number|string} param.ip6Prefix
 * @param {string} param.realSuffix - if realSuffix is given, value will replace by realSuffix
 * @returns {boolean} - True if length of suffix+prefix not exceed 128
 */
export function validateIpv6Suffix(value, { ip6Prefix, realSuffix }) {
  if (realSuffix !== undefined) {
    value = realSuffix;
  }

  if (IPv6.isValid(value)) {
    ip6Prefix = handlePayloadNumber(ip6Prefix);

    return getIpv6SuffixLength(value) + ip6Prefix <= 128;
  }

  return false;
}

/**
 * validate length of prefix.
 * @param {string} value - IPv6 format
 * @param {number|string} param.ip6Prefix - given prefix length
 * @param {string} param.realPrefix - if realPrefix is given, value will replace by realPrefix
 * @returns {boolean} - True if length of prefix not exceed given prefix length
 */
export function validatePrefixLength(value, { ip6Prefix, realPrefix }) {
  if (realPrefix !== undefined) {
    value = realPrefix;
  }

  if (IPv6.isValid(value)) {
    ip6Prefix = handlePayloadNumber(ip6Prefix);

    return getIpv6PrefixLength(value) <= ip6Prefix;
  }

  return false;
}

/**
 * @typedef SplitIPv6AddrData
 * @type {object}
 * @property {string} prefix - IPv6 Prefix
 * @property {string} suffix - IPv6 Suffix
 * @property {string|null} subnetId - IPv6 subnet id. null if DHCP-PD is disabled.
 */
/**
 * split the ipv6 address to prefix, subnet-id and suffix
 * @param {string} ipAddr - IPv6 format address
 * @param {number|string} payload - number: length of manually prefix, string: DHCP-PD
 * @return {SplitIPv6AddrData} data contains prefix, suffix and subnet-id of IPv6
 */
export function splitIpv6Addr(ipAddr, payload) {
  if (!ipAddr) {
    return null;
  }

  if (typeof payload === 'number') {
    const ip6Prefix = payload;

    if (ip6Prefix < 64) {
      return null;
    }
    const start = Math.floor(ip6Prefix / 16);
    const ip = IPv6.parse(ipAddr);

    if (ip6Prefix % 16 === 0) {
      const prefixParts = ip.parts.map((part, idx) => (idx >= start ? 0 : part));
      const suffixParts = ip.parts.map((part, idx) => (idx < start ? 0 : part));

      return {
        prefix: (new IPv6(prefixParts)).toString(),
        subnetId: null,
        suffix: (new IPv6(suffixParts)).toString(),
      };
    }
    const len = ip6Prefix % 16;
    const originalStr = ip.parts[start].toString(2).padStart(16, '0');
    const prefixStr = originalStr.slice(0, len);
    const suffixStr = originalStr.slice(len, 16);
    const prefixParts = ip.parts.map((part, idx) => {
      if (idx === start) {
        return parseInt(prefixStr.padEnd(16, '0'), 2);
      }

      return idx > start ? 0 : part;
    });
    const suffixParts = ip.parts.map((part, idx) => {
      if (idx === start) {
        return parseInt(suffixStr, 2);
      }

      return idx < start ? 0 : part;
    });

    return {
      prefix: (new IPv6(prefixParts)).toString(),
      subnetId: null,
      suffix: (new IPv6(suffixParts)).toString(),
    };
  }

  if (typeof payload === 'string') {
    const pd = payload;
    const ip = IPv6.parse(ipAddr);
    const prefixParts = ip.parts.map((part, idx) => (idx >= 4 ? 0 : part));
    const suffixParts = ip.parts.map((part, idx) => (idx < 4 ? 0 : part));
    const [pdIp, pdLength] = IPv6.parseCIDR(pd);
    const tmpSubnetIdParts = pdIp.parts.map((part, idx) => prefixParts[idx] - part);

    if (tmpSubnetIdParts.find((part) => part < 0)) {
      // current prefix not match given DHCP-PD
      return null;
    }
    const subnetIdParts = tmpSubnetIdParts
      .map((part, idx) => (idx < 4 ? 0 : tmpSubnetIdParts[idx - 4]));

    const subnetId = (new IPv6(subnetIdParts)).toString();

    if (validateIpv6SubnetId(subnetId, { pdLength }) !== true) {
      // current prefix not match given DHCP-PD
      return null;
    }

    return {
      prefix: (new IPv6(prefixParts)).toString(),
      subnetId,
      suffix: (new IPv6(suffixParts)).toString(),
    };
  }

  return null;
}

/**
 * Get length of DHCP-PD from CIDR format: (ipv6 format)/xx
 * @param {string} pd - DHCP-PD prefix
 * @returns {number} length of DHCP-PD prefix
 */
export function getIpv6PdLength(pd) {
  return IPv6.parseCIDR(pd)[1];
}

/**
 * Get IPv6 prefix from original IPv6 address
 * @param {string} ip - IPv6 format
 * @param {string|number} ip6Prefix - prefix length
 * @returns {string} IPv6 prefix
 */
export function getIpv6VlanPrefix(ip, ip6Prefix) {
  const ipData = splitIpv6Addr(ip, handlePayloadNumber(ip6Prefix));

  return ipData ? ipData.prefix : null;
}

/**
 * Get IPv6 suffix from IPv6 address
 * @param {string} ip - IPv6 format
 * @param {string|number} ip6Prefix - prefix length
 * @returns {string} IPv6 suffix
 */
export function getIpv6VlanSuffix(ip, ip6Prefix) {
  if (ip === '') {
    return '';
  }
  const ipData = splitIpv6Addr(ip, handlePayloadNumber(ip6Prefix));

  return ipData ? ipData.suffix : null;
}

/**
 * Get IPv6 prefix from DHCP-PD
 * @param {string} prefixDhcp6Pd - IPv6 format
 * @param {string} subnetId - IPv6 subnet id
 * @returns {string} IPv6 prefix
 */
export function getPrefixFromPd(prefixDhcp6Pd, subnetId) {
  const pdLength = getIpv6PdLength(prefixDhcp6Pd);

  if (validateIpv6SubnetId(subnetId, { pdLength, otherSubnetIds: [] }) !== true) {
    return null;
  }
  const ip = IPv6.parseCIDR(prefixDhcp6Pd)[0];

  // subnet id
  IPv6.parse(subnetId).parts.slice(4, 8).forEach((part, idx) => {
    ip.parts[idx] += part;
  });

  return ip.toString();
}

/**
 * Get IPv6 address from prefix and suffix
 * @param {string} prefix - IPv6 format
 * @param {string} suffix - IPv6 format
 * @param {number|string} ip6Prefix - prefix length
 * @returns {string} IPv6 Address
 */
export function getIpv6VlanIp(prefix, suffix, ip6Prefix) {
  ip6Prefix = handlePayloadNumber(ip6Prefix);

  if (!validateIpv6Suffix(suffix, { ip6Prefix })) {
    return null;
  }

  if (!IPv6.isValid(prefix) || !validatePrefixLength(prefix, { ip6Prefix })) {
    return null;
  }

  // length of prefix and suffix has been validate, so dont need to handle if ip6Prefix % 16 != 0
  const ip = IPv6.parse(prefix);
  const start = Math.floor(ip6Prefix / 16);

  IPv6.parse(suffix).parts.slice(start, 8).forEach((part, idx) => {
    ip.parts[idx + start] += part;
  });

  return ip.toString();
}

/**
 * validate value is FQDN format
 * @param {string} value
 * @returns {boolean} True if given value is FQDN format
 */
export function validateFQDN(value) {
  return FQDN_PATTERN.test(value);
}

/**
 * Check the IPv4 subnet with CIDR is valid.
 * @param {string} ip4Subnet - The IPv4 subnet with CIDR
 * @returns {boolean} True if the IPv4 subnet with CIDR is valid
 */
export function checkIp4Subnet(ip4Subnet) {
  try {
    IPv4.parseCIDR(ip4Subnet);
  } catch (err) {
    // invalid IPv4 subnet
    return false;
  }

  return true;
}

/**
 * Check if the IPv4 address is not the network or broadcast address.
 * @param {string} ip4Address - The IPv4 address
 * @param {number} ip4Prefix - The subnet mask
 * @returns {boolean} True if the IPv4 address is not the network or broadcast address
 */
export function checkIp4AddressWithPrefixNotReserved(ip4Address, ip4Prefix) {
  if (!checkIpValid(ip4Address) || ip4Prefix === 32) {
    return true;
  }

  const ip4Subnet = `${ip4Address}/${ip4Prefix}`;

  if (!checkIp4Subnet(ip4Subnet)) {
    return true;
  }

  const ipNetwork = IPv4.networkAddressFromCIDR(ip4Subnet).toString();
  const ipBroadcast = IPv4.broadcastAddressFromCIDR(ip4Subnet).toString();

  return ip4Address !== ipNetwork && ip4Address !== ipBroadcast;
}

/**
 * Check IPv6 subnet with CIDR is valid.
 * @param {string} ip6Subnet - IPv6 subnet with CIDR
 * @returns {boolean} True if IPv6 subnet is valid
 */
export function checkIp6Subnet(ip6Subnet) {
  try {
    IPv6.parseCIDR(ip6Subnet);
  } catch (err) {
    // invalid IPv6 submit
    return false;
  }

  return true;
}

/**
 * Check subnet overlap target subnet
 * @param {string} ip6Address - IPv6 address of subnet
 * @param {number|string} ip6Prefix - IPv6 prefix length of subnet
 * @param {string} targetIp6Address - IPv6 address of target subnet
 * @param {number|string} targetIp6Prefix - IPv6 prefix length of target subnet
 * @return {boolean} True if subnet overlap target subnet and all params format is valid.
 */
export function ip6SubnetOverlapSubnet(
  ip6Address,
  ip6Prefix,
  targetIp6Address,
  targetIp6Prefix,
) {
  if (!IPv6.isValid(ip6Address) || !isInteger(ip6Prefix)
    || !IPv6.isValid(targetIp6Address) || !isInteger(targetIp6Prefix)) {
    return false;
  }
  const ipItem = IPv6.networkAddressFromCIDR(`${ip6Address}/${ip6Prefix}`);
  const targetIpItem = IPv6.networkAddressFromCIDR(`${targetIp6Address}/${targetIp6Prefix}`);

  return ipItem.match(targetIpItem, targetIp6Prefix) || targetIpItem.match(ipItem, ip6Prefix);
}

/**
 * Check IPv6 IP in subnet
 * @param {string} ip6Address - IPv6 address
 * @param {string} targetIp6Address - IPv6 address of subnet
 * @param {string} targetIp6Prefix - IPv6 prefix length of subnet
 * @return {boolean} True if IP in subnet and all params format is valid.
 */
export function ip6AddressInSubnet(ip6Address, targetIp6Address, targetIp6Prefix) {
  if (!IPv6.isValid(ip6Address) || !IPv6.isValid(targetIp6Address) || !isInteger(targetIp6Prefix)) {
    return false;
  }

  if (typeof targetIp6Prefix === 'string') {
    targetIp6Prefix = parseInt(targetIp6Prefix, 10);
  }

  return IPv6.parse(ip6Address).match(IPv6.parse(targetIp6Address), targetIp6Prefix);
}

export async function ignoreFailedToFetch(handler) {
  try {
    await handler();
  } catch (error) {
    if (error.error_code !== 90000) {
      throw error;
    }
  }
}

/**
 * Check IPv6 IP in IP range
 * @param {string} ip6Address - IPv6 address
 * @param {string} startIp - start IP of IP range
 * @param {string} endIp - end IP of IP range
 * @returns {boolean} True if IP in IP range
 */
export function ip6AddressInRange(ip6Address, startIp, endIp) {
  if (!IPv6.isValid(ip6Address) || !IPv6.isValid(startIp) || !IPv6.isValid(endIp)) {
    return false;
  }

  return compareIpv6Address(ip6Address, startIp) > -1 && compareIpv6Address(ip6Address, endIp) < 1;
}

/**
 * Get the usable IP count of IPv4 address range.
 * @param {string} startIp - The start IP address of range
 * @param {string} endIp - The end IP address of range
 * @returns {number} The usable IP count of IPv4 address range
 */
export function getIp4AddressRangeUsableIpCount(startIp, endIp) {
  if (!IPv4.isValid(startIp) || !IPv4.isValid(endIp)) {
    return 0;
  }

  if (convertIpToInt(startIp) > convertIpToInt(endIp)) {
    return 0;
  }

  const startIpItem = IPv4.parse(startIp);
  const endIpItem = IPv4.parse(endIp);
  const OCTET_CARRY_VALUE = 256;
  const ADDRESS_OCTET_NUMS = 4;
  const usableIpCount = range(ADDRESS_OCTET_NUMS)
    .map((idx) => endIpItem.octets[idx] - startIpItem.octets[idx])
    .reverse()
    .reduce((current, item, idx) => current + (OCTET_CARRY_VALUE ** idx) * item, 1);

  return usableIpCount;
}

/**
 * Handle the TX rate from API for GUI
 * @param {number} txRate - TX rate(Bps) from API to be formatted
 * @returns {string} Formatted TX rate(Mbps) for GUI
 */
export function handleTxRateResponse(txRate) {
  const DEFAULT_VALUE = '';

  if (typeof txRate !== 'number'
      || Number.isNaN(txRate)
      || txRate < 0
  ) {
    return DEFAULT_VALUE;
  }

  return (txRate / LINE_RATE.MBPS).toString();
}

// /**
//  * Handle the TX rate from GUI for API
//  * @param {string} txRate - TX rate(Mbps) from GUI to be formatted
//  * @returns {number} Formatted TX rate(Bps) for API
//  */
export function handleTxRateRequest(txRate) {
  const DEFAULT_VALUE = 0;

  if (typeof txRate !== 'string' || !txRate) {
    return DEFAULT_VALUE;
  }

  const numericTxRate = Number(txRate);

  if (Number.isNaN(numericTxRate) || numericTxRate < 0) {
    return DEFAULT_VALUE;
  }

  return Number(txRate) * LINE_RATE.MBPS;
}

/**
 * Handle the RX rate from API for GUI
 * @param {number} rxRate - RX rate(Bps) from API to be formatted
 * @returns {string} Formatted RX rate(Mbps) for GUI
 */
export function handleRxRateResponse(rxRate) {
  if (typeof rxRate !== 'number'
      || Number.isNaN(rxRate)
      || rxRate <= 0
  ) {
    return DEFAULT_DISPLAYED_VALUE;
  }

  return (rxRate / LINE_RATE.MBPS).toString();
}

/**
 * Handle the RX rate from GUI for API
 * @param {string} rxRate - RX rate(Mbps) from GUI to be formatted
 * @returns {number} Formatted RX rate(Bps) for API
 */
export function handleRxRateRequest(rxRate) {
  const DEFAULT_VALUE = 0;

  if (typeof rxRate !== 'string' || !rxRate) {
    return DEFAULT_VALUE;
  }

  const numericTxRate = Number(rxRate);

  if (Number.isNaN(numericTxRate) || numericTxRate < 0) {
    return DEFAULT_VALUE;
  }

  return Number(rxRate) * LINE_RATE.MBPS;
}

/**
 * Indicate whether given string is a valid range of IPv6 IPs
 *
 * Format: xxx-yyy
 * - 1st part (xxx) must be a valid IPv6 IP address
 * - 2nd part (yyy) must be a valid IPv6 IP address equal or greater than 1st one
 *
 * @param {string} value - IP range to validate
 * @returns {boolean} True if valid IP range
 */
export function isIp6IpRange(value) {
  if (typeof value === 'string') {
    const ips = value.split('-');

    if (ips.length === 2) {
      return IPv6.isValid(ips[0])
        && IPv6.isValid(ips[1])
        && compareIpv6Address(ips[0], ips[1]) <= 0;
    }
  }

  return false;
}

/**
 * Convert the MAC address format to standard format for frontend validation and backend.
 * @param {string} macAddress - The MAC address
 * @returns {string} The formatted MAC address
 */
export function normalizeMacAddress(macAddress) {
  if (!macAddress || typeof macAddress !== 'string') {
    return '';
  }

  if (!checkMacAddressValid(macAddress)) {
    return macAddress;
  }

  return macAddress.replaceAll('-', ':').toUpperCase();
}

/**
 * Get the DHCP IP for the autofill
 * Note: This function will fill the incomplete IP address to the field of start/end IP.
 * @param {string} lanIp - IP of LAN interface
 * @param {number} lanSubnetPrefix - Subnet prefix of the LAN interface
 * @param {Object} dhcpService - DHCP service of the LAN interface
 * @param {string} dhcpService.serviceType - Service type of the DHCP service
 * @param {string} dhcpService.startIp - Start IP of the DHCP service
 * @param {string} dhcpService.endIp - End IP of the DHCP service
 * @returns {string} DHCP IP for the autofill
 */
export function getAutoFillDhcpIp(lanIp, lanSubnetPrefix, dhcpService) {
  const DEFAULT_VALUE = '';

  if (!dhcpService || dhcpService.serviceType !== LAN_DHCP_SERVICE_TYPE.SERVER) {
    return DEFAULT_VALUE;
  }

  if (!checkIpValid(lanIp)) {
    return DEFAULT_VALUE;
  }

  const { startIp, endIp } = dhcpService;
  const parsedLanIp = IPv4.parse(lanIp);

  if (checkIpValid(startIp)
    && checkIpValid(endIp)
    && IPv4.parse(startIp).match(parsedLanIp, lanSubnetPrefix)
    && IPv4.parse(endIp).match(parsedLanIp, lanSubnetPrefix)
  ) {
    return DEFAULT_VALUE;
  }

  const OCTET_BIT_LENGTH = 8;
  const networkIp = IPv4.networkAddressFromCIDR(`${lanIp}/${lanSubnetPrefix}`);

  return `${networkIp.octets.slice(0, Math.floor(lanSubnetPrefix / OCTET_BIT_LENGTH)).join('.')}.`;
}

/**
 * Check whether the reserved IP list is available to renew
 * @param {string} dhcpStartIp - Start IP of the DHCP service
 * @param {string} dhcpEndIp - End IP of the DHCP service
 * @param {number} reservedIpAmount - Amount of the reserved IPs to be updated
 * @returns {string} Empty string if the reserved IP list can be renewed or return warning message
 */
export function checkReservedIpListRenew(dhcpStartIP, dhcpEndIp, reservedIpAmount) {
  if (!checkIpValid(dhcpStartIP) || !checkIpValid(dhcpEndIp)) {
    return 'ID_LAN_AUTO_UPDATE_RESERVED_IP_START_END_IP_INVALID_MSG';
  }

  const usableIpCount = getIp4AddressRangeUsableIpCount(dhcpStartIP, dhcpEndIp);

  if (reservedIpAmount > usableIpCount) {
    return 'ID_LAN_AUTO_UPDATE_RESERVED_IP_USABLE_IP_COUNT_INVALID_MSG';
  }

  return '';
}

/**
 * Get the reserved IP list updated with the DHCP start/end IP
 * @param {string} dhcpStartIp - Start IP of the DHCP service
 * @param {Object[]} reservedIpList - List of the reserved IPs to be updated
 * @returns {Object[]} List of the updated reserved IPs
 */
export function getRenewedReservedIpList(dhcpStartIp, reservedIpList) {
  if (!checkIpValid(dhcpStartIp)) {
    return [];
  }

  const OCTET_CARRY_VALUE = 255;
  const currentIpItem = IPv4.parse(dhcpStartIp);

  return reservedIpList.map((reservedIp) => {
    const newIp = currentIpItem.toString();
    let currentOctetIdx = 3;

    currentIpItem.octets[currentOctetIdx] += 1;

    while (currentIpItem.octets[currentOctetIdx] > OCTET_CARRY_VALUE) {
      currentIpItem.octets[currentOctetIdx] = 0;
      currentOctetIdx -= 1;
      currentIpItem.octets[currentOctetIdx] += 1;
    }

    return {
      ...reservedIp,
      ip: newIp,
    };
  });
}
