<template>
  <!-- Set title prop to avoid to set HTML attribute and show tooltip because of v-bind="$attrs" -->
  <DialogBase
    :type="type"
    :title="title"
    v-bind="$attrs"
  >
    <template #title>
      <slot name="title" />
    </template>

    <FormBase
      class="flex-fluid df fd-column ov-auto"
      @submit="$emit('submit')"
    >
      <div class="flex-fluid df fd-column plr-35-px ov-auto pt-20-px">
        <slot />
      </div>

      <div class="df jc-end ai-center plr-30-px pt-20-px pb-25-px">
        <ButtonText
          :label="submitLabel"
          :theme="submitTheme"
          class="mr-20-px"
          type="submit"
        />
        <ButtonText
          :label="cancelLabel"
          theme="secondary"
          @click="cancel"
        />
      </div>
    </FormBase>
  </DialogBase>
</template>

<script>
import ButtonText from '@/components/Button/ButtonText.vue';
import FormBase from '@/components/Form/FormBase.vue';
import DialogBase from './DialogBase.vue';

export default {
  name: 'DialogForm',
  components: {
    FormBase,
    ButtonText,
    DialogBase,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    cancelLabel: {
      type: String,
      default: 'ID_CANCEL',
    },
    submitLabel: {
      type: String,
      default: 'ID_SAVE',
    },
    submitTheme: {
      type: String,
      default: 'primary',
    },
  },
  methods: {
    cancel() {
      this.$dialog.close(this.type);
      this.$emit('cancel');
    },
  },
};
</script>
