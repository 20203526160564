export const USER_SESSION_DISABLED = 0;
export const DEFAULT_USER_SESSIONS = 5000;
export const MIN_USER_SESSIONS = 100;
export const MAX_USER_SESSIONS = 10000;
export const SYSTEM_SESSION_DISABLED = 0;
export const HORA_DEFAULT_SYSTEM_SESSIONS = 25000;
export const HORA_MIN_SYSTEM_SESSIONS = 1000;
export const HORA_MAX_SYSTEM_SESSIONS = 50000;
export const MIRO_DEFAULT_SYSTEM_SESSIONS = 8000;
export const MIRO_MIN_SYSTEM_SESSIONS = 1000;
export const MIRO_MAX_SYSTEM_SESSIONS = 16000;
