
<router-link
  v-if="pathName"
  v-slot="{ href, navigate }"
  :to="{ name: pathName }"
  custom
>
  <a
    :href="href"
    :class="rootClassList"
    @click="beforeNavigating(), navigate($event)"
  >
    {{ $t(label) }}
  </a>
</router-link>
<a
  v-else-if="externalLink"
  :class="rootClassList"
  :href="externalLink"
  target="_blank"
  rel="external noopener noreferrer"
>
  {{ label ? $t(label) : externalLink }}
</a>
<span
  v-else-if="pureButton"
  :class="rootClassList"
  @click="$emit('click')"
>
  {{ label ? $t(label) : externalLink }}
</span>
<span v-else />
