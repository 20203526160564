import apiPath from './apiPath';
import { qFetch } from './utilities';

/**
 * Convert unit timestamp to epoch timestamp
 *
 * @param {string} unixTimestamp - Timestamp of unit format
 * @returns {string} Timestamp of epoch format
 */
function timestampUnixToEpoch(unixTimestamp) {
  const timestamp = parseInt(unixTimestamp, 10);

  if (Number.isNaN(timestamp)) {
    return 0;
  }

  return timestamp * 1000;
}

export default {
  async getVpnClient() {
    return qFetch.get(apiPath.VpnClient);
  },
  async postVpnClientOpenvpn(payload) {
    return qFetch.upload(apiPath.VpnClientOpenvpn, payload);
  },
  async postVpnClientOpenvpnCheck(payload) {
    return qFetch.upload(apiPath.VpnClientOpenvpnCheck, payload);
  },
  async deleteVpnClient(payload) {
    return qFetch.delete(apiPath.VpnClient, payload);
  },
  async patchVpnClient(payload) {
    return qFetch.patch(apiPath.VpnClient, payload);
  },
  /**
   * Get logs of connection
   *
   * @returns {Promise<Object[]>} Logs of connection
   */
  async getVpnClientConnectionLog() {
    const { result } = await qFetch.get(apiPath.VpnClientConnectionLog);

    return result
      .map((log) => ({
        id: log.id,
        action: log.action,
        timestamp: timestampUnixToEpoch(log.timestamp),
        protocol: log.protocol,
        publicIp: log.public_ip,
        vpnIp: log.vpn_ip,
        userName: log.user_name,
        country: log.country,
      }))
      .sort((log1, log2) => log2.timestamp - log1.timestamp);
  },
  /**
   * Delete all logs of connection
   *
   * @returns {Promise}
   */
  async deleteVpnClientConnectionLog() {
    await qFetch.delete(apiPath.VpnClientConnectionLog);
  },
};
