
<DialogBase
  type="QosPercentageChart"
  title="ID_QOS_SERVICE_CHART_TITLE"
  width="370"
  height="560"
>
  <ChartPie :dataset="dataset" />

  <ul class="ov-auto">
    <li
      v-for="(data, index) in dataset"
      :key="data.name"
      class="df ai-center fz-14"
      :class="{ 'mt-10-px': index !== 0 }"
    >
      <QosGroupIcon :color-index="index" />
      <span class="mr-10-px">{{ data.name }}</span>
      {{ data.value }} %
    </li>
  </ul>
</DialogBase>
