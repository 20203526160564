<template>
  <InputWrapper
    type="text"
    :value="value"
    :rules="rules"
    :size="size"
    :invalid-message="invalidMessage"
    :short="short"
    :disabled="disabled"
    class="por"
  >
    <template #default="{ inputClass }">
      <input
        v-bind="$attrs"
        v-model="innerValue"
        :placeholder="$t(placeholder)"
        :disabled="disabled"
        :class="[inputClass, { 'input-text--input-clearable': clearable }]"
        type="text"
        @keyup.enter="$emit('keyup', $event)"
      >
      <ButtonIcon
        v-if="clearable && innerValue"
        icon="ic_close_fill"
        class="input-text--clear-button"
        @click="innerValue = ''"
      />
    </template>
    <template #behind>
      <slot name="behind" />
    </template>
  </InputWrapper>
</template>

<script>
import ButtonIcon from '@/components/Button/ButtonIcon.vue';
import InputWrapper from './InputWrapper.vue';

export default {
  name: 'InputText',
  components: {
    InputWrapper,
    ButtonIcon,
  },
  inheritAttrs: false,
  props: {
    rules: {
      type: Object,
      default: null,
    },
    size: {
      type: String,
      default: 'sm',
    },
    invalidMessage: {
      type: String,
      default: null,
    },
    short: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.input-text--clear-button {
  position: absolute;
  top: 50%;
  right: rem(5px);
  transform: translateY(-50%);
}

.input-text--input-clearable {
  padding-right: rem(25px) !important;
}
</style>
