
<ModalConfirm
  id="qpkg_update_confirm"
  title="ID_QPKG_UPDATE_TITLE"
  force
>
  <div class="space-y-10-px">
    <TipMessage
      type="warning"
      text="ID_QPKG_UPDATE_DESC"
    />
    <TipMessage text="ID_QPKG_INCOMPATIBILITY_DESC" />
  </div>
  <template #modal-footer="{ cancel }">
    <ButtonText
      label="ID_QPKG_UPDATE_BTN"
      class="mr-10-px"
      @click="redirectAppCenter"
    />
    <ButtonText
      v-if="!forceUpdate"
      theme="secondary"
      label="ID_HORA_INIT_SKIP"
      @click="cancel"
    />
  </template>
</ModalConfirm>
