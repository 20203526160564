import { mergeDataWithKeys } from '@/common/utilities';

const storeState = {
  interfaceId: null,
  mode: '',
  originalWanConfig: null,
  editedConfig: {},
  wanConsts: {
    dataKeys: [
      'ip4Address',
      'ip4Gateway',
      'ip4Prefix',
      'ip4Type',
      'mtu',
      'password',
      'username',
      'ip4DnsServers',
      'lineRateRx',
      'lineRateTx',

      // IPv6
      'ip6PdEnabled',
      'ip6Address',
      'ip6DnsServers',
      'ip6Gateway',
      'ip6Prefix',
      'ip6Type',

      // advanced IPv6
      'dslite',
      'advIp6Region',
    ],
  },
};

const mutations = {
  startEditing(state, {
    interfaceId, mode, editedConfig, originalWanConfig,
  }) {
    state.interfaceId = interfaceId;
    state.mode = mode;
    state.originalWanConfig = originalWanConfig;
    state.editedConfig = editedConfig;
  },
  endEditing(state) {
    state.interfaceId = null;
    state.mode = '';
    state.originalWanConfig = null;
    state.editedConfig = {};
  },
  modifyEditedConfig(state, { key, value, target }) {
    target = target || state.editedConfig;
    target[key] = value;
  },
  modifyWan(state, { target, editedConfig }) {
    mergeDataWithKeys(state.wanConsts.dataKeys, editedConfig, target);
  },
};

export default {
  namespaced: true,
  state: storeState,
  mutations,
};
