import { toASCII, toUnicode } from 'punycode';
import apiPath from './apiPath';
import { qFetch } from './utilities';

/**
 * Convert the request payload of the parental control rule.
 * @param {Object} role - The parental control role
 * @returns {Object} The formatted request payload of the parental control rule
 */
function handleParentalControlRoleRequest(role) {
  return {
    filterMetadata: {
      safeSearch: role.filterMetadata.safeSearch,
      webFilter: toASCII(role.filterMetadata.webFilter),
    },
    name: role.name,
    ruleMask: role.ruleMask,
  };
}

export default {
  /**
   * Get the list of clients
   * @returns {Promise<Object[]>} List of clients
   */
  async getClientList() {
    return qFetch.get(apiPath.v2.ParentalControlClientList);
  },

  /**
   * Get the list of parental control roles
   * @returns {Promise<Object[]>} List of parental control roles
   */
  async getRoles() {
    const { result } = await qFetch.get(apiPath.v2.ParentalControlRoles);

    if (!result) {
      return [];
    }

    return result.map((role) => {
      const { filterMetadata = {} } = role;

      return {
        ...role,
        devices: role.devices ?? [],
        filterMetadata: {
          webFilter: filterMetadata.web_filter ? toUnicode(filterMetadata.web_filter) : '',
          safeSearch: filterMetadata.safe_search ?? '',
          schedule: filterMetadata.schedule ?? '',
        },
      };
    });
  },

  /**
   * Create a new role of parental control
   * @param {Object} role - New role for parental control
   * @returns {Promise<void>}
   */
  async createRole(role) {
    await qFetch.post(apiPath.v2.ParentalControlRole, {
      data: handleParentalControlRoleRequest(role),
    });
  },

  /**
   * Set a new status for the role of parental control
   * @param {number} roleId - ID of parental control role
   * @param {boolean} status - New status of parental control role
   * @returns {Promise<void>}
   */
  async setRoleStatus(roleId, status) {
    await qFetch.post(`${apiPath.v2.ParentalControlRoleAction}/${roleId}?status=${status}`);
  },

  /**
   * Update the role of parental control
   * @param {number} roleId - ID of parental control role
   * @param {Object} role - Role of parental control to be updated
   * @returns {Promise<void>}
   */
  async updateRole(roleId, role) {
    await qFetch.put(`${apiPath.v2.ParentalControlRole}/${roleId}`, {
      data: handleParentalControlRoleRequest(role),
    });
  },

  /**
   * Update the role of parental control with list of clients
   * @param {number} roleId - ID of parental control role
   * @param {Object[]} clients - List of clients
   * @returns {Promise<void>}
   */
  async updateRoleClients(roleId, clients) {
    const macAddressList = clients.map((client) => client.macAddr);

    await qFetch.patch(`${apiPath.v2.ParentalControlClientList}/${roleId}`, {
      data: {
        devicesList: macAddressList.join(','),
      },
    });
  },

  /**
   * Delete the parental control role
   * @param {number} roleId - ID of parental control role
   * @returns {Promise<void>}
   */
  async deleteRole(roleId) {
    return qFetch.delete(`${apiPath.v2.ParentalControlRole}/${roleId}`);
  },

  /**
   * Delete the client of the parent control role
   * @param {string} roleId - ID of the parent control role
   * @param {string} macAddress - Mac address of the client
   * @returns {Promise<void>}
   */
  async deleteRoleClient(roleId, macAddress) {
    const encodedMacAddress = btoa(macAddress);

    return qFetch.delete(`${apiPath.v2.getParentalControlClient(roleId, encodedMacAddress)}`);
  },
};
