
<InputWrapper
  type="text"
  :value="value"
  :rules="rules"
  :size="size"
  :invalid-message="invalidMessage"
  :short="short"
  :disabled="disabled"
  class="por"
>
  <template #default="{ inputClass }">
    <input
      v-bind="$attrs"
      v-model="innerValue"
      :placeholder="$t(placeholder)"
      :disabled="disabled"
      :class="[inputClass, { 'input-text--input-clearable': clearable }]"
      type="text"
      @keyup.enter="$emit('keyup', $event)"
    >
    <ButtonIcon
      v-if="clearable && innerValue"
      icon="ic_close_fill"
      class="input-text--clear-button"
      @click="innerValue = ''"
    />
  </template>
  <template #behind>
    <slot name="behind" />
  </template>
</InputWrapper>
