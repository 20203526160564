<template>
  <i
    class="dib w-10-px h-10-px border-radius-half mr-10-px"
    :style="{ backgroundColor: CHART_COLORS[colorIndex]}"
  />
</template>

<script>
import { CHART_COLORS } from '@/common/chart';

export default {
  name: 'QosGroupIcon',
  props: {
    colorIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      CHART_COLORS,
    };
  },
};
</script>
