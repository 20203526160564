import apiPath from './apiPath';
import { qFetch } from './utilities';

export default {
  async getControlhostQts() {
    return qFetch.get(apiPath.ControlhostQts);
  },
  async patchControlhostQts(payload) {
    return qFetch.patch(apiPath.ControlhostQts, payload);
  },
};
