
<ModalConfirm
  :id="`app-confirm-${key}`"
  :key="key"
  :message="message"
  :message-title="messageTitle"
  :type="type"
  :visible="visible"
  :confirm-label="confirmLabel"
  :cancel-label="cancelLabel"
  @confirm="confirm"
  @cancel="cancel"
/>
