import { System } from '@/services';
import WEBSOCKET_DASHBOARD from '@/enums/WebsocketDashboard';

const state = {
  cpuMetadata: {
    temperatureHigh: false,
    temperature: 0,
  },
  hwInfo: {
    bmac: '',
    serialNumber: '',
  },
  inputCountryCodeCount: 0,
  upTime: 0,
  uiSetting: {},

  // below data all from websocket
  uiBuzzerCheckBox: false,
  wsTemperatureHigh: false,
  currentTimestamp: 0,
  systemStatus: 0, // 0: normal, 1: abnormal, 2: missing node, -1: unable to get
  internetStatus: WEBSOCKET_DASHBOARD.NORMAL,
  miroStatus: 0, // 0: normal, 1: abnormal
};

const storeGetters = {
  isTemperatureTooHigh(state) {
    const temperatureHigh = state.cpuMetadata ? state.cpuMetadata.temperatureHigh : false;

    return temperatureHigh || state.wsTemperatureHigh;
  },
  isInternetConnected(state) {
    return state.internetStatus === WEBSOCKET_DASHBOARD.NORMAL;
  },
};

const mutations = {
  setHardwareInfo(state, payload) {
    state.hwInfo.bmac = payload.bmac;
    state.hwInfo.serialNumber = payload.serialNumber;
  },
  setHardwareStatus(state, payload) {
    state.cpuMetadata = payload.cpuMetadata;
    state.upTime = payload.upTime;
  },
  setCountryCodeSetting(state, payload) {
    state.inputCountryCodeCount = payload.settingCount;
  },
  setUiSetting(state, uiSetting) {
    state.uiSetting = JSON.parse(uiSetting);
  },
  updateCpuTempEvent(state, payload) {
    state.uiBuzzerCheckBox = payload.uiBuzzerCheckBox;
    state.wsTemperatureHigh = payload.wsTemperatureHigh;
  },
  updateDashboardEvent(state, payload) {
    state.currentTimestamp = payload.currentTimestamp;
    state.systemStatus = payload.systemStatus;
    state.internetStatus = payload.internetStatus;
    state.miroStatus = payload.miroStatus;
  },
};

const actions = {
  async getHardwareInfo({ commit }) {
    const res = await System.getHardwareInfo();

    commit('setHardwareInfo', res.result);
  },
  async getHardwareStatus({ commit }) {
    const res = await System.getHardwareStatus();

    commit('setHardwareStatus', res.result);
  },
  async getUiSetting({ commit }) {
    const res = await System.getUiSetting();

    commit('setUiSetting', res.result.uiSetting);
  },
  async patchGuideWanSetting() {
    await System.updateUiSetting('guide_wan_setting', true);
  },
  async putCountryCode({ commit }, payload) {
    try {
      await System.putCountryCode(payload);
    } catch (error) {
      if (error.result) {
        commit('setCountryCodeSetting', error.result);
      }

      throw error;
    }
  },
  async getCountryCodeStatus({ commit }) {
    const res = await System.getCountryCodeStatus();

    commit('setCountryCodeSetting', res.result);
  },
};

export default {
  namespaced: true,
  state,
  getters: storeGetters,
  mutations,
  actions,
};
