import { cloneDeep } from 'lodash';
import { toASCII, toUnicode } from 'punycode';
import apiPath from './apiPath';
import { qFetch } from './utilities';

export default {
  async getFirewall() {
    try {
      const { result } = await qFetch.get(apiPath.Firewall);

      result.data = result.data || [];

      result.data.forEach((rule) => {
        if (rule.dest_metadata) {
          rule.dest_metadata.domain_name = toUnicode(rule.dest_metadata.domain_name);
        }
      });

      return result;
    } catch (error) {
      throw new Error('Network Error!');
    }
  },
  async getFirewallInterfaces() {
    try {
      const rs = await qFetch.get(`${apiPath.Firewall}/interfaces`);

      return rs;
    } catch (error) {
      throw new Error('Network Error!');
    }
  },
  async putFirewallActivate(payload) {
    try {
      const rs = await qFetch.put(`${apiPath.Firewall}/${payload.activate}`);

      return rs;
    } catch (error) {
      throw new Error('Network Error!');
    }
  },
  async postFirewall(payload) {
    try {
      payload = cloneDeep(payload);

      payload.data.forEach((rule) => {
        if (rule.dest_metadata) {
          rule.dest_metadata.domain_name = toASCII(rule.dest_metadata.domain_name);
        }
      });

      await qFetch.post(apiPath.Firewall, payload);
    } catch (error) {
      throw new Error('Network Error!');
    }
  },
};
