/**
 * Format dateTime
 *
 * @param {number} timestamp  - Epoch timestamp
 * @returns {string} Formatted date
 */
export default (timestamp) => {
  if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
    return '';
  }

  if (timestamp === 0) {
    return '--';
  }

  const dateTime = new Date(timestamp);
  const month = (dateTime.getMonth() + 1).toString().padStart(2, '0');
  const date = dateTime.getDate().toString().padStart(2, '0');
  const hours = dateTime.getHours().toString().padStart(2, '0');
  const minutes = dateTime.getMinutes().toString().padStart(2, '0');

  return `${dateTime.getFullYear()}/${month}/${date} ${hours}:${minutes}`;
};
