import FTP_SERVER_ENABLED from '@/enums/FtpServerEnabled';
import apiPath from './apiPath';
import { qFetch } from './utilities';

export default {
  async getUsbInfo() {
    return qFetch.get(apiPath.UsbInfo);
  },
  async getFtpServer() {
    const { result } = await qFetch.get(apiPath.FtpServer);

    return {
      enabled: result.enabled === FTP_SERVER_ENABLED.ENABLED,
      concurrentConn: result.concurrent_conn,
      encoding: result.encoding,
      wanConnEnabled: result.wan_conn_enabled,
      localLink: result.local_link || [],
      remoteLink: result.remote_link || [],
    };
  },
  async putFtpServer(payload) {
    return qFetch.put(apiPath.FtpServer, {
      enabled: payload.enabled ? FTP_SERVER_ENABLED.ENABLED : FTP_SERVER_ENABLED.DISABLED,
      concurrent_conn: payload.concurrentConn,
      encoding: payload.encoding,
      wan_conn_enabled: payload.wanConnEnabled,
    });
  },
  async getFtpUser() {
    return qFetch.get(apiPath.FtpUser);
  },
  async putFtpUser(payload) {
    return qFetch.put(apiPath.FtpUser, payload);
  },
  async postFtpUser(payload) {
    return qFetch.post(apiPath.FtpUser, payload);
  },
  async deleteFtpUser(payload) {
    return qFetch.delete(apiPath.FtpUser, payload);
  },
  async putFtpDb(payload) {
    return qFetch.put(apiPath.FtpDb);
  },
};
