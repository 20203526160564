
<div
  class="dialog-mask"
  @click="$dialog.close(name)"
>
  <section
    ref="dialog"
    :style="style"
    role="dialog"
    aria-modal="true"
    class="dialog"
    @click.stop
  >
    <header class="df jc-sb mb-20-px">
      <div class="df ai-center pt-10-px">
        <i class="dif ic_master mr-10-px w-50-px h-50-px" />
        <h2 class="mr-10-px fz-20 mb-0-px fw-bold">
          {{ $t(title) }}
        </h2>
      </div>
      <button
        aria-label="Close"
        title="Close"
        type="button"
        class="btn-close"
        @click="$dialog.close(name)"
      >
        <img
          src="@/assets/icons/ic_dark_cross.svg"
          alt="Close"
          width="25"
          height="25"
          class="close-image"
        >
      </button>
    </header>

    <slot />
  </section>
</div>
