import { FaultyDeviceReplacement } from '@/services';
import { SET_RESTORE_DEVICE } from '@/store/mutation-types';

const state = {
  restoreDevice: null,
};

const mutations = {
  [SET_RESTORE_DEVICE](state, restoreDevice) {
    state.restoreDevice = restoreDevice;
  },
};

const actions = {
  async checkRestoreDevice({ commit }, file) {
    const restoreDevice = await FaultyDeviceReplacement.checkRestoreDevice(file);

    commit(SET_RESTORE_DEVICE, restoreDevice);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
