
<DialogHint
  name="VpnSettingsQbeltInstruction"
  :title="$t('ID_VPN_QBELT_CONNECTION_HELP', { model: $profile.name })"
>
  <div class="fz-14 mb-60-px pr-55-px">
    {{ $t("ID_VPN_QBELT_CONNECTION_HELP_MESSAGE") }}
  </div>
  <div class="df">
    <div class="df fd-column ai-center border-r-dark-gray pr-45-px pl-20-px ptb-10-px mr-40-px">
      <i class="dif ic_qvpn mb-10-px" />
      <span class="fw-bold">{{ $t("ID_QVPN") }}</span>
    </div>
    <div class="df pt-10-px">
      <div class="df fd-column mr-50-px">
        <div class="df ai-center mb-5-px">
          <i class="dif mr-5-px ic_computer" />
          <span class="fz-14">{{ $t("ID_VPN_QBELT_DESKTOP") }}</span>
        </div>
        <a
          href="https://www.qnap.com/en/utilities/networking"
          class="fz-14 btn-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ $t('ID_VPN_QBELT_DESKTOP_OS') }}
        </a>
      </div>
      <div class="df fd-column">
        <div class="df ai-center mb-5-px">
          <i class="dif mr-5-px ic_phone" />
          <span class="fz-14">{{ $t("ID_VPN_QBELT_MOBILE_APP") }}</span>
        </div>
        <a
          href="https://www.qnap.com/en/mobile-apps?category=networking"
          class="fz-14 btn-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ $t('ID_VPN_QBELT_MOBILE_APP_OS') }}
        </a>
      </div>
    </div>
  </div>
</DialogHint>
