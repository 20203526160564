import apiPath from './apiPath';
import { qFetch } from './utilities';

export default {
  async getUpnp() {
    return qFetch.get(apiPath.Upnp);
  },
  async putUpnp(payload) {
    return qFetch.put(apiPath.Upnp, payload);
  },
  async getUpnpService() {
    return qFetch.get(apiPath.UpnpService);
  },
  async getUpnpWan() {
    return qFetch.get(apiPath.UpnpWan);
  },
};
