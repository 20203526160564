<template>
  <DialogBase
    type="QosPercentageChart"
    title="ID_QOS_SERVICE_CHART_TITLE"
    width="370"
    height="560"
  >
    <ChartPie :dataset="dataset" />

    <ul class="ov-auto">
      <li
        v-for="(data, index) in dataset"
        :key="data.name"
        class="df ai-center fz-14"
        :class="{ 'mt-10-px': index !== 0 }"
      >
        <QosGroupIcon :color-index="index" />
        <span class="mr-10-px">{{ data.name }}</span>
        {{ data.value }} %
      </li>
    </ul>
  </DialogBase>
</template>

<script>
import { mapState } from 'vuex';
import ChartPie from '@/components/Chart/ChartPie.vue';
import DialogBase from '@/components/Dialog/DialogBase.vue';
import QosGroupIcon from './QosGroupIcon.vue';

export default {
  name: 'QosPercentageChartDialog',
  components: {
    ChartPie,
    DialogBase,
    QosGroupIcon,
  },
  computed: {
    ...mapState('Qos', ['priorityPercentages']),

    dataset() {
      return this.priorityPercentages.map((percentage, index) => {
        const groupNumber = index + 1;

        return {
          name: this.$t('ID_QOS_SERVICE_CLASS_NAME_LABEL', { number: groupNumber }),
          value: percentage,
        };
      });
    },
  },
};
</script>
