<template>
  <div
    class="dialog-mask"
    @click="clickOutside"
  >
    <section
      ref="dialog"
      :style="style"
      role="dialog"
      aria-modal="true"
      class="dialog-base"
      @click.stop
    >
      <header class="header">
        <h2 class="mr-10-px fz-20 mb-0-px">
          <slot name="title">
            {{ $t(title) }}
          </slot>
        </h2>
        <ButtonIcon
          icon="ic_close"
          label="ID_CLOSE"
          class="dialog-button-close"
          @click="close"
        />
      </header>

      <slot />
    </section>
  </div>
</template>

<script>
import dialogBase from '@/mixins/dialogBase';
import ButtonIcon from '@/components/Button/ButtonIcon.vue';

export default {
  name: 'DialogBase',
  components: {
    ButtonIcon,
  },
  mixins: [dialogBase],
  props: {
    type: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    // TODO: Handle auto width if needed
    width: {
      type: String,
      default: '500',
    },
    // TODO: Handle auto height if needed
    height: {
      type: String,
      default: '500',
    },
    autoHeight: {
      type: Boolean,
      default: false,
    },
    clickToClose: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      style: {
        width: `${this.width}px`,
        height: this.autoHeight ? 'auto' : `${this.height}px`,
        top: '0',
        left: '0',
      },
    };
  },
  mounted() {
    // Focus first focusable element
    this.$refs.dialog.querySelector('input')?.focus();
  },
  methods: {
    /**
     * Handle click outside of dialog
     */
    clickOutside() {
      if (this.clickToClose) {
        this.close();
      }
    },

    /**
     * Close dialog
     */
    close() {
      this.$dialog.close(this.type);
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-button-close {
  position: absolute;
  top: rem(5px);
  right: rem(5px);
}
</style>
