import { cloneDeep } from 'lodash';
import { Route } from '@/services';
import { mergeDataWithKeys } from '@/common/utilities';

function createDefaultIp4StaticRoute() {
  return {
    description: '',
    enabled: true,
    destination: '',
    prefix: 24,
    metric: 10,
    nextHopAddr: '',
    nextHopPortName: '',
    nextHopLan: '',
    shared: false,
  };
}
function createDefaultIp6StaticRoute() {
  return {
    description: '',
    enabled: true,
    destination: '',
    prefix: 64,
    metric: 10,
    nextHopAddr: '',
    nextHopPortName: '',
    nextHopLan: '',
    shared: false,
  };
}

// those consts only use in this file
const EDITED_TYPE_IPV4 = Symbol('static-route-ipv4');
const EDITED_TYPE_IPV6 = Symbol('static-route-ipv6');

const storeState = {
  ip4Routes: [],
  editedIp4Routes: [],
  ip6Routes: [],
  editedIp6Routes: [],
  editedRouteId: null,
  editedType: null,
  addedRoute: null,
  routeConsts: {
    dataKeysIpv4: [
      'description',
      'enabled',
      'destination',
      'prefix',
      'metric',
      'nextHopAddr',
      'nextHopPortName',
      'shared',
      'routeId',
      'type',
      'nextHopLan',
    ],
    dataKeysIpv6: [
      'description',
      'enabled',
      'destination',
      'prefix',
      'metric',
      'nextHopAddr',
      'nextHopPortName',
      'shared',
      'routeId',
      'type',
      'nextHopLan',
    ],
  },
};

const storeGetters = {
  isAddMode(state) {
    return state.addedRoute !== null;
  },
  editedRouteIpv4(state, getters) {
    if (state.editedType === EDITED_TYPE_IPV4) {
      const editedStaticRoute = getters.isAddMode
        ? state.addedRoute
        : getters.editedIp4StaticRoutes.find((route) => route.routeId === state.editedRouteId);

      return editedStaticRoute || null;
    }

    return null;
  },
  editedOriginalRouteIpv4(state, getters) {
    if (state.editedType === EDITED_TYPE_IPV4) {
      const originalStaticRoute = getters.isAddMode
        ? createDefaultIp4StaticRoute()
        : getters.ip4StaticRoutes.find((route) => route.routeId === state.editedRouteId);

      return originalStaticRoute || null;
    }

    return null;
  },
  editedRouteIpv6(state, getters) {
    if (state.editedType === EDITED_TYPE_IPV6) {
      const originalStaticRoute = getters.isAddMode
        ? state.addedRoute
        : getters.editedIp6StaticRoutes.find((route) => route.routeId === state.editedRouteId);

      return originalStaticRoute || null;
    }

    return null;
  },
  editedOriginalRouteIpv6(state, getters) {
    if (state.editedType === EDITED_TYPE_IPV6) {
      const editedStaticRoute = getters.isAddMode
        ? createDefaultIp6StaticRoute()
        : getters.ip6StaticRoutes.find((route) => route.routeId === state.editedRouteId);

      return editedStaticRoute || null;
    }

    return null;
  },
  ip4StaticRoutes(state) {
    return state.ip4Routes.filter((item) => item.type === 'manual');
  },
  editedIp4StaticRoutes(state) {
    return state.editedIp4Routes.filter((item) => item.type === 'manual');
  },
  ip6StaticRoutes(state) {
    return state.ip6Routes.filter((item) => item.type === 'manual');
  },
  editedIp6StaticRoutes(state) {
    return state.editedIp6Routes.filter((item) => item.type === 'manual');
  },
};

const mutations = {
  initRoutes(state, { ip4Routes, ip6Routes }) {
    state.editedIp4Routes = cloneDeep(ip4Routes);
    state.editedIp6Routes = cloneDeep(ip6Routes);
    state.ip4Routes = ip4Routes;
    state.ip6Routes = ip6Routes;
  },
  resetEditedIp4Routes(state) {
    state.editedIp4Routes = cloneDeep(state.ip4Routes);
  },
  resetEditedIp6Routes(state) {
    state.editedIp6Routes = cloneDeep(state.ip6Routes);
  },
  startEditingIpv4(state, routeId) {
    state.editedRouteId = routeId;
    state.editedType = EDITED_TYPE_IPV4;
  },
  startEditingIpv6(state, routeId) {
    state.editedRouteId = routeId;
    state.editedType = EDITED_TYPE_IPV6;
  },
  endEditing(state) {
    state.editedRouteId = null;
    state.editedType = null;
  },
  startAddingIpv4(state) {
    state.addedRoute = createDefaultIp4StaticRoute();
    state.editedType = EDITED_TYPE_IPV4;
  },
  startAddingIpv6(state) {
    state.addedRoute = createDefaultIp6StaticRoute();
    state.editedType = EDITED_TYPE_IPV6;
  },
  endAdding(state) {
    state.editedType = null;
    state.addedRoute = null;
  },
  modifyRouteIpv4(state, { target, editedConfig }) {
    mergeDataWithKeys(state.routeConsts.dataKeysIpv4, editedConfig, target);
  },
  modifyRouteIpv6(state, { target, editedConfig }) {
    mergeDataWithKeys(state.routeConsts.dataKeysIpv6, editedConfig, target);
  },
  modifyAddedRoute(state, editedConfig) {
    if (state.editedType === EDITED_TYPE_IPV4) {
      mergeDataWithKeys(state.routeConsts.dataKeysIpv4, editedConfig, state.addedRoute);
    } else if (state.editedType === EDITED_TYPE_IPV6) {
      mergeDataWithKeys(state.routeConsts.dataKeysIpv6, editedConfig, state.addedRoute);
    }
  },
};

const actions = {
  async getRoute({ commit }) {
    const routes = await Route.getRoutes();

    commit('initRoutes', routes);
  },
  /**
   * add or edit static route
   * @param {Object} context - store context
   */
  async sendEditedRoute({ state, dispatch, getters }) {
    if (getters.isAddMode) {
      if (state.editedType === EDITED_TYPE_IPV4) {
        await dispatch('postRouteIpv4', state.addedRoute);
      } else if (state.editedType === EDITED_TYPE_IPV6) {
        await dispatch('postRouteIpv6', state.addedRoute);
      }
    } else if (state.editedType === EDITED_TYPE_IPV4) {
      const editedRoute = getters.editedRouteIpv4;

      await dispatch('putRouteIpv4', editedRoute);
    } else if (state.editedType === EDITED_TYPE_IPV6) {
      const editedRoute = getters.editedRouteIpv6;

      await dispatch('putRouteIpv6', editedRoute);
    }
  },
  async putRouteIpv4({ state }, routeRule) {
    const rule = cloneDeep(routeRule);

    delete rule.routeId;

    await Route.putRouteIpv4(routeRule.routeId, rule);
  },
  async putRouteIpv6({ state }, routeRule) {
    const rule = cloneDeep(routeRule);

    delete rule.routeId;

    await Route.putRouteIpv6(routeRule.routeId, rule);
  },
  async postRouteIpv4({ state }, routeRule) {
    await Route.postRouteIpv4(routeRule);
  },
  async postRouteIpv6({ state }, routeRule) {
    await Route.postRouteIpv6(routeRule);
  },
  applyEditing({ getters, commit, state }, editedConfig) {
    if (getters.isAddMode) {
      commit('modifyAddedRoute', editedConfig);
    } else if (state.editedType === EDITED_TYPE_IPV4) {
      const target = getters.editedRouteIpv4;

      commit('modifyRouteIpv4', { target, editedConfig });
    } else if (state.editedType === EDITED_TYPE_IPV6) {
      const target = getters.editedRouteIpv6;

      commit('modifyRouteIpv6', { target, editedConfig });
    }
  },
};

export default {
  namespaced: true,
  state: storeState,
  getters: storeGetters,
  mutations,
  actions,
};
