<template>
  <transition
    name="fade"
    appear
  >
    <div
      v-if="visible"
      id="app-toast"
      :key="key"
      class="q-toast-wrapper"
    >
      <div class="q-toast-container">
        <div class="q-toast-content">
          {{ $t(text) }}
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ToastBase',
  computed: {
    ...mapState('Plugin/Toast', ['visible', 'key', 'text']),
  },
};
</script>

<style lang="scss" scoped>
.q-toast-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.q-toast-container {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: rem(960px);
  margin: rem(20px);
}

.q-toast-content {
  display: flex;
  justify-content: center;
  min-width: rem(200px);
  max-width: 100%;
  padding: rem(15px 35px);
  color: loading-color('text');
  pointer-events: auto;
  background-color: loading-color('background');
  border-radius: rem(5px);
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
.fade-enter-active {
  transition: 0.2s ease;
}
.fade-leave-active {
  transition: 0.7s ease;
}
.fade-enter-to, .fade-leave {
  opacity: 1;
}
</style>
