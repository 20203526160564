<template>
  <router-link
    v-if="pathName"
    v-slot="{ href, navigate }"
    :to="{ name: pathName }"
    custom
  >
    <a
      :href="href"
      :class="rootClassList"
      @click="beforeNavigating(), navigate($event)"
    >
      {{ $t(label) }}
    </a>
  </router-link>
  <a
    v-else-if="externalLink"
    :class="rootClassList"
    :href="externalLink"
    target="_blank"
    rel="external noopener noreferrer"
  >
    {{ label ? $t(label) : externalLink }}
  </a>
  <span
    v-else-if="pureButton"
    :class="rootClassList"
    @click="$emit('click')"
  >
    {{ label ? $t(label) : externalLink }}
  </span>
  <span v-else />
</template>

<script>
export default {
  name: 'ButtonLink',
  props: {
    label: {
      type: String,
      default: '',
    },
    pathName: {
      type: String,
      default: '',
    },
    externalLink: {
      type: String,
      default: '',
    },
    small: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    behind: {
      type: Boolean,
      default: false,
    },
    tabId: {
      type: String,
      default: '',
    },
    tableFilter: {
      type: String,
      default: '',
    },
    pureButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    rootClassList() {
      return {
        'button-link': true,
        'button-link-behind': this.behind,
        'button-link-disabled': this.disabled,
        'fz-14': this.small,
      };
    },
  },
  methods: {
    beforeNavigating() {
      this.$emit('before-navigate');

      if (this.tabId) {
        this.$store.commit('App/setRedirectTabId', this.tabId);
      }

      if (this.tableFilter) {
        this.$store.commit('App/setRedirectTableFilter', this.tableFilter);
      }
    },
  },
};
</script>
