import PROFILE from '@/common/profile';
import MODEL from '@/enums/Model';
import OPERATIONMODE from '@/enums/OperationMode';

const state = {
  modelName: '',
  isQpkg: false,
};

const storeGetters = {
  /* model based properties */
  isMiroPlus(state) {
    return state.modelName === MODEL.QMIROPLUS;
  },
  isMiro(state) {
    return state.modelName === MODEL.QMIRO;
  },
  isMiroSeries(state) {
    return state.modelName === MODEL.QMIRO || state.modelName === MODEL.QMIROPLUS;
  },
  isHora(state) {
    return state.modelName === MODEL.QHORA;
  },
  isHora321(state) {
    return state.modelName === MODEL.QHORA321;
  },
  isHora322(state) {
    return state.modelName === MODEL.QHORA322;
  },
  isHoraSeries(state) {
    return [MODEL.QHORA, MODEL.QHORA321, MODEL.QHORA322].includes(state.modelName);
  },
  isApMode(state, getters, rootState) {
    return rootState.OperationMode.apModeSetting.remote_mode === OPERATIONMODE.AP;
  },
  isWebContentFilter(state) {
    return [MODEL.QHORA321, MODEL.QHORA322].includes(state.modelName);
  },

  /* feature based properties */
  currentFeatures(state) {
    return PROFILE[state.modelName] || {};
  },
  supportWireless(state, getters) {
    return getters.currentFeatures.wireless;
  },
  supportApMode(state, getters) {
    return getters.currentFeatures.apMode;
  },
  supportUpnp(state, getters) {
    return getters.currentFeatures.upnp;
  },
  supportIpv6(state, getters) {
    return getters.currentFeatures.ipv6;
  },
  supportUsb(state, getters) {
    return getters.currentFeatures.usb;
  },
  supportNetworkV3(state, getters) {
    return getters.currentFeatures.networkv3;
  },
  supportIptv(state, getters) {
    return getters.currentFeatures.iptv;
  },
  supportMesh(state, getters) {
    return getters.currentFeatures.mesh;
  },
  supportFirewall(state, getters) {
    return getters.currentFeatures.firewall;
  },
  supportCustomPort(state, getters) {
    return getters.currentFeatures.customPort;
  },
  supportAirGap(state) {
    return [MODEL.QHORA321, MODEL.QHORA322].includes(state.modelName);
  },
  supportDpiModule(state, getters) {
    return getters.isHoraSeries;
  },
  supportPhyLink(state) {
    return [MODEL.QHORA321, MODEL.QHORA322].includes(state.modelName);
  },
  supportMtls(state) {
    return [MODEL.QHORA321, MODEL.QHORA322].includes(state.modelName);
  },

  /* other */
  downloadCenterLink(state) {
    const baseUrl = 'https://www.qnap.com/download';

    switch (state.modelName) {
      case MODEL.QHORA:
        return `${baseUrl}?model=qhora-301w&category=firmware`;
      case MODEL.QMIRO:
        return `${baseUrl}?model=qmiro-201w&category=firmware`;
      case MODEL.QMIROPLUS:
        return `${baseUrl}?model=qmiroplus-201w&category=firmware`;
      default:
        return baseUrl;
    }
  },
};

const mutations = {
  setProfile(state, profile) {
    state.modelName = profile.modelName;
    state.isQpkg = typeof profile.isQpkg === 'boolean' ? profile.isQpkg : false;
  },
};

export default {
  namespaced: true,
  state,
  getters: storeGetters,
  mutations,
};
