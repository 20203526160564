import { CLEAR_REDIRECT_TAB_ID } from '@/store/mutation-types';

const storeState = {
  redirectTabId: '',
  redirectTableFilter: '',
};

const mutations = {
  setRedirectTabId(state, value) {
    state.redirectTabId = value;
  },
  [CLEAR_REDIRECT_TAB_ID](state) {
    state.redirectTabId = '';
  },
  setRedirectTableFilter(state, value) {
    state.redirectTableFilter = value;
  },
  clearRedirectTableFilter(state) {
    state.redirectTableFilter = '';
  },
};

export default {
  namespaced: true,
  state: storeState,
  mutations,
};
