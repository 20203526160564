import SERVICES from '@/services';

const state = {
  ddnsInfo: {},
  ddnsWanStatus: false,
};

const actions = {
  async getDdnsInfo({ commit }) {
    const data = await SERVICES.DDNS.getDdnsInfo();

    commit('setDdnsInfo', data.result);
  },
  async putDdns({ commit }, payload) {
    await SERVICES.DDNS.putDdns(payload);
  },
  async updateDdnsInfo({ commit }) {
    const data = await SERVICES.DDNS.updateDdnsInfo();

    commit('setDdnsInfo', data.result);
  },
  async getDdnsWan({ commit }) {
    const data = await SERVICES.DDNS.getDdnsWan();

    commit('setDdnsWanStatus', data.result);
  },
};

const mutations = {
  setDdnsInfo(state, info) {
    state.ddnsInfo = info;
  },
  setDdnsWanStatus(state, status) {
    state.ddnsWanStatus = status;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
