import Vue from 'vue';
import { clearTokenStartingUsingTime } from '@/common/apiTokenMonitor';
import {
  clearAllowedIdleTime,
  clearStartingIdleTime,
} from '@/common/idleTimeMonitor';
import { clearAuthorization } from './utilities';

export const EVENT_BUS = new Vue();
export const INITIALIZATION_ACCOUNT = {
  USERNAME: 'Hora',
  PASSWORD: 'This is for hora init',
};

/**
 * Handle the format of time stored in the local storage for GUI
 * @param {string} time - Time stored in local storage
 * @returns {number} Formatted time for GUI
 */
export function handleStoredTime(time) {
  if (!time) {
    return 0;
  }

  const convertedTime = Number(time);

  if (Number.isNaN(convertedTime) || convertedTime < 0) {
    return 0;
  }

  return convertedTime;
}

/**
 * Clear the environment of the system
 * @returns {void}
 */
export function clearSystemEnvironment() {
  clearAllowedIdleTime();
  clearStartingIdleTime();
  clearTokenStartingUsingTime();
  clearAuthorization();
}
