<template>
  <ValidationProvider
    ref="provider"
    v-slot="{ validated, valid, errors }"
    :rules="rules"
    :disabled="disabled"
    tag="div"
    :immediate="immediate"
  >
    <slot
      :validated="validated"
      :valid="valid"
    />
    <TipInputMessage
      v-if="validated && !valid && invalidMessage"
      :message="invalidMessage"
    />
    <TipInputMessage
      v-else-if="validated && !valid && errors.length !== 0 && errors[0]"
      :message="errors[0]"
    />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import TipInputMessage from '@/components/Tip/TipInputMessage.vue';

export default {
  name: 'InputValidationBase',
  components: {
    ValidationProvider,
    TipInputMessage,
  },
  props: {
    /**
     * This prop is only for read.
     * Provider will validate every time the value is changed
     */
    value: {},

    rules: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },

    // Custom error message defined from components
    invalidMessage: {
      type: String,
      default: '',
    },

    immediate: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value() {
      const { provider } = this.$refs;

      if (provider && provider.flags.validated) {
        this.$nextTick(() => {
          // if use "immediate", $refs.provider may is undefined before mounted
          this.$refs.provider?.validate();
        });
      }
    },
  },
};
</script>
