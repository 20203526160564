import apiPath from '../apiPath';
import { qFetch } from '../utilities';

export default {
  /**
   * Get config of WireGuard server
   * @returns {Promise<Object>}
   */
  async getWireguardConfig() {
    return qFetch.get(apiPath.v2.WireguardServer);
  },
  /**
   * Update config of WireGuard server
   * @param {Object} config - server config
   * @returns {Promise<Object>}
   */
  async updateWireguardConfig(config) {
    return qFetch.put(apiPath.v2.WireguardServer, { data: config });
  },
  /**
   * Generate WireGuard private/public key pair
   * @param {string} privKey - private key
   * @returns {Promise<Object>}
   */
  async generateWireguardKey(privKey) {
    return qFetch.post(apiPath.v2.WireguardKey, { data: { privKey } });
  },
  /**
   * Get list of WireGuard users
   * @returns {Promise<Object>}
   */
  async getWireguardUsers() {
    return qFetch.get(apiPath.v2.WireguardUser);
  },
  /**
   * Create a WireGuard user
   * @param {Object} setting - user setting
   * @returns {Promise<Object>}
   */
  async createWireguardUser(setting) {
    return qFetch.post(apiPath.v2.WireguardUser, { data: setting });
  },
  /**
   * Delete a WireGuard user
   * @param {number} index - user id
   * @returns {Promise<Object>}
   */
  async deleteWireguardUser(index) {
    return qFetch.delete(`${apiPath.v2.WireguardUser}/${index}`);
  },
  /**
   * Update a WireGuard user
   * @param {Object} payload
   * @param {number} payload.index - user id
   * @param {Object} payload.setting - user setting
   * @returns {Promise<Object>}
   */
  async updateWireguardUser(payload) {
    return qFetch.patch(`${apiPath.v2.WireguardUser}/${payload.index}`, { data: payload.setting });
  },
  /**
   * Download WireGuard user profile
   * @param {Object} payload
   * @param {number} payload.index - user id
   * @param {string} payload.wanInterfaceID - selected wan interface
   * @param {string} payload.fileName - file name to download
   * @returns {Promise}
   */
  async exportWireguardUser(payload) {
    const path = `${apiPath.v2.WireguardUserExport}/${payload.index}?wanInterfaceID=${payload.wanInterfaceID}`;

    await qFetch.download(path, payload.fileName);
  },
};
