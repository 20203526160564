import { getQunmsInterfaceIpConflictMessage } from '@/services/utilities/errorHandler';
import store from '@/store';
import { CUSTOM_ERROR_TYPE, MODAL_NOTIFY_ID } from '@/common/consts';

/**
 * Plugin for handing the customize errors
 */
export default {
  install(Vue) {
    Vue.prototype.$catch = function (error) {
      let message = error?.message || '';

      switch (error?.name) {
        case CUSTOM_ERROR_TYPE.QUNMS_INTERFACE_IP_CONFLICT:
          if (error?.conflictConfigs) {
            message = error.conflictConfigs
              .map((device) => getQunmsInterfaceIpConflictMessage(device));
          }

          store.commit('Quwan/updateErrorMessages', message);
          Vue.prototype.$modal.show(MODAL_NOTIFY_ID.NOTIFY_QUNMS_NETWORK_CONFLICT_ID);
          break;
        case CUSTOM_ERROR_TYPE.QUNMS_DEPLOYMENT:
          store.commit('Quwan/updateErrorMessages', message);
          Vue.prototype.$modal.show(MODAL_NOTIFY_ID.NOTIFY_QUWAN_DEPLOYMENT_ERROR_ID);
          break;
        default:
          Vue.prototype.$notify(error?.type, message);
      }
    };
  },
};
