<template>
  <div
    id="loading-spinner"
    :style="style"
  >
    <div id="yellow" />
    <div id="green" />
    <div id="cyan" />
    <div id="white" />
  </div>
</template>

<script>
export default {
  name: 'LoadingSpinner',
  props: {
    width: {
      type: Number,
      default: 30,
    },
    height: {
      type: Number,
      default: 30,
    },
  },
  computed: {
    style() {
      return {
        width: `${this.width}px`,
        height: `${this.height}px`,
      };
    },
  },
};
</script>
