import { cloneDeep } from 'lodash';
import { Network } from '@/services';
import { handleWanRequest, handleWanResponse } from '@/services/utilities/wan';
import { mergeDataWithKeys } from '@/common/utilities';
import NETWORK_INTERFACE from '@/enums/NetworkInterface';
import PORT_RATE from '@/enums/PortRate';
import WAN_ADV_IP6_REGION from '@/enums/WanAdvIp6Region';
import WAN_IP4_TYPE from '@/enums/WanIp4Type';
import WAN_IP6_TYPE from '@/enums/WanIp6Type';
import { i18n } from '@/lang';

const IP4_TYPE_TEXT_MAPPING = {
  dhcp: 'ID_WAN_DYNAMIC_IP',
  static: 'ID_WAN_STATIC_IP',
  pppoe: 'ID_WAN_PPPOE',
  dslite: 'ID_IPV6_ADVANCED_DSLITE',
  mape: 'ID_IPV6_ADVANCED_MAPE',
};

const IP6_TYPE_TEXT_MAPPING = {
  dhcp: 'ID_IPV6_TYPE_DHCPV6',
  static: 'ID_IPV6_TYPE_STATIC',
};

const storeState = {
  wans: [],
  editedWans: [],
  hasEditedWanPortNames: [],
  wansStatus: [],
  editedConfig: {},
  addedWan: null,
  wanPortConsts: {
    dataKeys: [
      'enabled',
      'label',
      'name',

      'description',
      'ip4Address',
      'ip4Gateway',
      'ip4Prefix',
      'ip4Type',
      'mtu',
      'password',
      'portName',
      'username',
      'ip4DnsServers',
      'lineRateRx',
      'lineRateTx',
      'speed',

      // IPv6
      'ip6PdEnabled',
      'ip6Address',
      'ip6DnsServers',
      'ip6Gateway',
      'ip6Prefix',
      'ip6Type',

      // advanced IPv6
      'dslite',
      'advIp6Region',
    ],
  },
};

const storeGetters = {
  createDefaultWan: (state, getters, rootState, rootGetters) => (portName) => {
    const { lineRateRx, lineRateTx } = rootGetters['Network/Ports/getNetworkProfilePortDefaultValues'](portName);

    return {
      enabled: true,
      dialOnDemand: false,
      name: `WAN${portName}`,
      label: '',

      description: '',
      ip4Address: '',
      ip4Gateway: '',
      ip4Prefix: 24,
      ip4Type: WAN_IP4_TYPE.DHCP,
      mtu: 1500,
      password: '',
      portName,
      username: '',
      ip4AutoDnsServers: true,
      ip4DnsServers: [],
      lineRateRx,
      lineRateTx,

      ip6PdEnabled: false,
      ip6Address: '',
      ip6DnsServers: [],
      ip6Gateway: '',
      ip6Prefix: 64,
      ip6Type: WAN_IP6_TYPE.DISABLED,

      advIp6Region: WAN_ADV_IP6_REGION.GLOBAL,
      dslite: {
        aftrAddress: '',
        b4Address: '192.0.0.2',
      },
      speed: PORT_RATE.AUTO,
    };
  },

  getIp4TypeText: () => (ip4Type) => {
    const messageId = IP4_TYPE_TEXT_MAPPING[ip4Type];

    return i18n.t(messageId);
  },
  getIp6TypeText: () => (ip6Type) => {
    const messageId = IP6_TYPE_TEXT_MAPPING[ip6Type];

    return i18n.t(messageId);
  },
  getWanPortText: (state, getters, rootState, rootGetters) => (portName) => {
    const portItem = rootGetters['Network/Ports/findNetworkProfilePortItem'](portName);
    const textId = portItem.portRate === '10g' ? 'ID_SR_WAN_10G_PORT_N' : 'ID_SR_WAN_PORT_N';

    return i18n.t(textId, [portItem.portRateIndex + 1]);
  },
  findWanStatus: (state) => (portName) => {
    const wanStatus = state.wansStatus.find((status) => status.portName === portName);

    // status will not exist if WAN is disabled
    return wanStatus ?? {
      ip4Address: '',
      ip6Address: '',
      status: 'down',
      throughputRx: 0,
      throughputTx: 0,
      throughputPktRx: 0,
      throughputPktTx: 0,
    };
  },
  editedWan(state) {
    const editedWan = state.editedWans.find((wan) => wan.portName === state.editedConfig.portName);

    return editedWan ?? state.addedWan;
  },
  editedOriginalWan(state, getters) {
    if (state.hasEditedWanPortNames.includes(state.editedConfig.portName)) {
      return getters.editedWan;
    }

    return state.wans.find((wan) => wan.portName === state.editedConfig.portName)
      || getters.createDefaultWan(state.editedConfig.portName);
  },
  enabledWans(state) {
    return state.wans.filter((item) => item.enabled);
  },
  enabledWansStatus(state) {
    return state.wansStatus.filter((item) => state.wans
      .find((port) => port.portName === item.portName)?.enabled);
  },
  usingAdvancedIpv6(state, getters) {
    const advanceIpv6Ip4Types = [WAN_IP4_TYPE.DSLITE, WAN_IP4_TYPE.MAPE];

    return getters.enabledWansStatus.some((wan) => advanceIpv6Ip4Types.includes(wan.ip4Type));
  },
};

const mutations = {
  initWans(state, wans) {
    wans = wans.map((wan) => handleWanResponse(wan));
    wans.sort((item1, item2) => parseInt(item1.portName, 10) - parseInt(item2.portName, 10));
    state.editedWans = cloneDeep(wans);
    state.wans = wans;
    state.hasEditedWanPortNames = [];
  },
  resetEditedWans(state) {
    state.editedWans = cloneDeep(state.wans);
  },
  setWansStatus(state, payload) {
    state.wansStatus = payload
      .sort((item1, item2) => parseInt(item1.portName, 10) - parseInt(item2.portName, 10));
  },
  resetEditedState(state) {
    state.editedConfig = {};
    state.addedWan = null;
  },
  modifyEditedConfig(state, { key, value, target }) {
    target = target || state.editedConfig;
    target[key] = value;
  },
  modifyWan(state, { target, editedConfig }) {
    mergeDataWithKeys(state.wanPortConsts.dataKeys, editedConfig, target);
  },
  checkAddedWan(state) {
    if (state.addedWan) {
      const idx = state.editedWans.findIndex((wan) => wan.portName === state.addedWan.portName);

      if (idx > -1) {
        state.editedWans.splice(idx, 1);
      }
      state.editedWans.push(state.addedWan);
    }
  },
  checkRemoveWan(state, portName) {
    const idx = state.editedWans.findIndex((port) => port.portName === portName);

    if (idx > -1) {
      state.editedWans.splice(idx, 1);
    }
  },
  toggleWanEnabled(state, portName) {
    const wan = state.editedWans.find((port) => port.portName === portName);

    if (wan) {
      wan.enabled = !wan.enabled;
    }
  },
  markWanHasEdited(state, portName) {
    if (!state.hasEditedWanPortNames.includes(portName)) {
      state.hasEditedWanPortNames.push(portName);
    }
  },
  setEditedState(state, { addedWan, editedConfig }) {
    state.addedWan = addedWan;
    state.editedConfig = editedConfig;
  },
};

const actions = {
  startEditing({
    state, commit, getters, dispatch,
  }, portName) {
    dispatch('initEditedState', portName);
    commit('Network/WanConfig/startEditing', {
      interfaceId: portName,
      mode: NETWORK_INTERFACE.WAN,
      editedConfig: state.editedConfig,
      originalWanConfig: getters.editedOriginalWan,
    }, { root: true });
  },
  endEditing({ commit }) {
    commit('resetEditedState');
    commit('Network/WanConfig/endEditing', undefined, { root: true });
  },
  initEditedState({ state, getters, commit }, portName) {
    let editedWan = state.editedWans.find((wan) => wan.portName === portName);
    let addedWan = null;

    if (!editedWan) {
      editedWan = getters.createDefaultWan(portName);
      addedWan = editedWan;
    }
    const editedConfig = mergeDataWithKeys(state.wanPortConsts.dataKeys, editedWan, {});

    commit('setEditedState', { addedWan, editedConfig });
  },

  applyEditing({ getters, commit }, editedConfig) {
    const target = getters.editedWan;

    commit('checkAddedWan');
    commit('modifyWan', { target, editedConfig });
  },
  applyEditingAndPutLater({ commit, dispatch }, editedConfig) {
    dispatch('applyEditing', editedConfig);
    commit('markWanHasEdited', editedConfig.portName);
  },

  async putEditedWan({ getters, dispatch }) {
    await dispatch('putWan', getters.editedWan);
  },
  async putWan({ dispatch }, wanConfig) {
    const wan = handleWanRequest(wanConfig);

    await Network.putWan(wan.portName, wan);
    await Promise.all([
      dispatch('Network/Ports/getPorts', null, { root: true }),
      dispatch('Network/Ports/getPortsStatus', null, { root: true }),
    ]);
  },
};

export default {
  namespaced: true,
  state: storeState,
  getters: storeGetters,
  mutations,
  actions,
};
