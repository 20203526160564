<template>
  <ModalConfirm
    :id="`app-confirm-${key}`"
    :key="key"
    :message="message"
    :message-title="messageTitle"
    :type="type"
    :visible="visible"
    :confirm-label="confirmLabel"
    :cancel-label="cancelLabel"
    @confirm="confirm"
    @cancel="cancel"
  />
</template>

<script>
import { mapState } from 'vuex';
import ModalConfirm from '@/components/Modal/ModalConfirm.vue';

export default {
  name: 'AppConfirm',
  components: {
    ModalConfirm,
  },
  computed: {
    ...mapState('Plugin/Confirm', [
      'key',
      'message',
      'messageTitle',
      'type',
      'visible',
      'confirmLabel',
      'cancelLabel',
      'confirm',
      'cancel',
    ]),
  },
};
</script>
