import { System } from '@/services';
import { mergeDataWithKeys } from '../../common/utilities';

const state = {
  apModeSetting: {
    remote_mode: 'router',
    mode: 'router',
    networkInfo: {
      ip4Address: '',
      ip4Gateway: '',
      ip4Type: 'DHCP',
      ip4DnsServers: [],
      ip4Prefix: 24,
      enableStp: 0,
    },
  },
};

const actions = {
  async putOperationSetting({ commit, state }) {
    const payload = {
      mode: state.apModeSetting.mode,
      networkInfo: {
        enableStp: Boolean(state.apModeSetting.networkInfo.enableStp),
        ip4Type: state.apModeSetting.networkInfo.ip4Type,
      },
    };

    if (payload.networkInfo.ip4Type === 'STATIC') {
      const keys = ['ip4Address', 'ip4Gateway', 'ip4Prefix', 'ip4DnsServers'];

      mergeDataWithKeys(keys, state.apModeSetting.networkInfo, payload.networkInfo);
    }
    await System.putOperationSetting(payload);
  },
  async getOperationSetting({ commit }) {
    const res = await System.getOperationSetting();

    commit('setOperationSetting', res.result);
  },
};

const mutations = {
  setNetworkConfig(state, payload) {
    Object.keys(payload).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(payload, key)) {
        state.apModeSetting.networkInfo[key] = payload[key];
      }
    });
  },
  setModeConfig(state, payload) {
    Object.keys(payload).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(payload, key)) {
        state.apModeSetting[key] = payload[key];
      }
    });
  },
  setOperationSetting(state, payload) {
    if (payload) {
      state.apModeSetting.mode = payload.mode;
      state.apModeSetting.remote_mode = payload.mode;
      state.apModeSetting.networkInfo = payload.networkInfo;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
