/* eslint-disable import/prefer-default-export */

/**
 * Handle the restore file to the form data for API
 *
 * @param {File} file - Restore file from GUI
 * @returns {FormData|null} Formatted form data with restore file for API
 */
export function handleRestoreFormData(file) {
  if (!file) {
    return null;
  }

  const fileExtension = file.name.split('.').pop();
  const RESTORE_FILE_EXTENSION = 'bin';

  if (fileExtension !== RESTORE_FILE_EXTENSION) {
    return null;
  }

  const formData = new FormData();

  formData.append('file', file);
  formData.append('size', file.size);

  return formData;
}
