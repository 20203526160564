const state = {
  key: 0,
  visible: false,
  text: '',
};

const mutations = {
  showToast(state, text) {
    if (!state.visible) {
      state.key += 1;
    }
    state.visible = true;
    state.text = text;
  },
  hideToast(state) {
    state.visible = false;
    state.text = '';
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
