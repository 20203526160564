import { System } from '@/services';

const state = {
  buzzerEnable: false,
  deviceName: '',
  qtsDeviceName: '',
  region: '',

  /**
   * Store the last payload of the diagnostic API.
   * Ensure that if the results of the diagnostic API do not finish and the user
   * leaves the diagnostic page, the results will still display when the user is back.
   */
  diagnostic: {},
};

const storeGetters = {
  displayDeviceName(state) {
    return `${state.qtsDeviceName}${state.deviceName}`;
  },
};

const mutations = {
  setDeviceName(state, payload) {
    if (!payload.qtsHostname) {
      state.qtsDeviceName = '';
      state.deviceName = payload.hostname;
    } else {
      state.qtsDeviceName = payload.qtsHostname.replace('-', '');
      state.deviceName = payload.hostname.replace(state.qtsDeviceName, '');
    }
  },
  setSystemBuzzer(state, buzzerEnable) {
    state.buzzerEnable = buzzerEnable;
  },
  setRegion(state, region) {
    state.region = region;
  },
  setDiagnostic(state, diagnostic) {
    state.diagnostic = diagnostic;
  },
};

const actions = {
  async getSystemBuzzer({ commit }) {
    const res = await System.getSystemBuzzer();

    commit('setSystemBuzzer', res.result.enableBuzzer);
  },
  async patchSystemBuzzer({ commit }, payload) {
    await System.patchSystemBuzzer(payload);
  },
  async getDeviceName({ commit }) {
    try {
      const res = await System.getDeviceName();

      commit('setDeviceName', res.result);
    } catch (error) {
      if (error.result) {
        commit('setDeviceName', error.result);
      }

      throw error;
    }
  },
  async getRegionSetting({ commit }) {
    const res = await System.getRegionSetting();

    commit('setRegion', res.result.region);
  },
  async updateRegionSetting({ commit, state }) {
    await System.updateRegionSetting(state.region);
  },
};

export default {
  namespaced: true,
  state,
  getters: storeGetters,
  mutations,
  actions,
};
