import apiPath from '@/services/apiPath';
import { qFetch } from '@/services/utilities';
import { normalizeMacAddress } from '@/common/network';
import { convertSecondToMillisecond } from '@/common/utilities';

/**
 * Handle the response format of backup jobs from API
 * @param {Object[]} backupJobs - List of backup jobs from API
 * @returns {Object[]} Formatted backup jobs for GUI
 */
function handleBackupJobsResponse(backupJobs) {
  if (!backupJobs) {
    return [];
  }

  return backupJobs.map((job) => ({
    backupJobInfo: job.backupJobInfo,
    portIds: job.portID,
    scheduleTime: convertSecondToMillisecond(job.scheduleTime),
  }));
}

/**
 * Note: Only QuRouter 321/322 support AirGap features
 */
export default {
  /**
   * Get the list of air gap devices
   * @returns {Promise<Object[]>} List of air gap devices
   */
  async getDevices() {
    const { result } = await qFetch.get(apiPath.v2.AirGapDevices);

    if (!result.airGapDevice) {
      return [];
    }

    return result.airGapDevice.map((device) => ({
      srcDeviceName: device.srcDeviceName,
      srcMacAddr: normalizeMacAddress(device.srcMacAddr),
      backupJobs: device.backupJobSchedule.map((job) => ({
        backupJobInfo: job.backupJobInfo,
        scheduleTime: job.scheduleTime,
        portIds: job.portID,
      })),
    }));
  },

  /**
   * Get the info of the air gap device by MAC address
   * @param {string} macAddress - MAC address of the device
   * @returns {Promise<Object>} Info of the air gap device
   */
  async getDeviceInfo(macAddress) {
    const { result } = await qFetch.get(apiPath.v2.AirGapDeviceInfo, { srcMacAddr: macAddress });

    if (!result) {
      return {};
    }

    return {
      backupJobSchedule: handleBackupJobsResponse(result.backupJobSchedule),
      pairedPorts: result.portStatusInfo
        ? result.portStatusInfo.map((port) => ({
          ...port,
          linkUpTime: convertSecondToMillisecond(port.linkUpTime),
        }))
        : [],
      role: result.role,
      status: result.status,
    };
  },

  /**
   * Get the port information registered by air gap device
   * @param {string} portName - Port name of the port to query
   * @returns {Object} Port information registered by air gap device
   */
  async getPortInfo(portName) {
    const { result } = await qFetch.get(apiPath.v2.AirGapPortInfo, { portID: portName });

    if (!result) {
      return {};
    }

    return {
      backupJobSchedule: handleBackupJobsResponse(result.backupJobSchedule),
      linkStatus: result.linkStatus,
      linkUpTime: convertSecondToMillisecond(result.linkUpTime),
      airGapDevices: result.airGapDevice.map((device) => ({
        backupJobSchedule: device.backupJobSchedule.map((job) => ({
          ...job,
          backupJobInfo: {
            jobName: job.backupJobInfo.jobName,
            jobId: job.backupJobInfo.jobID,
          },
          scheduleTime: convertSecondToMillisecond(job.scheduleTime),
        })),
        name: device.srcDeviceName,
        macAddress: device.srcMacAddr,
      })),
      upTime: result.upTime,
    };
  },

  /**
   * Get the list of paired port ID
   * @returns {string[]} List of the paired port ID
   */
  async getPortIds() {
    const { result } = await qFetch.get(apiPath.v2.AirGapPorts);

    return result.portID;
  },

  /**
   * Unbind the air gap device from QuRouter
   * @param {string} unbindType - Type of the way to unbind device
   * @param {string} unbindData - Data for unbinding the device
   * @returns {Promise<void>}
   */
  async unbindDevice(unbindType, unbindData) {
    await qFetch.post(apiPath.v2.AirGapDeviceUnbind, {
      data: {
        unbindType,
        unbindVal: unbindData,
      },
    });
  },
};
