<template>
  <ModalConfirm
    id="qpkg_update_confirm"
    title="ID_QPKG_UPDATE_TITLE"
    force
  >
    <div class="space-y-10-px">
      <TipMessage
        type="warning"
        text="ID_QPKG_UPDATE_DESC"
      />
      <TipMessage text="ID_QPKG_INCOMPATIBILITY_DESC" />
    </div>
    <template #modal-footer="{ cancel }">
      <ButtonText
        label="ID_QPKG_UPDATE_BTN"
        class="mr-10-px"
        @click="redirectAppCenter"
      />
      <ButtonText
        v-if="!forceUpdate"
        theme="secondary"
        label="ID_HORA_INIT_SKIP"
        @click="cancel"
      />
    </template>
  </ModalConfirm>
</template>

<script>
import ButtonText from '@/components/Button/ButtonText.vue';
import ModalConfirm from '@/components/Modal/ModalConfirm.vue';
import TipMessage from '@/components/Tip/TipMessage.vue';

export default {
  name: 'QpkgUpdateConfirm',
  components: {
    ButtonText,
    ModalConfirm,
    TipMessage,
  },
  props: {
    forceUpdate: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    /**
     * Redirect the URL to App Center of QTS
     * @returns {void}
     */
    redirectAppCenter() {
      window.open(`http://${window.location.host}/cgi-bin/main.html?a=qpkg`, '_parent');
    },
  },
};
</script>
