<template>
  <div
    class="dialog-mask"
    @click="$dialog.close(name)"
  >
    <section
      ref="dialog"
      :style="style"
      role="dialog"
      aria-modal="true"
      class="dialog"
      @click.stop
    >
      <header class="df jc-sb mb-20-px">
        <div class="df ai-center pt-10-px">
          <i class="dif ic_master mr-10-px w-50-px h-50-px" />
          <h2 class="mr-10-px fz-20 mb-0-px fw-bold">
            {{ $t(title) }}
          </h2>
        </div>
        <button
          aria-label="Close"
          title="Close"
          type="button"
          class="btn-close"
          @click="$dialog.close(name)"
        >
          <img
            src="@/assets/icons/ic_dark_cross.svg"
            alt="Close"
            width="25"
            height="25"
            class="close-image"
          >
        </button>
      </header>

      <slot />
    </section>
  </div>
</template>

<script>
import dialogBase from '@/mixins/dialogBase';

export default {
  name: 'DialogHint',
  mixins: [dialogBase],
  props: {
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      style: {
        top: '',
        left: '',
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: rem(570px);
  height: rem(320px);
  padding-left: rem(35px);
  background-color: dialog-color("background");
  border-radius: rem(4px) 0px rem(4px) rem(4px);
}

.btn-close {
  position: relative;
  width: rem(45px);
  height: rem(45px);
  padding: 0;
  background-color: transparent;
  border: 0;
  border-top: rem(45px) solid rgba(0, 0, 0, 0.5);
  border-left: rem(45px) solid #dfdfdf;
  outline: none;
}

.close-image {
  position: absolute;
  bottom: 0;
  left: rem(-45px);
}
</style>
