import store from '@/store';

export default {
  install(Vue) {
    const nextActions = [];
    let progressTimer = null;

    Vue.prototype.$loading = {
      show(options = {}) {
        if (typeof options === 'string') {
          options = { text: options };
        }

        if (options.estimatedTime && options.estimatedTime > 0) {
          const period = options.estimatedTime / 10;

          progressTimer = setInterval(() => {
            store.commit('Plugin/Loading/increaseProgress');
          }, period);
        }
        store.commit('Plugin/Loading/setLoading', {
          value: true,
          text: options.text || 'ID_LOADING',
          detail: options.detail || '',
          showBackdrop: options.showBackdrop ?? true,
          cancel: typeof options.cancel === 'function' ? options.cancel : null,
        });

        // concept: loading has higher priority than toast
        if (store.state.Plugin.Toast.visible) {
          Vue.prototype.$toast.clearAll();
        }
      },
      setVisible(visible) {
        store.commit('Plugin/Loading/setVisible', visible);
      },
      setText(text) {
        store.commit('Plugin/Loading/setText', text);
      },
      setDetail(detail) {
        store.commit('Plugin/Loading/setDetail', detail);
      },
      setShowBackdrop(showBackdrop) {
        store.commit('Plugin/Loading/setShowBackdrop', showBackdrop);
      },
      setCancel(cancel) {
        store.commit('Plugin/Loading/setCancel', cancel);
      },
      hide() {
        if (progressTimer !== null) {
          clearInterval(progressTimer);
          progressTimer = null;
        }
        store.commit('Plugin/Loading/setLoading', {
          value: false,
          text: '',
          detail: '',
        });

        if (nextActions.length !== 0) {
          Vue.nextTick(() => {
            while (nextActions.length !== 0) {
              const action = nextActions.pop();

              if (action.handler) {
                action.handler();
              }
              action.resolve();
            }
          });
        }
      },
      /**
       * Do something when loading just hide
       * (design reference from: Vue.nextTick)
       * @param {Function} handler - something to do
       * @returns {Promise}
       */
      next(handler) {
        if (!store.state.Plugin.Loading.loading) {
          if (handler) {
            handler();
          }

          return Promise.resolve();
        }

        return new Promise((resolve) => {
          nextActions.push({ handler, resolve });
        });
      },
    };
  },
};
