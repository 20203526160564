import apiPath from '../apiPath';
import { qFetch } from '../utilities';

export default {
  /**
   * Get profile of current Model.
   * @returns {Promise<Object>}
   */
  async getProfile() {
    return qFetch.get(apiPath.v2.WirelessProfile);
  },
  /**
   * Get wireless status.
   * @returns {Promise<Object>}
   */
  async getStatus() {
    return qFetch.get(apiPath.v2.WirelessStatus);
  },
  /**
   * Get all bands config.
   * @returns {Promise<Object>}
   */
  async getBands() {
    return qFetch.get(apiPath.v2.WirelessBand);
  },
  /**
   * Put all bands config.
   * @param {Object} bandsConfig - all bands config
   * @returns {Promise<Object>}
   */
  async putBands(bandsConfig) {
    return qFetch.put(apiPath.v2.WirelessBand, { data: bandsConfig });
  },
  /**
   * Get all bands status.
   * @returns {Promise<Object>}
   */
  async getBandsStatus() {
    return qFetch.get(apiPath.v2.WirelessBandStatus);
  },
  /**
   * Get channel options of current country code.
   * @returns {Promise<Object>}
   */
  async getChannelList() {
    return qFetch.get(apiPath.v2.WirelessChannelList);
  },
  /**
   * Get all VAPs config.
   * @returns {Promise<Object>}
   */
  async getVaps() {
    return qFetch.get(apiPath.v2.Vap);
  },
  /**
   * Put VAPs config.
   * @param {Object} vapsConfig - vaps config.
   * @returns {Promise<Object>}
   */
  async putVaps(vapsConfig) {
    return qFetch.put(apiPath.v2.Vap, { data: vapsConfig });
  },
  /**
   * Get all VAPs status.
   * @returns {Promise<Object>}
   */
  async getVapsStatus() {
    return qFetch.get(apiPath.v2.VapStatus);
  },
  /**
   * Restart Wireless to apply VAP settings, Band settings or country code.
   * @returns {Promise<Object>}
   */
  async postRestart() {
    return qFetch.post(apiPath.v2.WirelessRestart);
  },
  /**
   * Get WPS config.
   * @returns {Promise<Object>}
   */
  async getWps() {
    return qFetch.get(apiPath.v2.Wps);
  },
  /**
   * Put WPS config.
   * @param {Object} wpsConfig - WPS config.
   * @returns {Promise<Object>}
   */
  async putWps(wpsConfig) {
    return qFetch.put(apiPath.v2.Wps, { data: wpsConfig });
  },
  /**
   * Get WPS status.
   * @returns {Promise<Object>}
   */
  async getWpsStatus() {
    return qFetch.get(apiPath.v2.WpsStatus);
  },
  /**
   * Trigger WPS.
   * @returns {Promise<Object>}
   */
  async postWpsTrigger() {
    return qFetch.post(apiPath.v2.WpsTrigger);
  },
  /**
   * Get current wireless CAC state.
   * @returns {Promise<Object>}
   */
  async getCacState() {
    return qFetch.get(apiPath.v2.CacState);
  },
};
