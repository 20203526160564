<template>
  <ValidationObserver
    ref="observer"
    tag="form"
    novalidate
    @submit.prevent="validate"
  >
    <slot />
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from 'vee-validate';

/**
 * Form component to handle validation
 */
export default {
  name: 'FormBase',
  components: {
    ValidationObserver,
  },
  methods: {
    async validate() {
      const valid = await this.$refs.observer.validate();

      if (valid) {
        this.beforeSubmit();
        this.$emit('submit');
      }
    },
    beforeSubmit() {
      /**
       * Let observer reset and stop observing.
       * note: observer will start observing when user submit again.
       */
      this.$refs.observer.reset();
    },
  },
};
</script>
