const MTU_LIMIT = {
  WAN_MAX: 1500,
  WAN_PPPPE_MAX: 1492,
  WAN_MIN: 576,
  LAN_MAX: 9000,
  LAN_WIRELESS_MAX: 1500,
  LAN_MIN: 1500,
};

export default MTU_LIMIT;
