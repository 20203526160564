import Vue from 'vue';
import VueI18n from 'vue-i18n';
import ENG from '@/lang/locales/ENG.json';

Vue.use(VueI18n);

const messages = {
  ENG,
};

export const LANGUAGE_CODE = {
  AUTO: 'auto',
  ENG: 'ENG',
  SCH: 'SCH',
  TCH: 'TCH',
  CZE: 'CZE',
  DAN: 'DAN',
  GER: 'GER',
  SPA: 'SPA',
  FRE: 'FRE',
  ITA: 'ITA',
  JPN: 'JPN',
  KOR: 'KOR',
  NOR: 'NOR',
  POL: 'POL',
  RUS: 'RUS',
  FIN: 'FIN',
  SWE: 'SWE',
  DUT: 'DUT',
  TUR: 'TUR',
  THA: 'THA',
  POR: 'POR',
  HUN: 'HUN',
  GRK: 'GRK',
  ROM: 'ROM',
};

export const i18n = new VueI18n({
  locale: LANGUAGE_CODE.ENG,
  fallbackLocale: LANGUAGE_CODE.ENG,
  messages,
  silentTranslationWarn: true,
});
