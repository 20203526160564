import Vue from 'vue';
import Vuex from 'vuex';
import AccessControl from './modules/AccessControl';
import AirGap from './modules/AirGap';
import ApNetwork from './modules/ApNetwork';
import App from './modules/App';
import BlockedClient from './modules/BlockedClient';
import Client from './modules/Client';
import Dashboard from './modules/dashboard';
import Ddns from './modules/Ddns';
import FaultyDeviceReplacement from './modules/FaultyDeviceReplacement';
import Firewall from './modules/Firewall';
import Firmware from './modules/Firmware';
import Home from './modules/Home';
import Initial from './modules/Initial';
import Iptv from './modules/Iptv';
import MIRO from './modules/miro';
import Nat from './modules/Nat';
import Network from './modules/Network';
import OperationMode from './modules/OperationMode';
import ParentalControl from './modules/ParentalControl';
import Plugin from './modules/Plugin';
import Profile from './modules/Profile';
import Qos from './modules/Qos';
import Quwan from './modules/Quwan';
import RemoteSupport from './modules/RemoteSupport';
import ServicePort from './modules/ServicePort';
import SystemSetting from './modules/SystemSetting';
import TermsOfUse from './modules/TermsOfUse';
import Upnp from './modules/Upnp';
import USB from './modules/USB';
import UsbPacketCapture from './modules/UsbPacketCapture';
import VPN from './modules/Vpn';
import VpnClient from './modules/VpnClient';
import Wireless from './modules/Wireless';

Vue.use(Vuex);

const debug = import.meta.env.MODE !== 'production';

export default new Vuex.Store({
  modules: {
    App,
    AirGap,
    BlockedClient,
    Home,
    Profile,
    Plugin,
    MIRO,
    FaultyDeviceReplacement,
    Client,
    Initial,
    Dashboard,
    ParentalControl,
    AccessControl,
    SystemSetting,
    Firewall,
    Quwan,
    VPN,
    VpnClient,
    OperationMode,
    TermsOfUse,
    USB,
    Upnp,
    Ddns,
    Qos,
    RemoteSupport,
    ServicePort,
    UsbPacketCapture,

    // QuRouter
    Wireless,
    ApNetwork,
    Nat,
    Network,
    Iptv,
    Firmware,
  },
  strict: debug,
});
