/**
 * Common methods for setting position of dialog
 * Based on: https://baianat.github.io/vee-validate/guide/validation-provider.html#refactoring-validation-providers
 */

export default {
  mounted() {
    this.setPosition();
    window.addEventListener('resize', this.setPosition);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setPosition);
  },
  methods: {
    /**
     * Set position of dialog
     */
    setPosition() {
      const centerY = (window.innerHeight / 2) - (this.$refs.dialog.offsetHeight / 2);
      const centerX = (window.innerWidth / 2) - (this.$refs.dialog.offsetWidth / 2);

      this.style.top = `${centerY}px`;
      this.style.left = `${centerX}px`;
    },
  },
};
