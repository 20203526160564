import { toASCII, toUnicode } from 'punycode';
import apiPath from '../apiPath';
import { qFetch } from '../utilities';

export default {
  /**
   * Get all firewall available interface options
   * @returns {Promise<Object>}
   */
  async getFirewallInterfaces() {
    return qFetch.get(apiPath.v2.FirewallInterfaces);
  },
  /**
   * Get IPv6 Firewall config.
   * @returns {Promise<Object>} IPv6 Firewall config
   */
  async getFirewallIpv6Config() {
    const { result } = await qFetch.get(apiPath.v2.FirewallIpv6);

    return {
      enabled: result.enable,
      rules: result.data.map((rule) => ({
        action: rule.action,
        dstDomainName: toUnicode(rule.dstDN),
        dstIp: rule.dstIp,
        dstPort: rule.dstPort,
        icmpv6Type: rule.icmpv6Type,
        name: rule.name,
        protocol: rule.protocol,
        srcIface: rule.srcIface,
        srcIp: rule.srcIp,
        srcPort: rule.srcPort,
        srcGroup: rule.srcGroup || '',
      })),
    };
  },
  /**
   * Update IPv6 Firewall config.
   * @param {Object} config - The IPv6 Firewall config
   * @returns {Promise<Object>} The applying failed rules info
   */
  async updateFirewallIpv6Config(config) {
    const { result } = await qFetch.put(apiPath.v2.FirewallIpv6, {
      enable: config.enabled,
      data: config.rules.map((rule) => ({
        action: rule.action,
        dstDN: toASCII(rule.dstDomainName),
        dstIp: rule.dstIp,
        dstPort: rule.dstPort,
        icmpv6Type: rule.icmpv6Type,
        name: rule.name,
        protocol: rule.protocol,
        srcIface: rule.srcIface,
        srcIp: rule.srcIp,
        srcPort: rule.srcPort,
        srcGroup: rule.srcGroup,
      })),
    });

    return {
      failedRuleIndexes: result.data,
      failedRuleCode: result.error,
    };
  },
};
