import { mapGetters, mapMutations } from 'vuex';
import { SET_IS_DEVICE_REBOOTING } from '@/store/mutation-types';
import { clearSystemEnvironment } from '@/common/system';
import { convertMillisecondToSecond } from '@/common/utilities';

/**
 * Common function to display reboot countdown in the loading modal, used in:
 * - QuwanView
 * - DeviceActionSetting
 * - DeviceRestartSetting
 * - FirmwareUpgrade mixin
 * - DeviceReboot mixin
 * - QuwanDeployment mixin
 */
export default {
  computed: {
    ...mapGetters('Profile', ['isMiroSeries']),
  },
  methods: {
    ...mapMutations('Initial', [SET_IS_DEVICE_REBOOTING]),

    /**
     * Display the reboot countdown dialog
     *
     * @param {string} message - Message for loading modal
     * @param {number} [countDownSeconds] - Seconds for the countdown of restart
     * @param {boolean} [isReload] - True if the page will be reloaded when reboot is finished
     * @returns {Promise<void>}
     */
    async showRebootCountdown(message, countDownSeconds, isReload = true) {
      const MIRO_RESTART_COUNTDOWN_SECONDS = 300;
      const HORA_RESTART_COUNTDOWN_SECONDS = 180;
      const COUNTDOWN_INTERVAL = 1000;
      const COUNTDOWN_INTERVAL_SECOND = convertMillisecondToSecond(COUNTDOWN_INTERVAL);

      if (typeof countDownSeconds !== 'number' || countDownSeconds < 0) {
        countDownSeconds = this.isMiroSeries
          ? MIRO_RESTART_COUNTDOWN_SECONDS
          : HORA_RESTART_COUNTDOWN_SECONDS;
      }

      // always clear environment before reboot
      clearSystemEnvironment();
      this[SET_IS_DEVICE_REBOOTING](true);
      this.$loading.show();
      this.$loading.setText(`${this.$t(message)} ... ${countDownSeconds}`);

      return new Promise((resolve) => {
        const restartInterval = setInterval(() => {
          this.$loading.setText(`${this.$t(message)} ... ${countDownSeconds}`);
          countDownSeconds -= COUNTDOWN_INTERVAL_SECOND;

          if (countDownSeconds === 0) {
            clearInterval(restartInterval);
            this[SET_IS_DEVICE_REBOOTING](false);
            this.$loading.hide();
            resolve();

            if (isReload) {
              window.location.reload();
            }
          }
        }, COUNTDOWN_INTERVAL);
      });
    },
  },
};
