import LAN_IP6_TYPE from '@/enums/LanIp6Type';
import { isIpInSubnet } from './utilities';

/**
 * Check enabled/disabled state for LAN interface config.
 * @param {Object} lan - LAN interface config
 * @returns {boolean} True if LAN interface config is enabled
 */
export function isLanIp6Enabled(lan) {
  return !!lan?.ip6Type && lan.ip6Type !== LAN_IP6_TYPE.DISABLED;
}

/**
 * Get the list of the reserved IPs that are in the LAN IP subnet.
 * @param {Object} lanConfig - The config of the LAN interface
 * @returns {Object[]} List of th reserved IPs that are in the LAN IP subnet
 */
export function getValidReservedIps(lanConfig) {
  if (!lanConfig?.dhcpService?.reservedIps) {
    return [];
  }

  return lanConfig.dhcpService.reservedIps
    .filter((item) => isIpInSubnet(item.ip, lanConfig.ip4Address, lanConfig.ip4Prefix));
}
