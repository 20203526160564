import store from '@/store';

export default {
  install(Vue) {
    let nextMessages = [];
    let toastTimer = null;
    const createTimer = (text) => {
      if (store.state.Plugin.Toast.text === '') {
        store.commit('Plugin/Toast/showToast', text);
        toastTimer = setTimeout(() => {
          store.commit('Plugin/Toast/hideToast');

          if (nextMessages.length > 0) {
            const message = nextMessages.shift();

            createTimer(message);
          }
        }, 4000);
      } else {
        nextMessages.push(text);
      }
    };

    Vue.prototype.$toast = {
      show(options = {}) {
        if (typeof options === 'string') {
          options = { text: options };
        }
        createTimer(options.text);
      },
      clearAll() {
        clearTimeout(toastTimer);
        nextMessages = [];
        store.commit('Plugin/Toast/hideToast');
      },
    };
  },
};
