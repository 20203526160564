import { Nat } from '@/services';
import Alg from './Alg';
import Dmz from './Dmz';
import PortForwarding from './PortForwarding';

const storeState = {
  featuresStatus: [],
};

const storeGetters = {
  listWanUsedByDmz(state) {
    return state.featuresStatus.filter((item) => item.dmz).map((item) => item.wanInterfaceID);
  },
  listWanUsedByPortForwarding(state) {
    return state.featuresStatus
      .filter((item) => item.portForward).map((item) => item.wanInterfaceID);
  },
  listWanUsedByUpnp(state) {
    return state.featuresStatus.filter((item) => item.upnp).map((item) => item.wanInterfaceID);
  },
};

const mutations = {
  setFeaturesStatus(state, featuresStatus) {
    state.featuresStatus = featuresStatus;
  },
};

const actions = {
  async getFeaturesStatus({ commit }) {
    const res = await Nat.getFeaturesStatus();

    commit('setFeaturesStatus', res.result);
  },
};

const modules = {
  Alg,
  PortForwarding,
  Dmz,
};

export default {
  namespaced: true,
  state: storeState,
  getters: storeGetters,
  mutations,
  actions,
  modules,
};
