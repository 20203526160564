import BRIDGE_TYPE from '@/enums/BridgeType';
import IPTV_LABEL from '@/enums/IptvLabel';
import NETWORK_INTERFACE_TYPE from '@/enums/NetworkInterfaceType';

/**
 * Check if Bridge is used for IPTV.
 * @param {Object} bridge - Bridge config
 * @returns {boolean} True if Bridge is used for IPTV
 */
export function isIptvBridge(bridge) {
  const { labels = [] } = bridge;

  return labels.includes(IPTV_LABEL.IPTV);
}

/**
 * Get the network interface type of the bridge.
 * @param {string} bridgeType - The bridge type
 * @returns {string} The network interface type
 */
export function getBridgeInterfaceType(bridgeType) {
  return bridgeType === BRIDGE_TYPE.WAN ? NETWORK_INTERFACE_TYPE.WAN : NETWORK_INTERFACE_TYPE.LAN;
}
