import store from '@/store';

export default {
  install(Vue) {
    Vue.prototype.$page = {
      setDirty(value) {
        store.commit('Plugin/Page/setDirty', value);
      },
    };
  },
};
