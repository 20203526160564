import { cloneDeep } from 'lodash';
import { Wireless } from '@/services';
import { mergeDataWithKeys } from '@/common/utilities';

const storeState = {
  vapGroups: [],
  editedVapGroups: [],
  vapsStatus: [],
  groupEditedConfig: {},
  editedConfig: {},
  prepareEditingState: null,
  vapConsts: {
    vapGroupDataKeys: [
      'smartConnect',
      'type',
      'vapGroupIdx',
      'vaps',
    ],
    vapDataKeys: [
      'band',
      'enabled',
      'fastRoaming',
      'hideSsid',
      'password',
      'radiusIp',
      'radiusPassword',
      'radiusPort',
      'schedule',
      'security',
      'ssid',
      'vlanId',
      'interfaceId',
      'scheduleEnabled',
      'uapsd',
    ],
  },
  vapEnums: {
    groupType: {
      primary: 'primary',
      guest: 'guest',
    },
    band: {
      all: 'all', // smart connect
      2.4: '2.4',
      '5-1': '5-1',
      '5-2': '5-2',
    },
    security: {
      'wpa2-psk': 'wpa2-psk',
      'wpa-psk/wpa2-psk': 'wpa-psk/wpa2-psk',
      'wpa-enterprise': 'wpa-enterprise',
      'wpa2-enterprise': 'wpa2-enterprise',
      'wpa2-psk/wpa3-personal': 'wpa2-psk/wpa3-personal',
      owe: 'owe',
    },
  },
};

const storeGetters = {
  getSecurityText: (state) => (securuty) => {
    const securityEnums = state.vapEnums.security;
    const securityMapping = {
      [securityEnums['wpa2-psk']]: 'WPA2-PSK',
      [securityEnums['wpa-psk/wpa2-psk']]: 'WPA-PSK / WPA2-PSK',
      [securityEnums['wpa-enterprise']]: 'WPA-Enterprise',
      [securityEnums['wpa2-enterprise']]: 'WPA2-Enterprise',
      [securityEnums['wpa2-psk/wpa3-personal']]: 'WPA2-PSK / WPA3-Personal',
      [securityEnums.owe]: 'OWE',
    };

    return securityMapping[securuty];
  },
  getBandText: (state, getters, rootState, rootGetters) => (band) => {
    const bandMapping = {
      [state.vapEnums.band['2.4']]: '2.4 GHz',
      [state.vapEnums.band['5-1']]: rootGetters['Profile/isHoraSeries'] ? '5 GHz' : '5 GHz-1',
      [state.vapEnums.band['5-2']]: '5 GHz-2',
      [state.vapEnums.band.all]: '2.4 GHz / 5 GHz',
    };

    return bandMapping[band];
  },
  editedVapGroup(state, getters) {
    return getters.findVapGroup(
      state.groupEditedConfig.type,
      state.groupEditedConfig.vapGroupIdx,
      state.editedVapGroups,
    );
  },
  editedOriginalVapGroup(state, getters) {
    return getters.findVapGroup(state.groupEditedConfig.type, state.groupEditedConfig.vapGroupIdx);
  },
  editedVap(state, getters) {
    return getters.findVap(
      state.groupEditedConfig.type,
      state.groupEditedConfig.vapGroupIdx,
      state.editedConfig.band,
      state.editedVapGroups,
    );
  },
  editedOriginalVap(state, getters) {
    return getters.findVap(
      state.groupEditedConfig.type,
      state.groupEditedConfig.vapGroupIdx,
      state.editedConfig.band,
    );
  },
  findVapGroup: (state) => (vapGroupType, vapGroupIdx, srcVapGroups) => {
    srcVapGroups = srcVapGroups || state.vapGroups;
    const group = srcVapGroups
      .find((vapGroup) => vapGroup.vapGroupIdx === vapGroupIdx && vapGroup.type === vapGroupType);

    return group || null;
  },
  findVap: (state, getters) => (vapGroupType, vapGroupIdx, vapBand, srcVapGroups) => {
    const group = getters.findVapGroup(vapGroupType, vapGroupIdx, srcVapGroups);

    if (group) {
      const vap = group.vaps.find((item) => item.band === vapBand);

      return vap || null;
    }

    return null;
  },
  primaryVapGroups(state) {
    return state.vapGroups.filter((group) => group.type === 'primary');
  },
  guestVapGroups(state) {
    return state.vapGroups.filter((group) => group.type === 'guest');
  },
};

const mutations = {
  initVapGroups(state, groups) {
    const vapBandOrder = [
      state.vapEnums.band.all,
      state.vapEnums.band['2.4'],
      state.vapEnums.band['5-1'],
      state.vapEnums.band['5-2'],
    ];

    groups.forEach((group) => {
      group.vaps
        .sort((vap1, vap2) => vapBandOrder.indexOf(vap1.band) - vapBandOrder.indexOf(vap2.band));
      group.vaps.forEach((vap) => {
        if (vap.radiusPassword) {
          vap.radiusPassword = atob(vap.radiusPassword);
        }

        if (vap.password) {
          vap.password = atob(vap.password);
        }

        if (!vap.interfaceId) {
          vap.interfaceId = '';
        }
      });
    });
    state.editedVapGroups = cloneDeep(groups);
    state.vapGroups = groups;
  },
  modifyVap(state, { target, editedConfig }) {
    mergeDataWithKeys(state.vapConsts.vapDataKeys, editedConfig, target);
  },
  startEditing(state, { vapGroupType, vapGroupIdx, vapBand }) {
    const group = state.editedVapGroups
      .find((vapGroup) => vapGroup.vapGroupIdx === vapGroupIdx && vapGroup.type === vapGroupType);

    state.groupEditedConfig = mergeDataWithKeys(state.vapConsts.vapGroupDataKeys, group, {});
    state.editedConfig = state.groupEditedConfig.vaps.find((item) => item.band === vapBand);
  },
  endEditing(state) {
    state.groupEditedConfig = {};
    state.editedConfig = {};
  },
  setVapsStatus(state, vapsStatus) {
    state.vapsStatus = vapsStatus;
  },
  modifyEditedConfig(state, { key, value, target }) {
    target = target || state.editedConfig;
    target[key] = value;
  },
  setPrepareEditingState(state, value) {
    // value: { vapGroupType: string, vapGroupIdx: number, vapBand: string } | null
    state.prepareEditingState = value;
  },
};

const actions = {
  async getVaps({ commit, rootGetters }) {
    if (!rootGetters['Profile/supportWireless']) {
      // if not support wireless, keep vapGroups empty
      return;
    }

    const vapsRes = await Wireless.getVaps();

    commit('initVapGroups', vapsRes.result);
  },
  async getVapsStatus({ commit, rootGetters }) {
    if (!rootGetters['Profile/supportWireless']) {
      // if not support wireless, keep vapsStatus empty
      return;
    }

    const vapsStatusRes = await Wireless.getVapsStatus();

    commit('setVapsStatus', vapsStatusRes.result);
  },

  async toggleVapGroupSmartConnect(
    {
      state, getters, dispatch, rootState, rootGetters,
    },
    { vapGroupType, vapGroupIdx },
  ) {
    const bandMapping = {
      [state.vapEnums.band['2.4']]: '2.4G',
      [state.vapEnums.band['5-1']]: rootGetters['Profile/isHoraSeries'] ? '5G' : '5G-1',
      [state.vapEnums.band['5-2']]: '5G-2',
    };
    const targetGroup = getters.findVapGroup(vapGroupType, vapGroupIdx);
    const group = mergeDataWithKeys(state.vapConsts.vapGroupDataKeys, targetGroup, {});

    if (group.smartConnect) {
      const vaps = rootState.Wireless.wirelessProfile.supportBand.map((item) => {
        const newVap = mergeDataWithKeys(state.vapConsts.vapDataKeys, group.vaps[0], {});

        newVap.band = item;
        const bandSuffix = ` (${bandMapping[item]})`;
        let newSsid = `${newVap.ssid}${bandSuffix}`;

        if ((new Blob([newSsid])).size > 32) {
          while ((new Blob([newSsid])).size + bandSuffix.length > 32) {
            newSsid = newSsid.slice(0, -1);
          }
        }
        newVap.ssid = newSsid;

        if (item === state.vapEnums.band['5-2']) {
          newVap.enabled = false;
        }

        return newVap;
      });

      group.vaps = vaps;
    } else {
      group.vaps = [group.vaps[1]];
      group.vaps[0].ssid = group.vaps[0].ssid
        .replace(new RegExp(` \\(${bandMapping[group.vaps[0].band]}\\)$`), '');
      group.vaps[0].band = 'all';
    }
    group.smartConnect = !group.smartConnect;
    await dispatch('putVapGroup', group);
  },
  async toggleVapEnabled({ state, getters, dispatch }, { vapGroupType, vapGroupIdx, vapBand }) {
    const targetGroup = getters.findVapGroup(vapGroupType, vapGroupIdx);
    const group = mergeDataWithKeys(state.vapConsts.vapGroupDataKeys, targetGroup, {});
    const vap = group.vaps.find((item) => item.band === vapBand);

    vap.enabled = !vap.enabled;
    await dispatch('putVapGroup', group);
  },
  async putEditedVap({ getters, dispatch }) {
    await dispatch('putVapGroup', getters.editedVapGroup);
  },
  async putVapGroup({ state }, vapGroupConfig) {
    const config = cloneDeep(vapGroupConfig);

    config.vaps.forEach((vap) => {
      if (vap.radiusPassword) {
        vap.radiusPassword = btoa(vap.radiusPassword);
      }

      if (vap.password) {
        vap.password = btoa(vap.password);
      }
    });

    await Wireless.putVaps([config]);
    await Wireless.postRestart();
  },

  applyEditing({ getters, commit }, editedConfig) {
    const target = getters.editedVap;

    commit('modifyVap', { target, editedConfig });
  },
};

export default {
  namespaced: true,
  state: storeState,
  getters: storeGetters,
  mutations,
  actions,
};
