import QOS_RULE_TYPE from '@/enums/QosRuleType';
import apiPath from './apiPath';
import { qFetch } from './utilities';

const handleDpiAppResponse = (json) => ({
  availableCategories: json.availableCategories,
  categories: json.categories.map((category) => ({
    id: category.cid,
    name: category.cname,
    editable: category.editable,
    removable: category.removable,
  })),
  applications: json.applications.map((application) => ({
    id: application.aid,
    qnapId: application.qnap_aid,
    name: application.aname,
    categoryId: application.cid,
  })),
});

export default {
  /**
   * Get list of user rules
   *
   * @returns {Promise<Object[]>} List of user rules
   */
  async getQosUserRules() {
    const { result } = await qFetch.get(apiPath.QosUserRules);

    return result.rules
      .map((rule) => ({
        ...rule,
        type: QOS_RULE_TYPE.CUSTOM,
      }));
  },

  /**
   * Get list of segment rules
   *
   * @returns {Promise<Object[]>} List of segment rules
   */
  async getQosSegmentRules() {
    const { result } = await qFetch.get(apiPath.QosSegmentRules);

    return result.rules
      .map((rule) => ({
        ...rule,
        type: QOS_RULE_TYPE.SEGMENT,
      }));
  },

  /**
   * Get priority config
   *
   * @returns {Promise<Object>} Object of priority config
   */
  async getQosPriority() {
    const { result } = await qFetch.get(apiPath.QosPriority);

    return result;
  },

  /**
   * Get applications and categories of DPI
   *
   * @returns {Promise<Object[]>} Applications and categories of DPI
   */
  async getQunmsDpiApp() {
    const { result } = await qFetch.get(apiPath.QunmsDpiApp);

    return handleDpiAppResponse(result);
  },

  /**
   * Get list of DPI client OSs
   *
   * @returns {Promise<Object[]>} List of segment rules
   */
  async getQunmsDpiClientOss() {
    const { result } = await qFetch.get(apiPath.QunmsDpiClientOs);

    return result.clientOss;
  },
};
