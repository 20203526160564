import apiPath from '../apiPath';
import { qFetch } from '../utilities';

export default {
  /**
   * Get all static-route rules and routing-table of IPv4 and IPv6
   * @returns {Promise<Object>} Route map with IPv4 and IPv6 routing list
   */
  async getRoutes() {
    const { result } = await qFetch.get(apiPath.v2.Route);

    return result;
  },
  /**
   * Add new IPv4 static route rule
   * @param {Object} routeRule - IPv4 static route rule
   * @returns {Primise<Object>}
   */
  async postRouteIpv4(routeRule) {
    return qFetch.post(apiPath.v2.RouteIpv4, { data: routeRule });
  },
  /**
   * Delete IPv4 static route rule
   * @param {string} routeId - route ID
   * @returns {Primise<Object>}
   */
  async deleteRouteIpv4(routeId) {
    return qFetch.delete(`${apiPath.v2.RouteIpv4}/${routeId}`);
  },
  /**
   * Put IPv4 static route rule
   * @param {string} routeId - route ID
   * @param {Object} routeRule - IPv4 static route rule
   * @returns {Primise<Object>}
   */
  async putRouteIpv4(routeId, routeRule) {
    return qFetch.put(`${apiPath.v2.RouteIpv4}/${routeId}`, { data: routeRule });
  },
  /**
   * Add new IPv6 static route rule
   * @param {Object} routeRule - IPv6 static route rule
   * @returns {Primise<Object>}
   */
  async postRouteIpv6(routeRule) {
    return qFetch.post(apiPath.v2.RouteIpv6, { data: routeRule });
  },
  /**
   * Delete IPv6 static route rule
   * @param {string} routeId - route ID
   * @returns {Primise<Object>}
   */
  async deleteRouteIpv6(routeId) {
    return qFetch.delete(`${apiPath.v2.RouteIpv6}/${routeId}`);
  },
  /**
   * Add new IPv6 static route rule
   * @param {Object} routeRule - IPv6 static route rule
   * @returns {Primise<Object>}
   */
  async putRouteIpv6(routeId, routeRule) {
    return qFetch.put(`${apiPath.v2.RouteIpv6}/${routeId}`, { data: routeRule });
  },
};
