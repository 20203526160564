const storeState = {
  visibleModalIds: [],
};

const storeGetters = {
  checkVisible: (state) => (id) => {
    const check = state.visibleModalIds.includes(id);

    return check;
  },
};

const mutations = {
  show(state, id) {
    if (!state.visibleModalIds.includes(id)) {
      state.visibleModalIds.push(id);
    }
  },
  hide(state, id) {
    const idx = state.visibleModalIds.indexOf(id);

    if (idx > -1) {
      state.visibleModalIds.splice(idx, 1);
    }
  },
};

export default {
  namespaced: true,
  state: storeState,
  getters: storeGetters,
  mutations,
};
