<template>
  <div
    class="q-input-radio-wrapper"
    :class="{
      'no-label': noLabel,
    }"
  >
    <input
      :id="innerId"
      v-model="innerValue"
      :name="name"
      :value="value"
      :disabled="disabled"
      type="radio"
      class="q-input-radio"
    >
    <label
      :for="innerId"
      class="df ai-center q-input-radio-label"
    >
      <slot>
        <div v-if="label">{{ $t(label) }}</div>
      </slot>
    </label>
  </div>
</template>

<script>
let qInputRadioAutoIncreasementId = 0;

export default {
  name: 'InputRadio',
  model: {
    prop: 'checked',
    event: 'input',
  },
  props: {
    checked: {
      type: [Number, String, Boolean],
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },

    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Number, String, Boolean],
      default: true,
    },
    name: {
      type: String,
      default: null,
    },
  },
  data() {
    qInputRadioAutoIncreasementId += 1;

    return {
      defaultId: `__Q_INPUT_RADIO__${qInputRadioAutoIncreasementId}`,
    };
  },
  computed: {
    innerValue: {
      get() {
        return this.checked;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    innerId() {
      return this.id || this.defaultId;
    },
    noLabel() {
      return !this.label && !this.$slots.label;
    },
  },
};
</script>

<style lang="scss" scoped>
$button-height: rem(30px);
$radio-width: rem(16px);
$radio-my:  calc(($button-height - $radio-width) / 2);
$label-ml: rem(9px);

.q-input-radio-wrapper {
  position: relative;
  z-index: 1;
  display: flex;
  padding-left: $radio-width + $label-ml;
}

.q-input-radio-wrapper.no-label {
  padding-left: $radio-width;

  .q-input-radio-label {
    &::before, &::after {
      left: -1 * $radio-width;
    }
  }
}

.q-input-radio {
  position: absolute;
  top: $radio-my;
  left: 0;
  z-index: -1;
  flex-shrink: 0;
  width: $radio-width;
  height: $radio-width;
  opacity: 0;
}

.q-input-radio-label {
  position: relative;
  min-height: $button-height;

  &::before, &::after {
    position: absolute;
    top: $radio-my;
    left: -1 * ($radio-width + $label-ml);
    display: block;
    width: $radio-width;
    height: $radio-width;
    pointer-events: none;
    content: "";
    opacity: 1;
  }

  &::before {
    border: #adb5bd solid 1px;
    border-radius: 50%;
    transition:
      background-color .15s ease-in-out,
      border-color .15s ease-in-out,
      box-shadow .15s ease-in-out;
  }

  &::after {
    background: 50%/50% 50% no-repeat;
  }
}

.q-input-radio:checked ~ .q-input-radio-label {
  &::before {
    color: #ffffff;
    background-color: #007bff;
    border-color: #007bff;
  }
  &::after {
    background-image: url("@/assets/icons/input/ic_input_radio_checked.svg");
  }
}

.q-input-radio:checked:disabled ~ .q-input-radio-label {
  &::before {
    background-color: rgba(0, 123, 255, 0.5);
  }
}

.q-input-radio:active ~ .q-input-radio-label {
  &::before {
    color: #ffffff;
    background-color: #b3d7ff;
    border-color: #b3d7ff;
  }
}

.q-input-radio:focus:not(:active) ~ .q-input-radio-label {
  &::before {
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  }
}

.q-input-radio:focus:not(:checked) ~ .q-input-radio-label {
  &::before {
    border-color: #80bdff;
  }
}

.q-input-radio:disabled ~ .q-input-radio-label {
  &::before {
    background-color: #e9ecef;
    border-color: #adb5bd;
  }
}

.q-input-radio:disabled {
  cursor: not-allowed;
}
</style>
