import apiPath from '../apiPath';
import { qFetch } from '../utilities';

export default {
  /**
   * Get all ALG configs.
   * @returns {Promise<Object>}
   */
  async getAlg() {
    return qFetch.get(apiPath.v2.Alg);
  },
  /**
   * Put ALG configs.
   * @param {Object} algConfigs - ALG configs
   * @returns {Promise<Object>}
   */
  async putAlg(algConfigs) {
    return qFetch.put(apiPath.v2.Alg, { data: algConfigs });
  },
  /**
   * Get all rules of DMZ.
   * @returns {Promise<Object>}
   */
  async getDmz() {
    return qFetch.get(apiPath.v2.Dmz);
  },
  /**
   * Put rules of DMZ.
   * @param {Object} dmzRules - rules of DMZ
   * @returns {Promise<Object>}
   */
  async putDmz(dmzRules) {
    return qFetch.put(apiPath.v2.Dmz, { data: dmzRules });
  },
  /**
   * Clear rule of DMZ.
   * @param {string} wanInterface - WAN interface ID
   * @returns {Promise<Object>}
   */
  async deleteDmz(wanInterface) {
    return qFetch.delete(`${apiPath.v2.Dmz}/${wanInterface}`);
  },
  /**
   * Get all rules of port forwarding.
   * @returns {Promise<Object>}
   */
  async getPortForwarding() {
    return qFetch.get(apiPath.v2.PortForwarding);
  },
  /**
   * Put rule of port forwarding.
   * @param {string} ruleId - rule ID
   * @param {Object} portForwardingRule - rule of port forwarding
   * @returns {Promise<Object>}
   */
  async putPortForwarding(ruleId, portForwardingRule) {
    return qFetch.put(`${apiPath.v2.PortForwarding}/${ruleId}`, { data: portForwardingRule });
  },
  /**
   * Delete rule of port forwarding.
   * @param {string} ruleId - rule ID
   * @returns {Promise<Object>}
   */
  async deletePortForwarding(ruleId) {
    return qFetch.delete(`${apiPath.v2.PortForwarding}/${ruleId}`);
  },
  /**
   * Post rule to add new port forwarding.
   * @param {Object} portForwardingRule - rule of port forwarding
   * @returns {Promise<Object>}
   */
  async postPortForwarding(portForwardingRule) {
    return qFetch.post(apiPath.v2.PortForwarding, { data: portForwardingRule });
  },
  /**
   * get enable status of DMZ / Port Forward / UPnP features
   * @returns {Promise<Object>}
   */
  async getFeaturesStatus() {
    return qFetch.get(apiPath.PortForwardFeaturesStatus);
  },
};
