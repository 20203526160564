export default {
  install(Vue) {
    /**
     * Show loading with backdrop until all given promises are resolved or rejected
     * @param {Promise<any> | Promise<any>[]} promises - promise or promises to wait
     * @param {Object} options
     * @param {boolean} [options.showLoading] - If true, show loading until promise resolve
     * @param {Function} [options.errorHandler] - custom error handler
     * @returns {Promise<boolean>} True if no promise is rejected
     */
    Vue.prototype.$viewInit = async (promises, {
      showLoading = false,
      errorHandler = null,
    } = {}) => {
      if (!Array.isArray(promises)) {
        promises = [promises];
      }

      try {
        if (showLoading) {
          Vue.prototype.$loading.show();
        }
        await Promise.all(promises);

        return true;
      } catch (error) {
        if (errorHandler === null) {
          Vue.prototype.$notify.error(error.message);
        } else {
          errorHandler(error);
        }

        return false;
      } finally {
        if (showLoading) {
          Vue.prototype.$loading.hide();
        }
      }
    };
  },
};
