/* eslint-disable import/prefer-default-export */

export const COUNTRY_CODE_TEXT_MAPPING = {
  AE: 'ID_COUNTRY_NEMA_AE',
  AF: 'ID_COUNTRY_NEMA_AF',
  AL: 'ID_COUNTRY_NEMA_AL',
  AM: 'ID_COUNTRY_NEMA_AM',
  AN: 'ID_COUNTRY_NEMA_AN',
  AR: 'ID_COUNTRY_NEMA_AR',
  AT: 'ID_COUNTRY_NEMA_AT',
  AU: 'ID_COUNTRY_NEMA_AU',
  AX: 'ID_COUNTRY_NAME_AX',
  AZ: 'ID_COUNTRY_NEMA_AZ',
  BB: 'ID_COUNTRY_NEMA_BB',
  BD: 'ID_COUNTRY_NEMA_BD',
  BE: 'ID_COUNTRY_NEMA_BE',
  BF: 'ID_COUNTRY_NEMA_BF',
  BG: 'ID_COUNTRY_NEMA_BG',
  BH: 'ID_COUNTRY_NEMA_BH',
  BL: 'ID_COUNTRY_NEMA_BL',
  BM: 'ID_COUNTRY_NEMA_BM',
  BN: 'ID_COUNTRY_NEMA_BN',
  BO: 'ID_COUNTRY_NEMA_BO',
  BR: 'ID_COUNTRY_NEMA_BR',
  BS: 'ID_COUNTRY_NEMA_BS',
  BT: 'ID_COUNTRY_NEMA_BT',
  BZ: 'ID_COUNTRY_NEMA_BZ',
  CA: 'ID_COUNTRY_NEMA_CA',
  CF: 'ID_COUNTRY_NEMA_CF',
  CH: 'ID_COUNTRY_NEMA_CH',
  CI: 'ID_COUNTRY_NEMA_CI',
  CL: 'ID_COUNTRY_NEMA_CL',
  CN: 'ID_COUNTRY_NEMA_CN',
  CO: 'ID_COUNTRY_NEMA_CO',
  CR: 'ID_COUNTRY_NEMA_CR',
  CX: 'ID_COUNTRY_NEMA_CX',
  CY: 'ID_COUNTRY_NEMA_CY',
  CZ: 'ID_COUNTRY_NEMA_CZ',
  DE: 'ID_COUNTRY_NEMA_DE',
  DK: 'ID_COUNTRY_NEMA_DK',
  DM: 'ID_COUNTRY_NEMA_DM',
  DO: 'ID_COUNTRY_NEMA_DO',
  DZ: 'ID_COUNTRY_NEMA_DZ',
  EC: 'ID_COUNTRY_NEMA_EC',
  EE: 'ID_COUNTRY_NEMA_EE',
  EG: 'ID_COUNTRY_NEMA_EG',
  ES: 'ID_COUNTRY_NEMA_ES',
  ET: 'ID_COUNTRY_NEMA_ET',
  FI: 'ID_COUNTRY_NEMA_FI',
  FM: 'ID_COUNTRY_NEMA_FM',
  FO: 'ID_COUNTRY_NEMA_FO',
  FR: 'ID_COUNTRY_NEMA_FR',
  GB: 'ID_COUNTRY_NEMA_GB',
  GD: 'ID_COUNTRY_NEMA_GD',
  GE: 'ID_COUNTRY_NEMA_GE',
  GF: 'ID_COUNTRY_NAME_GF',
  GH: 'ID_COUNTRY_NEMA_GH',
  GI: 'ID_COUNTRY_NEMA_GI',
  GL: 'ID_COUNTRY_NEMA_GL',
  GP: 'ID_COUNTRY_NAME_GP',
  GR: 'ID_COUNTRY_NEMA_GR',
  GT: 'ID_COUNTRY_NEMA_GT',
  GU: 'ID_COUNTRY_NEMA_GU',
  HK: 'ID_COUNTRY_NEMA_HK',
  HM: 'ID_COUNTRY_NAME_HM',
  HN: 'ID_COUNTRY_NEMA_HN',
  HR: 'ID_COUNTRY_NEMA_HR',
  HU: 'ID_COUNTRY_NEMA_HU',
  ID: 'ID_COUNTRY_NEMA_ID',
  IE: 'ID_COUNTRY_NEMA_IE',
  IL: 'ID_COUNTRY_NEMA_IL',
  IN: 'ID_COUNTRY_NEMA_IN',
  IQ: 'ID_COUNTRY_NEMA_IQ',
  IS: 'ID_COUNTRY_NEMA_IS',
  IT: 'ID_COUNTRY_NEMA_IT',
  JM: 'ID_COUNTRY_NEMA_JM',
  JO: 'ID_COUNTRY_NEMA_JO',
  JP: 'ID_COUNTRY_NEMA_JP',
  KE: 'ID_COUNTRY_NEMA_KE',
  KN: 'ID_COUNTRY_NEMA_KN',
  KR: 'ID_COUNTRY_NEMA_KR',
  KW: 'ID_COUNTRY_NEMA_KW',
  KY: 'ID_COUNTRY_NEMA_KY',
  KZ: 'ID_COUNTRY_NEMA_KZ',
  LB: 'ID_COUNTRY_NEMA_LB',
  LC: 'ID_COUNTRY_NEMA_LC',
  LI: 'ID_COUNTRY_NEMA_LI',
  LK: 'ID_COUNTRY_NEMA_LK',
  LS: 'ID_COUNTRY_NEMA_LS',
  LT: 'ID_COUNTRY_NEMA_LT',
  LU: 'ID_COUNTRY_NEMA_LU',
  LV: 'ID_COUNTRY_NEMA_LV',
  MA: 'ID_COUNTRY_NEMA_MA',
  MC: 'ID_COUNTRY_NEMA_MC',
  MD: 'ID_COUNTRY_NEMA_MD',
  ME: 'ID_COUNTRY_NEMA_ME',
  MF: 'ID_COUNTRY_NEMA_MF',
  MH: 'ID_COUNTRY_NEMA_MH',
  MK: 'ID_COUNTRY_NEMA_MK',
  MN: 'ID_COUNTRY_NEMA_MN',
  MO: 'ID_COUNTRY_NEMA_MO',
  MP: 'ID_COUNTRY_NEMA_MP',
  MQ: 'ID_COUNTRY_NAME_MQ',
  MR: 'ID_COUNTRY_NEMA_MR',
  MT: 'ID_COUNTRY_NEMA_MT',
  MU: 'ID_COUNTRY_NEMA_MU',
  MV: 'ID_COUNTRY_NEMA_MV',
  MX: 'ID_COUNTRY_NEMA_MX',
  MY: 'ID_COUNTRY_NEMA_MY',
  NA: 'ID_COUNTRY_NEMA_NA',
  NF: 'ID_COUNTRY_NAME_NF',
  NG: 'ID_COUNTRY_NEMA_NG',
  NI: 'ID_COUNTRY_NEMA_NI',
  NL: 'ID_COUNTRY_NEMA_NL',
  NO: 'ID_COUNTRY_NEMA_NO',
  NZ: 'ID_COUNTRY_NEMA_NZ',
  OM: 'ID_COUNTRY_NEMA_OM',
  PA: 'ID_COUNTRY_NEMA_PA',
  PE: 'ID_COUNTRY_NEMA_PE',
  PF: 'ID_COUNTRY_NEMA_PF',
  PG: 'ID_COUNTRY_NEMA_PG',
  PH: 'ID_COUNTRY_NEMA_PH',
  PK: 'ID_COUNTRY_NEMA_PK',
  PL: 'ID_COUNTRY_NEMA_PL',
  PM: 'ID_COUNTRY_NEMA_PM',
  PR: 'ID_COUNTRY_NEMA_PR',
  PT: 'ID_COUNTRY_NEMA_PT',
  PW: 'ID_COUNTRY_NEMA_PW',
  PY: 'ID_COUNTRY_NEMA_PY',
  QA: 'ID_COUNTRY_NEMA_QA',
  RE: 'ID_COUNTRY_NEMA_RE',
  RO: 'ID_COUNTRY_NEMA_RO',
  RS: 'ID_COUNTRY_NEMA_RS',
  RU: 'ID_COUNTRY_NEMA_RU',
  RW: 'ID_COUNTRY_NEMA_RW',
  SA: 'ID_COUNTRY_NEMA_SA',
  SE: 'ID_COUNTRY_NEMA_SE',
  SG: 'ID_COUNTRY_NEMA_SG',
  SI: 'ID_COUNTRY_NEMA_SI',
  SK: 'ID_COUNTRY_NEMA_SK',
  SN: 'ID_COUNTRY_NEMA_SN',
  SR: 'ID_COUNTRY_NEMA_SR',
  SV: 'ID_COUNTRY_NEMA_SV',
  TC: 'ID_COUNTRY_NEMA_TC',
  TD: 'ID_COUNTRY_NEMA_TD',
  TF: 'ID_COUNTRY_NAME_TF',
  TG: 'ID_COUNTRY_NEMA_TG',
  TH: 'ID_COUNTRY_NEMA_TH',
  TN: 'ID_COUNTRY_NEMA_TN',
  TR: 'ID_COUNTRY_NEMA_TR',
  TT: 'ID_COUNTRY_NEMA_TT',
  TW: 'ID_COUNTRY_NEMA_TW',
  TZ: 'ID_COUNTRY_NEMA_TZ',
  UA: 'ID_COUNTRY_NEMA_UA',
  UG: 'ID_COUNTRY_NEMA_UG',
  UM: 'ID_COUNTRY_NAME_UM',
  US: 'ID_COUNTRY_NEMA_US',
  UY: 'ID_COUNTRY_NEMA_UY',
  UZ: 'ID_COUNTRY_NEMA_UZ',
  VC: 'ID_COUNTRY_NEMA_VC',
  VE: 'ID_COUNTRY_NEMA_VE',
  VG: 'ID_COUNTRY_NEMA_VG',
  VN: 'ID_COUNTRY_NEMA_VN',
  VU: 'ID_COUNTRY_NEMA_VU',
  WF: 'ID_COUNTRY_NEMA_WF',
  WS: 'ID_COUNTRY_NEMA_WS',
  YT: 'ID_COUNTRY_NEMA_YT',
  ZA: 'ID_COUNTRY_NEMA_ZA',
  ZW: 'ID_COUNTRY_NEMA_ZW',
};
