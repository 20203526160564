import store from '@/store';
import { SET_INIT_PATH_NAME } from '@/store/mutation-types';
import {
  getNewTermsOfUseVersionState,
  getShouldCheckTermsOfUseVersionState,
} from '@/common/termsOfUse';
import { hasAuthorization, getIsInit } from '@/common/utilities';

export default function (router) {
  let firstFlag = true;

  router.beforeEach((to, from, next) => {
    if (firstFlag && store.state.Profile.modelName === '') {
      store.commit(`Initial/${SET_INIT_PATH_NAME}`, to.name);
      firstFlag = false;
    }
    const hasNewTermsOfUseVersion = getNewTermsOfUseVersionState();
    const shouldCheckTermsOfUseVersion = getShouldCheckTermsOfUseVersionState();

    if (!to.meta.noAuth) {
      if (getIsInit()) {
        next({ name: 'BasePage' });
      } else if (hasAuthorization()) {
        if (shouldCheckTermsOfUseVersion
            && hasNewTermsOfUseVersion
            && to.name !== 'TermsOfUse'
        ) {
          next({ name: 'TermsOfUse' });
        } else if (store.getters['Profile/isApMode']) {
          try {
            if (to.meta.apMode) {
              next();
            } else {
              next({ name: 'ApWireless' });
            }
          } catch (err) {
            console.log(err);
          }
        } else if (to.meta.horaOnly && !store.getters['Profile/isHoraSeries']) {
          next({ name: 'Dashboard' });
        } else if (to.meta.miroOnly && !store.getters['Profile/isMiroSeries']) {
          next({ name: 'Dashboard' });
        } else if (to.meta.notSupportNetworkV3 && store.getters['Profile/supportNetworkV3']) {
          next({ name: 'Dashboard' });
        } else if (!store.getters['Profile/supportWireless'] && to.name === 'Vap') {
          next({ name: 'Dashboard' });
        } else if (!store.getters['Profile/supportApMode'] && to.name === 'OperationMode') {
          next({ name: 'Dashboard' });
        } else if (!store.getters['Profile/supportUpnp'] && to.name === 'Upnp') {
          next({ name: 'Dashboard' });
        } else if (!store.getters['Profile/supportIptv'] && to.name === 'Iptv') {
          next({ name: 'Dashboard' });
        } else if (!store.getters['Profile/supportMesh'] && to.name === 'Miro') {
          next({ name: 'Dashboard' });
        } else if (!store.getters['Profile/isMiroPlus'] && to.name === 'ControlHost') {
          next({ name: 'Dashboard' });
        } else {
          next();
        }
      } else if (store.getters['Profile/isApMode']) {
        next({ name: 'Login' });
      } else {
        next({ name: 'BasePage' });
      }
    } else {
      next();
    }
  });
}
