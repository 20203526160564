import { Wireless } from '@/services';
import { COUNTRY_CODE_TEXT_MAPPING } from '@/common/countryCode';
import { i18n } from '@/lang';
import Band from './Band';
import Channel from './Channel';
import Vap from './Vap';
import Wps from './Wps';

const storeState = {
  wirelessProfile: {},
  wirelessStatus: {},
  wirelessEnums: {
    wifiMode: {
      normal: 'normal',
      basic: 'basic',
    },
  },
  cacState: {
    running: false,
    startTime: 0,
    radarDetected: false,
  },
};

const storeGetters = {
  isNormalMode(state, getters, rootState, rootGetters) {
    if (!rootGetters['Profile/supportWireless']) {
      // if not support wireless, GET wirelessStatus is not available, so always return true
      return true;
    }

    return state.wirelessStatus.wifiMode === state.wirelessEnums.wifiMode.normal;
  },
  getCountryCodeText: () => (countryCode) => {
    const messageId = COUNTRY_CODE_TEXT_MAPPING[countryCode] || '';

    return i18n.t(messageId);
  },
};

const mutations = {
  initWirelessProfile(state, profile) {
    state.wirelessProfile = profile;
  },
  initWirelessStatus(state, status) {
    state.wirelessStatus = status;
  },
  setCacState(state, cacState) {
    state.cacState = cacState;
  },
};

const actions = {
  async getWirelessProfile({ commit }) {
    const profileRes = await Wireless.getProfile();

    commit('initWirelessProfile', profileRes.result);
  },
  async getWirelessStatus({ commit }) {
    const statusRes = await Wireless.getStatus();

    commit('initWirelessStatus', statusRes.result);
  },
  async getCacState({ commit, rootGetters }) {
    if (!rootGetters['Profile/supportWireless']) {
      return;
    }

    const res = await Wireless.getCacState();

    commit('setCacState', res.result);
  },
};

const modules = {
  Vap,
  Band,
  Channel,
  Wps,
};

export default {
  namespaced: true,
  state: storeState,
  getters: storeGetters,
  mutations,
  actions,
  modules,
};
