import { cloneDeep } from 'lodash';
import WAN_ADV_IP6_REGION from '@/enums/WanAdvIp6Region';
import WAN_IP4_TYPE from '@/enums/WanIp4Type';

/**
 * Handle the WAN interface config of API request data.
 * @param {Object} wan - WAN interface config of the network interface
 * @returns {Object} The new WAN interface config after handling
 */
export function handleWanRequest(wan) {
  wan = cloneDeep(wan);

  if (wan.ip4Type !== WAN_IP4_TYPE.DSLITE) {
    delete wan.dslite;
  }

  if (wan.password) {
    wan.password = btoa(wan.password);
  }

  return wan;
}

/**
 * Handle the WAN interface config of API response data.
 * @param {Object} wan - WAN interface config of the network interface
 * @returns {Object} The new WAN interface config after handling
 */
export function handleWanResponse(wan) {
  wan = cloneDeep(wan);

  if (typeof wan.enabled !== 'boolean') {
    wan.enabled = true;
  }

  if (!wan.dslite) {
    const WAN_DSLITE_DEFAULT_B4_ADDRESS = '192.0.0.2';

    wan.dslite = {
      aftrAddress: '',
      b4Address: WAN_DSLITE_DEFAULT_B4_ADDRESS,
    };
  }

  if (!wan.advIp6Region) {
    wan.advIp6Region = WAN_ADV_IP6_REGION.GLOBAL;
  }

  if (wan.password) {
    wan.password = atob(wan.password);
  }

  return wan;
}
