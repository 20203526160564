<template>
  <DialogHint
    name="VapExplanationDialog"
    title="ID_VAP_EXPLANATION_LABEL"
  >
    <div class="fz-14 pr-55-px">
      {{ $t("ID_VAP_EXPLANATION_DESC") }}
    </div>
  </DialogHint>
</template>

<script>
import DialogHint from '@/components/Dialog/DialogHint.vue';

export default {
  name: 'VapExplanationDialog',
  components: {
    DialogHint,
  },
};
</script>
