import apiPath from '@/services/apiPath';
import { qFetch } from '@/services/utilities';

export default {
  /**
   * Get the information of the Terms of Use
   * @returns {Promise<Object>} Information of the Terms of Use
   */
  async getTermsOfUseInfo() {
    const { result } = await qFetch.get(apiPath.v2.TermsOfUse);

    return result;
  },

  /**
   * Patch the consented version of the Terms of Use
   * @param {Object} payload - Data to update the Terms of Use info
   * @param {string} [payload.consentedVersion] - Consented version of the Terms of Use
   * @param {boolean} [payload.shouldCheckVersion] - True if it's necessary to
   *                                                 check the version of the Terms of Use
   * @returns {Promise<void>}
   */
  async patchTermsOfUseInfo(payload) {
    const { consentedVersion, shouldCheckVersion } = payload;
    const formattedPayload = {};

    if (consentedVersion !== undefined) {
      formattedPayload.consentedVersion = consentedVersion;
    }

    if (shouldCheckVersion !== undefined) {
      formattedPayload.shouldCheckVersion = shouldCheckVersion;
    }

    await qFetch.patch(apiPath.v2.TermsOfUse, {
      data: formattedPayload,
    });
  },
};
