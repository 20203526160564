<template>
  <DialogBase
    type="QuwanCloudDisconnectedReasons"
    title="ID_ERROR_MESSAGE_QUNMS_CONNECTION_DISCONNECTED_REASON_TITLE"
    class="fz-14"
    height="300"
  >
    <div class="flex-fluid df fd-column ov-auto pt-20-px plr-35-px">
      {{ $t('ID_ERROR_MESSAGE_QUNMS_CONNECTION_DISCONNECTED_REASON') }}
    </div>

    <ButtonText
      label="ID_OK"
      class="ml-a mt-20-px mb-25-px mr-30-px"
      @click="$dialog.close('QuwanCloudDisconnectedReasons')"
    />
  </DialogBase>
</template>

<script>
import ButtonText from '@/components/Button/ButtonText.vue';
import DialogBase from '@/components/Dialog/DialogBase.vue';

export default {
  name: 'QuwanCloudDisconnectedReasonsDialog',
  components: {
    ButtonText,
    DialogBase,
  },
};
</script>
