
<div
  class="dialog-mask"
  @click="clickOutside"
>
  <section
    ref="dialog"
    :style="style"
    role="dialog"
    aria-modal="true"
    class="dialog-base"
    @click.stop
  >
    <header class="header">
      <h2 class="mr-10-px fz-20 mb-0-px">
        <slot name="title">
          {{ $t(title) }}
        </slot>
      </h2>
      <ButtonIcon
        icon="ic_close"
        label="ID_CLOSE"
        class="dialog-button-close"
        @click="close"
      />
    </header>

    <slot />
  </section>
</div>
