import VPN_PROTOCOL_TYPE from '@/enums/VpnProtocolType';

const VPN_PROTOCOLS_LABELS = {
  [VPN_PROTOCOL_TYPE.QBELT]: 'QBelt',
  [VPN_PROTOCOL_TYPE.L2TP]: 'L2TP',
  [VPN_PROTOCOL_TYPE.PPTP]: 'PPTP',
  [VPN_PROTOCOL_TYPE.OVPN]: 'Open VPN',
};

/**
 * Get protocol label for GUI
 *
 * @param {string} protocol - VPN protocol from API
 * @returns {string} Protocol label for GUI
 */
export default (protocol) => VPN_PROTOCOLS_LABELS[protocol];
