import {
  isPlainObject,
  validateOverlappingPort,
} from '@/common/utilities';
import SERVICE_PORT_PROTOCOL from '@/enums/ServicePortProtocol';

export const MIX_PORTS_SETTING_MAX_NUMBERS = 15;

export const RESERVED_SERVICE_PORTS = [
  { protocol: SERVICE_PORT_PROTOCOL.TCP, port: '5000,22200' },
  { protocol: SERVICE_PORT_PROTOCOL.UDP, port: '5555,7788' },
];

/**
 * Get the filtered service port list by conflict protocol.
 * @param {Object[]} servicePortList - The service port list to filter
 * @param {Object} servicePort - The target service port
 * @returns {Object[]} - The filtered service port list
 */
export function filterServicePortByConflictProtocol(servicePortList, servicePort) {
  if (!Array.isArray(servicePortList) || !servicePort) {
    return [];
  }

  let filteredList = servicePortList.filter((port) => port.uiId !== servicePort.uiId);

  if (servicePort.protocol === SERVICE_PORT_PROTOCOL.ESP) {
    filteredList = filteredList.filter((port) => port.protocol === SERVICE_PORT_PROTOCOL.ESP);
  } else if (servicePort.protocol === SERVICE_PORT_PROTOCOL.ALL) {
    filteredList = filteredList.filter((port) => port.protocol !== SERVICE_PORT_PROTOCOL.ESP);
  } else {
    filteredList = filteredList
      .filter((port) => port.protocol === SERVICE_PORT_PROTOCOL.ALL
        || port.protocol === servicePort.protocol);
  }

  return filteredList;
}

/**
 * Check the port of the service port not conflict with other service ports.
 * @param {Object[]} servicePortList - The service port list to check
 * @param {string} unparsedPort - The port of service port
 * @returns {Object|null} The checking conflict result, null if not conflict
 */
export function checkPortOfServicePortConflict(servicePortList, unparsedPort) {
  if (!Array.isArray(servicePortList) || typeof unparsedPort !== 'string' || unparsedPort === '') {
    return null;
  }

  let checkingResult = null;
  const ports = unparsedPort.split(',');

  servicePortList.some((comparedServicePort) => {
    if (typeof comparedServicePort.port !== 'string' || comparedServicePort.port === '') {
      return false;
    }

    const comparePorts = comparedServicePort.port.split(',');

    return comparePorts.some((comparePort) => {
      const conflictPort = ports.find((port) => validateOverlappingPort(port, comparePort));

      if (conflictPort) {
        checkingResult = {
          conflictServicePort: comparedServicePort,
          conflictPort,
        };
      }

      return !!checkingResult;
    });
  });

  return checkingResult;
}

/**
 * Get the filtered service port list by conflict protocol.
 * @param {Object[]} servicePortList - List of the service ports
 * @param {Object} servicePort - Service port to be check
 * @returns {Object|Null} - Null if the service port is not conflict or return conflict service port
 */
export function checkServicePortConflict(servicePortList, servicePort) {
  if (!Array.isArray(servicePortList) || !isPlainObject(servicePort)) {
    return null;
  }

  const protocolConflictServicePorts = filterServicePortByConflictProtocol(
    servicePortList,
    servicePort,
  );

  return checkPortOfServicePortConflict(protocolConflictServicePorts, servicePort.port);
}
