
<ModalConfirm
  :id="id"
  type="warning"
>
  <template #message>
    <div class="fz-18 fw-bold">
      {{ $t('ID_FIRMWARE_UPGRADE_BACKUP_FAILED_TITLE') }}
    </div>
    <div class="mt-5-px">
      {{
        retryBackupCount < BACKUP_RETRIES_MAXIMUM
          ? $t('ID_FIRMWARE_UPGRADE_BACKUP_FAILED_MSG')
          : $t('ID_FIRMWARE_UPGRADE_BACKUP_RETRY_FAILED_MSG')
      }}
    </div>
  </template>
  <template #modal-footer>
    <ButtonText
      v-if="retryBackupCount < BACKUP_RETRIES_MAXIMUM"
      label="ID_FIRMWARE_UPGRADE_BACKUP_RETRY"
      theme="secondary"
      @click="handleContinueUpgrade(false)"
    />
    <ButtonText
      v-else
      label="ID_DOWNLOAD"
      theme="secondary"
      @click="downloadSystemBackup"
    />
    <ButtonText
      label="ID_FIRMWARE_UPGRADE_BACKUP_SKIP"
      theme="secondary"
      class="ml-10-px"
      @click="handleContinueUpgrade(true)"
    />
  </template>
</ModalConfirm>
