import apiPath from './apiPath';
import { qFetch } from './utilities';

export default {
  async getInitStatus() {
    return qFetch.get(apiPath.MachineInfo);
  },

  /**
   * Get info of account
   *
   * @returns {Promise<Object>} Info of account
   */
  async getAccountInfo() {
    const { result } = await qFetch.get(apiPath.token);

    return {
      isQid: result.is_qid,
      username: result.username,
      IsLocalAccountDefaultCred: result.IsLocalAccountDefaultCred,
    };
  },

  // miro
  async getQtsRedirect() {
    return qFetch.get(apiPath.QtsRedirect, '', {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    });
  },
  /**
   * Get the versions of QPKG and firmware
   * @returns {Promise<Object>} Versions of QPKG and firmware
   */
  async getFirmwareVersion() {
    const { result } = await qFetch.get(apiPath.FirmwareVersion);

    return {
      newerVersionDevice: result.new_version_device,
      qpkgVersion: result.qpkg_version,
      qurouterVersion: result.qurouter_version,
    };
  },
  async getLanInfo() {
    return qFetch.get(apiPath.LanInfo);
  },
};
