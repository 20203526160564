import apiPath from '@/services/apiPath';
import { qFetch } from '@/services/utilities';
import { handleRestoreFormData } from './utilities/fileUpload';

export default {
  /**
   * Check whether the restore device is valid
   *
   * @param {File} file - File of restore device to check
   * @returns {Promise<Object>} Verified restore device
   */
  async checkRestoreDevice(file) {
    const payload = handleRestoreFormData(file);
    const { result } = await qFetch.upload(apiPath.v2.FaultyDeviceReplacementRestore, payload);
    const { rmaDeviceInfo, rmaFWInfo } = result;

    return {
      id: rmaDeviceInfo.deviceID,
      name: rmaDeviceInfo.deviceName,
      isNameValid: rmaDeviceInfo.deviceNameValid,
      faultyDeviceSerialNumber: rmaDeviceInfo.faultyIdentifySN,
      organizationId: rmaDeviceInfo.organizationID,
      isCompatible: rmaFWInfo.compatible,
      compatibleFirmwareVersion: rmaFWInfo.compatibleFWVersion,
      firmwareVersion: rmaFWInfo.currentFWVersion,
      faultyDeviceFirmwareVersion: rmaFWInfo.targetFWVersion,
    };
  },

  /**
   * Replace the faulty device deployed on cloud with restore device
   *
   * @param {Object} restoreDevice - The restore device to replace faulty device
   * @returns {Promise<Object>} - The information of faulty device replacement
   */
  async replaceFaultyDevice(restoreDevice) {
    const { result } = await qFetch.put(apiPath.v2.FaultyDeviceReplacementUpdateDevice, {
      data: {
        deviceID: restoreDevice.id,
        deviceName: restoreDevice.name,
        faultyIdentifySN: restoreDevice.faultyDeviceSerialNumber,
        fwCompatible: restoreDevice.isCompatible,
        organizationID: restoreDevice.organizationId,
      },
    });

    return {
      firmwareCompatible: result.fwCompatible,
    };
  },
};
