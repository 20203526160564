import SERVICE_PORT_TYPE from '@/enums/ServicePortType';
import SERVICE_PORT_USED from '@/enums/ServicePortUsed';
import apiPath from './apiPath';
import { qFetch } from './utilities';

/**
 * Convert the service port list to new format for the view components.
 * @param {Object[]} servicePorts - The service port list to convert
 * @param {string} servicePortType - The service port type, used to generate ID
 * @returns {Object[]} The formatted service port list
 */
function convertServicePortList(servicePorts, servicePortType) {
  return servicePorts.map((servicePort, idx) => {
    const formattedServicePort = {
      // Index of service for specifying the service to be updated/deleted with API
      index: idx,
      uiId: `${servicePortType}-${idx}`, // the ID for GUI
      category: servicePort.category,
      service: servicePort.serv,
      type: servicePortType,
      enabled: servicePort.enabled,
      protocol: servicePort.proto,
      port: servicePort.port,
      description: servicePort.desc,
      usedInPortForwarding: servicePort.used === SERVICE_PORT_USED.USED,
      editable: servicePort.editable,
    };

    if (servicePortType === SERVICE_PORT_TYPE.SYSTEM) {
      formattedServicePort.isOpenVpn = formattedServicePort.service.includes('OpenVPN');
    }

    return formattedServicePort;
  });
}

/**
 * Convert the formatted service port to original format
 * @param {Object} servicePort - The formatted service port to convert
 * @returns {Object} The service port with original format
 */
function revertServicePort(servicePort) {
  return {
    category: servicePort.category,
    serv: servicePort.service,
    enabled: servicePort.enabled,
    proto: servicePort.protocol,
    port: servicePort.port,
    desc: servicePort.description,
    used: servicePort.usedInPortForwarding ? SERVICE_PORT_USED.USED : SERVICE_PORT_USED.NONE,
    editable: servicePort.editable,
  };
}

export default {
  async getCustomServicePort() {
    const { result } = await qFetch.get(apiPath.ServicePortCustom);

    return convertServicePortList(result.serv_custom_port, SERVICE_PORT_TYPE.CUSTOM);
  },
  async addCustomServicePort(payload) {
    await qFetch.post(apiPath.ServicePortCustom, revertServicePort(payload));
  },
  async modifyCustomServicePort(id, payload) {
    await qFetch.put(`${apiPath.ServicePortCustom}/${id}`, revertServicePort(payload));
  },
  async deleteCustomServicePort(id) {
    await qFetch.delete(`${apiPath.ServicePortCustom}/${id}`);
  },

  /**
   * Delete the list of the custom service ports
   * @param {Object[]} servicePorts - List of the custom service port
   * @returns {Promise<void>}
   */
  async deleteCustomServicePorts(servicePorts) {
    const indices = servicePorts.map((servicePort) => servicePort.index);

    await qFetch.delete(apiPath.v2.CustomServicePorts, {
      indices,
    });
  },

  async getSystemServicePort() {
    const { result } = await qFetch.get(apiPath.ServicePortSystem);

    return {
      systemPortList: convertServicePortList(result.serv_system_port, SERVICE_PORT_TYPE.SYSTEM),
      isSystemPortsReserved: result.reserved_mgmt,
    };
  },

  /**
   * Modify the list of the custom service ports
   * @param {Object[]} servicePorts - List of the custom service port
   * @returns {Promise<void>}
   */
  async modifyCustomServicePorts(servicePorts) {
    const payload = servicePorts.map((servicePort) => ({
      serviceName: servicePort.service,
      index: servicePort.index,
      protocol: servicePort.protocol,
      ports: servicePort.port,
      description: servicePort.description,
      editable: servicePort.editable,
    }));

    await qFetch.put(apiPath.v2.CustomServicePorts, {
      data: payload,
    });
  },

  async modifySystemServicePort(id, payload) {
    await qFetch.put(`${apiPath.ServicePortSystem}/${id}`, revertServicePort(payload));
  },
  /**
   * Update the system ports reserved setting.
   * @param {boolean} isSystemPortReserved - The value to set, true if reserved system ports
   * @returns {Promise<void>}
   */
  async updateReservedSetting(isSystemPortReserved) {
    await qFetch.put(apiPath.v2.ReservedSetting, {
      data: {
        reserved_mgmt: isSystemPortReserved,
      },
    });
  },
};
