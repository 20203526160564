import { qFetch } from '@/services/utilities';
import ERROR_CODE from '@/services/utilities/errorCode';
import apiPath from '../apiPath';
import { handleLocalAccountPasswordRequest } from './utilities/auth';

export default {
  /**
   * Get access setting
   * @returns {Promise<Object>}
   */
  async getAccessSetting() {
    return qFetch.get(apiPath.v2.AccessSetting);
  },
  /**
   * Update access setting
   * @param {Object} setting
   * @returns {Promise}
   */
  async patchAccessSetting(setting) {
    await qFetch.patch(apiPath.v2.AccessSetting, { data: setting });
  },
  /**
   * Get local account information
   * @returns {Promise<Object>}
   */
  async getLocalAccount() {
    return qFetch.get(apiPath.v2.LocalAccount);
  },
  /**
   * Update local account information
   * @param {Object} payload
   * @param {number} payload.id - local account id
   * @param {Object} payload.data - Account data for updates
   * @returns {Promise<void>}
   */
  async putLocalAccount(payload) {
    const { user } = payload.data;

    const formattedUser = {
      ...user,
      newPassword: handleLocalAccountPasswordRequest(user.newPassword),
    };

    if (user.password) {
      // The password is not needed when resetting the password of local account
      formattedUser.password = handleLocalAccountPasswordRequest(user.password);
    }

    await qFetch.put(`${apiPath.v2.LocalAccount}/${payload.id}`, { data: { user: formattedUser } });
  },
  /**
   * Get qnap id account information
   * @returns {Promise<Object>}
   */
  async getRemoteAccount() {
    return qFetch.get(apiPath.v2.RemoteAccount);
  },
  /**
   * Remove QID biding from local device and cloud
   *
   * @returns {Promise<Object>} Result of the QID unbind
   */
  async deleteQid() {
    const response = await qFetch.delete(apiPath.v2.Qid);

    return {
      unbindQidFromCloudFailed: response.error_code === ERROR_CODE.MYQNAPCLOUD_CONNECTION_ERROR,
    };
  },
};
