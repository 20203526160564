import { Wireless } from '@/services';

const storeState = {
  channels: [],
};

const storeGetters = {
  getBandChannels: (state) => (band) => {
    const channelData = state.channels.find((item) => item.band === band);

    if (channelData) {
      return channelData.channels;
    }

    return [];
  },
};

const mutations = {
  initChannels(state, channels) {
    state.channels = channels;
  },
};

const actions = {
  async getChannels({ commit, rootState }) {
    const channelListRes = await Wireless.getChannelList();

    commit('initChannels', channelListRes.result);
  },
};

export default {
  namespaced: true,
  state: storeState,
  getters: storeGetters,
  mutations,
  actions,
};
