<template>
  <div class="df ai-start pt-5-px pl-5-px">
    <div class="ic-sys-error mr-10-px flex-shrink-0 q-input-message-icon" />
    <div class="ws-pl c-error q-input-message-text">
      {{ convertLinebreak($t(message)) }}
    </div>
  </div>
</template>

<script>
import { convertLinebreak } from '@/common/utilities';

export default {
  name: 'TipInputMessage',
  props: {
    message: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      convertLinebreak,
    };
  },
};
</script>

<style lang="scss" scoped>
.q-input-message-icon {
  width: rem(15px);
  height: rem(21px); // equal to line-height
}
.q-input-message-text {
  font-size: rem(13px);
  line-height: rem(21px);
}
</style>
