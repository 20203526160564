import { sortBy } from 'lodash';
import SERVICES from '@/services';
import MIRO_NODE_STATUS from '@/enums/MiroNodeStatus';

const MIRO_NODE_MASTER_ID = 1;

const storeState = {
  miroList: [],
  miroEditData: {
    id: '',
    location: '',
    locationOption: '',
  },
  miroLocationDefaultOption: ['Living room', 'Bedroom', 'Office', 'Dining room', 'Study room', 'Customized'],
  satelliteList: [],
  selectedNodeSsid: '',
  miroListTotal: 0,
  currentMiroId: null,
};

const storeGetters = {
  missingNodes(state) {
    return state.miroList.some((node) => node.status === MIRO_NODE_STATUS.OFFLINE
      && node.id !== MIRO_NODE_MASTER_ID);
  },
  currentMiroData(state) {
    return state.miroList.find((miro) => miro.id === state.currentMiroId) || {
      id: -1,
      created_at: '',
      updated_at: '',
      location: '',
      status: 0,
      serial_no: '',
      mac_addrs: {
        wifi0: '',
        wifi1: '',
        wifi2: '',
      },
      last_status_change_time: '',
      upnode_id: 0,
      upnode_rssi: 0,
      fw_ver: '',
      fw_upgrade_status: 0,
      fw_error_code: '',
      model: '',
      mac_bh: '',
      upnode_conn_intf: '',
      meta_data: null,
      led_control: 0,
    };
  },
};

const mutations = {
  setMiroList(state, res) {
    if (res) {
      state.miroList = res.result;
      state.miroListTotal = res.total;
    }
  },
  setSatelliteList(state, payload) {
    if (payload) {
      state.satelliteList = sortBy(payload, [(node) => (node.role !== 'init')]);
    } else {
      state.satelliteList = [];
    }
  },
  changePage(state, payload) {
    Object.keys(payload).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(payload, key)) {
        state[key] = payload[key];
      }
    });
  },
  modifyMiroEditData(state, payload) {
    Object.keys(payload).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(payload, key)) {
        state.miroEditData[key] = payload[key];
      }
    });
  },
  startEditing(state, miroId = -1) {
    state.currentMiroId = miroId;
  },
  endEditing(state) {
    state.currentMiroId = null;
  },
};

const actions = {
  async getMiroList({ commit }, payload) {
    const res = await SERVICES.MIRO.getMiroList(payload);

    commit('setMiroList', res);
  },
  async getMiroListAll({ commit }, payload) {
    const res = await SERVICES.MIRO.getMiroListAll();

    commit('setMiroList', res);
  },
  async patchMiro({ commit }, payload) {
    return SERVICES.MIRO.patchMiro(payload);
  },
  async postSatellite({ commit }, payload) {
    return SERVICES.MIRO.postSatellite(payload);
  },
  async getSatellite({ commit }, payload) {
    const res = await SERVICES.MIRO.getSatellite(payload);

    commit('setSatelliteList', res.result);
  },
  async postWirelessLocation({ commit }, payload) {
    return SERVICES.MIRO.postWirelessLocation(payload);
  },
};

export default {
  namespaced: true,
  state: storeState,
  getters: storeGetters,
  mutations,
  actions,
};
