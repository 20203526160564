
<DialogForm
  type="QuwanAddRegion"
  title="ID_QUWAN_DIALOG_REGION_ADD_TITLE"
  submit-label="ID_ADD"
  width="460"
  height="300"
  @submit="submit"
>
  <q-row
    class="fz-14 mb-10-px"
    align-v="center"
  >
    <q-col flex-width="8rem">
      {{ $t('ID_QUWAN_DIALOG_REGION_ADD_TYPE_LABEL') }}
    </q-col>
    <q-col>
      <InputRadioGroup
        id="quwan-add-region-type"
        v-model="regionSetting.domainName"
        :options="options"
        :rules="{ required: true }"
        disabled
      />
    </q-col>
  </q-row>
  <q-row
    class="fz-14"
    align-v="center"
  >
    <q-col flex-width="8rem">
      {{ $t('ID_QUWAN_DIALOG_REGION_REGION_LABEL') }}
    </q-col>
    <q-col>
      <InputText
        id="quwan-settings-region-name"
        v-model="regionSetting.groupName"
        :rules="{
          required: true,
          excluded: {
            list: regionNames,
            message: 'ID_ERROR_MESSAGE_DUPLICATED_REGION_NAME',
          },
          max: {
            length: 64,
            message: $t('ID_DESCRIPTION_FIELD_MAX_LENGTH_MSG', { max: 64 }),
          },
        }"
      />
    </q-col>
  </q-row>
</DialogForm>
