import VPN_COMPRESSED_LINK from '@/enums/VpnCompressedLink';
import VPN_GATEWAY_REDIRECT from '@/enums/VpnGatewayRedirect';
import VPN_SERVER_ENABLED from '@/enums/VpnServerEnabled';
import VPN_USER_ENABLED from '@/enums/VpnUserEnabled';
import apiPath from './apiPath';
import { qFetch } from './utilities';

/**
 * Format QBelt config from API for GUI
 *
 * @param {Object} config - Config of QBelt from API
 * @returns {Object} Formatted config of QBelt for GUI
 */
function handleQbeltConfigResponse(config) {
  return {
    dnsList: config.dns_ip_to_client.join(','),
    enable: config.enable === VPN_SERVER_ENABLED.ENABLE,
    numMaxClients: config.num_max_clients,
    port: Number(config.port),
    psk: config.psk,
    tunnelIp: config.tunnel_ip,
    tunnelMask: config.tunnel_mask,
    tunnelMtu: config.tunnel_mtu,
  };
}

/**
 * Format L2TP config from API for GUI
 *
 * @param {Object} config - Config of L2TP from API
 * @returns {Object} Formatted config of L2TP for GUI
 */
function handleL2tpConfigResponse(config) {
  return {
    enable: config.Enable === VPN_SERVER_ENABLED.ENABLE,
    numMaxClients: config.MaxClients,
    manualDns: config.ManualDNS,
    psk: config.PSK,
    tunnelIp: config.ClientIPStart,
    authentication: config.Authentication,
  };
}

/**
 * Format OpenVPN config from API for GUI
 *
 * @param {Object} config - Config of OpenVPN from API
 * @returns {Object} Formatted config of OpenVPN for GUI
 */
function handleOpenvpnConfigResponse(config) {
  return {
    enable: config.Enable === VPN_SERVER_ENABLED.ENABLE,
    tunnelIp: config.ClientIPStart,
    protocol: config.Protocol,
    port: Number(config.Port),
    numMaxClients: config.MaxClients,
    encryption: config.Encryption,
    manualDns: config.ManualDNS,
    compressedLink: config.CompressedLink === VPN_COMPRESSED_LINK.ENABLE,
    gatewayRedirect: config.GatewayRedirect === VPN_GATEWAY_REDIRECT.ENABLE,
  };
}

/**
 * Format user of VPN from GUI for API
 *
 * @param {Object[]} user - User of VPN from GUI
 * @return {Object[]} Formatted user of GUI for API
 */
function handleUserRequest(user) {
  if (user.password) {
    return {
      enabled: user.enabled ? VPN_USER_ENABLED.ENABLE : VPN_USER_ENABLED.DISABLE,
      pw: btoa(user.password),
      user_name: user.userName,
    };
  }

  return {
    enabled: user.enabled ? VPN_USER_ENABLED.ENABLE : VPN_USER_ENABLED.DISABLE,
    user_name: user.userName,
  };
}

/**
 * Format users of VPN from API for GUI
 *
 * @param {Object[]} users - Users of VPN from API
 * @return {Object[]} Formatted users of VPN for GUI
 */
function handleUserResponse(users) {
  return users
    .map((user) => ({
      id: user.id,
      enabled: user.enabled === VPN_USER_ENABLED.ENABLE,
      userName: user.user_name,
    }))
    .sort((user1, user2) => user1.id.localeCompare(user2.id));
}

/**
 * Convert unit timestamp to epoch timestamp
 *
 * @param {string} unixTimestamp - Timestamp of unit format
 * @returns {string} Timestamp of epoch format
 */
function timestampUnixToEpoch(unixTimestamp) {
  const timestamp = parseInt(unixTimestamp, 10);

  if (Number.isNaN(timestamp)) {
    return 0;
  }

  return timestamp * 1000;
}

export default {
  /**
   * Get config of QBelt server
   *
   * @returns {Promise<Object>} Config of QBelt server
   */
  async getQbeltConfig() {
    const { result } = await qFetch.get(apiPath.qbeltServer);

    return handleQbeltConfigResponse(result);
  },

  /**
   * Get config of L2TP server
   *
   * @returns {Promise<Object>} Config of L2TP server
   */
  async getL2tpConfig() {
    const { result } = await qFetch.get(apiPath.l2tpServer);

    return handleL2tpConfigResponse(result);
  },

  /**
   * Get config of OpenVPN server
   *
   * @returns {Promise<Object>} Config of OpenVPN server
   */
  async getOpenvpnConfig() {
    const { result } = await qFetch.get(apiPath.openvpnServer);

    return handleOpenvpnConfigResponse(result);
  },

  /**
   * Download profile of OpenVPN
   *
   * @returns {Promise<Object>} Profile of OpenVPN
   */
  async downloadOpenvpnProfile() {
    await qFetch.download(apiPath.openvpnProfile);
  },

  /**
   * Update config of L2TP server
   *
   * @param {Object} config - Config of L2TP to update
   * @returns {Promise<Object>} Updated config of L2TP server
   */
  async updateL2tpConfig(config) {
    const { result } = await qFetch.put(apiPath.l2tpServer, {
      ManualDNS: config.manualDns,
      Enable: config.enable ? VPN_SERVER_ENABLED.ENABLE : VPN_SERVER_ENABLED.DISABLE,
      MaxClients: config.numMaxClients,
      PSK: config.psk,
      ClientIPStart: config.tunnelIp,
      Authentication: config.authentication,
    });

    return handleL2tpConfigResponse(result);
  },

  /**
   * Update config of OpenVPN server
   *
   * @param {Object} config - Config of OpenVPN to update
   * @returns {Promise<Object>} Updated config of OpenVPN server
   */
  async updateOpenvpnConfig(config) {
    const { result } = await qFetch.put(apiPath.openvpnServer, {
      Enable: config.enable ? VPN_SERVER_ENABLED.ENABLE : VPN_SERVER_ENABLED.DISABLE,
      ClientIPStart: config.tunnelIp,
      Protocol: config.protocol,
      Port: String(config.port),
      MaxClients: config.numMaxClients,
      Encryption: config.encryption,
      ManualDNS: config.manualDns,
      CompressedLink: config.compressedLink
        ? VPN_COMPRESSED_LINK.ENABLE
        : VPN_COMPRESSED_LINK.DISABLE,
      GatewayRedirect: config.gatewayRedirect
        ? VPN_GATEWAY_REDIRECT.ENABLE
        : VPN_GATEWAY_REDIRECT.DISABLE,
    });

    return handleOpenvpnConfigResponse(result);
  },

  /**
   * Update config of QBelt server
   *
   * @param {Object} config - Config of QBelt to update
   * @returns {Promise<Object>} Updated config of QBelt server
   */
  async updateQbeltConfig(config) {
    const { result } = await qFetch.put(apiPath.qbeltServer, {
      dns_ip_to_client: config.dnsList.split(','),
      enable: config.enable ? VPN_SERVER_ENABLED.ENABLE : VPN_SERVER_ENABLED.DISABLE,
      num_max_clients: config.numMaxClients,
      port: String(config.port),
      psk: config.psk,
      tunnel_ip: config.tunnelIp,
      tunnel_mask: config.tunnelMask,
      tunnel_mtu: config.tunnelMtu,
    });

    return handleQbeltConfigResponse(result);
  },

  /**
   * Get logs of connection
   *
   * @returns {Promise<Object[]>} Logs of connection
   */
  async getConnectionLogs() {
    const { result } = await qFetch.get(apiPath.connectionLog);

    return result
      .map((log) => ({
        id: log.id,
        action: log.action,
        deviceName: log.dev_name,
        timestamp: timestampUnixToEpoch(log.timestamp),
        protocol: log.protocol,
        sourceIp: log.src_ip,
        vpnIp: log.vpn_ip,
        userName: log.user_name,
      }))
      .sort((log1, log2) => log2.timestamp - log1.timestamp);
  },

  /**
   * Delete all logs of connection
   *
   * @returns {Promise}
   */
  async deleteConnectionLogs() {
    await qFetch.delete(apiPath.connectionLog);
  },

  /**
   * Get users of VPN
   *
   * @returns {Promise<Object[]>} Users of VPN
   */
  async getUsers() {
    const { result } = await qFetch.get(apiPath.vpnUsers);

    return handleUserResponse(result);
  },

  /**
   * Create user of VPN
   *
   * @param {Object} user - User of VPN to create
   * @returns {Promise<Object[]>} Users of VPN
   */
  async createUser(user) {
    const { result } = await qFetch.post(apiPath.vpnUsers, handleUserRequest(user));

    return handleUserResponse(result);
  },

  /**
   * Update user of VPN
   *
   * @param {Object} user - User of VPN to update
   * @returns {Promise<Object[]>} Updated user of VPN
   */
  async updateUser(user) {
    const { result } = await qFetch.put(apiPath.vpnUsers, handleUserRequest(user));

    return handleUserResponse(result);
  },

  /**
   * Delete user of VPN
   *
   * @param {Object} userName - Name of VPN user to delete
   * @returns {Promise<Object[]>} Updated user of VPN
   */
  async deleteUser(userName) {
    const { result } = await qFetch.delete(apiPath.vpnUsers, { user_name: userName });

    return handleUserResponse(result);
  },

  /**
   * Get list of online users
   *
   * @returns {Promise<Object[]>} List of online users
   */
  async getOnlineUsers() {
    const { result } = await qFetch.get(apiPath.vpnOnlineUsers);

    return result.map((onlineUser) => ({
      vpnIp: onlineUser.vpn_ip,
      userName: onlineUser.user_name,
      sourceIp: onlineUser.src_ip,
      deviceName: onlineUser.dev_name,
      id: onlineUser.id,
      timestamp: timestampUnixToEpoch(onlineUser.timestamp),
      protocol: onlineUser.protocol,
      macAddress: onlineUser.mac_addr,
    }));
  },
};
