import apiPath from '@/services/apiPath';
import { qFetch } from '@/services/utilities';
import { convertSecondToMillisecond } from '@/common/utilities';

export default {
  /**
   * Get the list of blocked clients
   * @returns {Promise<Object[]>} List of blocked clients
   */
  async getAll() {
    const { result } = await qFetch.get(apiPath.v2.BlockedClients);

    return result.data.map((client) => ({
      ...client,
      blockedTime: convertSecondToMillisecond(client.blockedTime),
    }));
  },

  /**
   * Create a new blocked client
   * @param {Object} blockedClient - Client to be blocked
   * @returns {Promise<void>}
   */
  async create(blockedClient) {
    await qFetch.post(apiPath.v2.BlockedClients, { data: blockedClient });
  },

  /**
   * Update the blocked client
   * @param {Object} blockedClient - Blocked client to be updated
   * @returns {Promise<void>}
   */
  async patch(blockedClient) {
    await qFetch.patch(`${apiPath.v2.BlockedClients}/${blockedClient.id}`, { data: blockedClient.data });
  },

  /**
   * Remove a blocked client
   * @param {Object} blockedClient - Blocked client to be removed
   * @returns {Promise<void>}
   */
  async delete(blockedClient) {
    await qFetch.delete(`${apiPath.v2.BlockedClients}/${blockedClient.id}`);
  },

  /**
   * Get list of interfaces that could be blocked
   * @returns {Promise<Object>} List of interfaces that could be blocked
   */
  async getBlockableInterfaces() {
    const { result } = await qFetch.get(apiPath.v2.BlockedClientsBlockableInterfaces);

    return result.data;
  },
};
