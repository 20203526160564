import { i18n } from '@/lang';

const state = {
  visible: false,
  text: '',
  detail: '',
  showBackdrop: false,
  cancel: null,
  key: 0,
  progressPercentage: 0,
};

const storeGetters = {
  displayText(state) {
    if (state.progressPercentage > 0) {
      return `${i18n.t(state.text)} ${state.progressPercentage}%`;
    }

    return state.text;
  },
};

const mutations = {
  setLoading(state, options) {
    const loadingChanged = state.visible !== options.value;

    state.visible = options.value;
    state.text = options.text;
    state.detail = options.detail;
    state.showBackdrop = options.showBackdrop;
    state.cancel = options.cancel;

    if (loadingChanged) {
      state.key += 1;
    }
    state.progressPercentage = 0;
  },
  setVisible(state, visible) {
    // just set visible state without increasing key or modifying another state
    state.visible = visible;
  },
  setText(state, text) {
    state.text = text;
  },
  setDetail(state, text) {
    state.detail = text;
  },
  setShowBackdrop(state, showBackdrop) {
    state.showBackdrop = showBackdrop;
  },
  setCancel(state, cancel) {
    state.cancel = cancel;
  },
  increaseProgress(state) {
    // current design: update progress every 10%, show max 90% until finish
    if (state.progressPercentage < 90) {
      state.progressPercentage += 10;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters: storeGetters,
  mutations,
};
