import { cloneDeep } from 'lodash';
import SERVICES from '@/services';
import { checkServicePortConflict } from '@/common/servicePort';
import SERVICE_PORT_PROTOCOL from '@/enums/ServicePortProtocol';

const state = {
  customPortList: [],
  systemPortList: [],
  isSystemPortsReserved: false,
  editedOriginalConfig: {},
  editedConfig: {
    isAddDialog: true,
    isSystemDialog: false,
    index: -1,
    service: '',
    protocol: SERVICE_PORT_PROTOCOL.ALL,
    port: '',
    description: '',
    usedInPortForwarding: 0,
  },
};

const storeGetters = {
  isCustomPortListEmpty(state) {
    return state.customPortList.length === 0;
  },
  editablePortList(state) {
    return state.customPortList.concat(state.systemPortList);
  },
  isReserveSystemPortsMessageVisible(state, getters, rootState) {
    if (state.isSystemPortsReserved) {
      return false;
    }

    if (rootState.Nat.Dmz.dmzRules.some((rule) => rule.enabled)) {
      return true;
    }

    return state.systemPortList
      .some((systemPort) => checkServicePortConflict(state.customPortList, systemPort));
  },
};

const mutations = {
  setCustomPortList(state, list) {
    state.customPortList = list;
  },
  setSystemPortList(state, list) {
    state.systemPortList = list;
  },
  setIsSystemPortsReserved(state, isSystemPortsReserved) {
    state.isSystemPortsReserved = isSystemPortsReserved;
  },
  modifyEditedConfig(state, { key, value }) {
    state.editedConfig[key] = value;
  },
  initEditedConfig(state, editedConfig) {
    state.editedOriginalConfig = cloneDeep(editedConfig);
    state.editedConfig = editedConfig;
  },
};

const actions = {
  async getCustomServicePort({ commit }) {
    const customPortList = await SERVICES.SERVICEPORT.getCustomServicePort();

    commit('setCustomPortList', customPortList);
  },
  async addCustomServicePort({ dispatch }, servicePort) {
    await SERVICES.SERVICEPORT.addCustomServicePort(servicePort);
    await dispatch('getCustomServicePort');
  },
  async modifyCustomServicePort({ dispatch }, data) {
    await SERVICES.SERVICEPORT.modifyCustomServicePort(data.id, data.servicePort);
    await dispatch('getCustomServicePort');
  },
  async deleteCustomServicePort({ dispatch }, id) {
    await SERVICES.SERVICEPORT.deleteCustomServicePort(id);
    await dispatch('getCustomServicePort');
  },
  async getSystemServicePort({ commit }) {
    const result = await SERVICES.SERVICEPORT.getSystemServicePort();

    commit('setSystemPortList', result.systemPortList);
    commit('setIsSystemPortsReserved', result.isSystemPortsReserved);
  },
  async modifySystemServicePort({ dispatch }, data) {
    await SERVICES.SERVICEPORT.modifySystemServicePort(data.id, data.servicePort);
    await dispatch('getSystemServicePort');
  },
  async reserveSystemPorts({ dispatch }) {
    await SERVICES.SERVICEPORT.updateReservedSetting(true);
    await dispatch('getSystemServicePort');
  },
};

export default {
  namespaced: true,
  state,
  getters: storeGetters,
  mutations,
  actions,
};
