import Confirm from './Confirm';
import Dialog from './Dialog';
import Loading from './Loading';
import Modal from './Modal';
import Notify from './Notify';
import Page from './Page';
import Toast from './Toast';

const modules = {
  Confirm,
  Dialog,
  Loading,
  Notify,
  Page,
  Toast,
  Modal,
};

export default {
  namespaced: true,
  modules,
};
