import dayjs from 'dayjs';
import { mapState, mapGetters } from 'vuex';
import { System } from '@/services';

/**
 * Common methods for the system backup.
 * Common used in:
 * - DeviceActionSetting
 * - FirmwareRestoreFailedConfirm
 */
export default {
  computed: {
    ...mapState('Firmware', ['localFirmware']),
    ...mapGetters('SystemSetting', ['displayDeviceName']),
  },
  methods: {
    /**
     * Download the system backup file.
     * @returns {Promise<void>}
     */
    async downloadSystemBackup() {
      this.$loading.show();

      const dateText = dayjs().format('YYYYMMDD');
      const filename = `${this.displayDeviceName}_${this.localFirmware.version}_${dateText}.bin`;

      try {
        await System.backupSystem(filename);
      } catch (error) {
        this.$notify(error.type, error.message);
      } finally {
        this.$loading.hide();
      }
    },
  },
};
