import apiPath from '../apiPath';
import { qFetch } from '../utilities';

export default {
  /**
   * Get QNAP website information
   * @returns {Promise<Object>}
   */
  async getWebsiteInfo() {
    return qFetch.get(apiPath.v2.WebsiteInfo);
  },
  /**
   * Get remote support status
   * @returns {Promise<Object>}
   */
  async getSupportStatus() {
    return qFetch.get(apiPath.v2.RemoteSupportEnable);
  },
  /**
   * Check contact information is valid
   * @param {Object} data
   * @param {string} data.email - user email
   * @param {string} data.ticketId - QNAP support ticket id
   * @returns {Promise}
   */
  async checkContactInfo(data) {
    await qFetch.post(apiPath.v2.RemoteSupportCheck, { data });
  },
  /**
   * Create a local support account
   * @param {Object} data
   * @param {string} data.email - user email
   * @param {string} data.ticketId - QNAP support ticket id
   * @returns {Promise<Object>}
   */
  async createLocalSupportAccount(data) {
    return qFetch.post(apiPath.v2.RemoteSupportUser, { data });
  },
  /**
   * Start remote support
   * @returns {Promise}
   */
  async startRemoteSupport() {
    await qFetch.post(apiPath.v2.RemoteSupportEnable);
  },
  /**
   * Stop remote support
   * @returns {Promise}
   */
  async stopRemoteSupport() {
    await qFetch.post(apiPath.v2.RemoteSupportDisable);
  },
};
