import { Firmware } from '@/services';
import { checkFullFwVersionIsOlder } from '@/common/utilities';

const state = {
  localFirmware: {
    type: '',
    version: '',
    buildDate: 0,
    updateTime: 0,
  },
  officialFirmware: {
    version: '',
    isUpgradeAvailable: false,
    changeLogUrl: '',
  },
  // TODO: show beta firmware remote version info in Dashboard
  betaFirmware: {
    version: '',
    isUpgradeAvailable: false,
    changeLogUrl: '',
  },
  scheduleInfo: {
    updateTime: '',
    updateType: '',
    isBackupForced: true,
  },
  isBetaJoined: false,
  changeLogUrl: '',
  currentUpgradeType: '',
  retryBackupCount: 0,
  retryBackupInfo: {
    firmwareVersion: '',
    firmwareMd5: '',
  },
  minimumResetVersion: '',
  minimumRestoreVersion: '',
};

const storeGetters = {
  isBetaUpgradeAvailable(state) {
    return state.betaFirmware.isUpgradeAvailable
      && checkFullFwVersionIsOlder(state.officialFirmware.version, state.betaFirmware.version);
  },
  isAnyFirmwareUpgradeAvailable(state, getters) {
    return state.officialFirmware.isUpgradeAvailable || getters.isBetaUpgradeAvailable;
  },
};

const mutations = {
  setFirmwareInfo(state, info) {
    state.localFirmware = info.localFirmware;
    state.officialFirmware = info.officialFirmware;

    if (info.betaFirmware) {
      state.betaFirmware = info.betaFirmware;
    }

    state.minimumResetVersion = info.minimumResetVersion;
    state.minimumRestoreVersion = info.minimumRestoreVersion;
  },
  setScheduleInfo(state, scheduleInfo) {
    state.scheduleInfo = scheduleInfo;
  },
  setIsBetaJoined(state, isBetaJoined) {
    state.isBetaJoined = isBetaJoined;
  },
  setChangeLogUrl(state, url) {
    state.changeLogUrl = url;
  },
  startFirmwareUpgrade(state, upgradeType) {
    state.currentUpgradeType = upgradeType;
    state.retryBackupCount = 0;
  },
  increaseRetryBackupCount(state) {
    state.retryBackupCount += 1;
  },
  setRetryBackupInfo(state, retryBackupInfo) {
    state.retryBackupInfo = {
      firmwareVersion: retryBackupInfo.version,
      firmwareMd5: retryBackupInfo.fwImgMd5,
    };
  },
};

const actions = {
  async getFirmwareInfo({ commit }) {
    const firmwareInfo = await Firmware.getFirmware();

    commit('setFirmwareInfo', firmwareInfo);
  },
  async getScheduleInfo({ commit }) {
    const scheduleInfo = await Firmware.getSchedule();

    commit('setScheduleInfo', scheduleInfo);
  },
  async getBetaConfig({ commit }) {
    const betaConfig = await Firmware.getBetaConfig();

    commit('setIsBetaJoined', betaConfig.enabled);
  },
  async toggleIsBetaJoined({ state, commit, dispatch }) {
    const newConfig = await Firmware.updateBetaConfig({
      enabled: !state.isBetaJoined,
    });

    commit('setIsBetaJoined', newConfig.enabled);
    await dispatch('getFirmwareInfo');
  },
  async continueUpgrade({ state, commit }, skipBackup) {
    if (!skipBackup) {
      commit('increaseRetryBackupCount');
    }

    await Firmware.continueUpgrade({
      upgradeType: state.currentUpgradeType,
      fwVersion: state.retryBackupInfo.firmwareVersion,
      fwImgMd5: state.retryBackupInfo.firmwareMd5,
      skip: skipBackup,
    });
  },
};

export default {
  namespaced: true,
  state,
  getters: storeGetters,
  mutations,
  actions,
};
