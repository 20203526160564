/* eslint-disable import/prefer-default-export */

/**
 * Handle the password format from GUI to API
 *
 * @param {string} password - Password to format
 * @returns {string} Formatted password
 */
export function handleLocalAccountPasswordRequest(password) {
  return window.btoa(password);
}
