import { cloneDeep } from 'lodash';
import SERVICES, { Firewall } from '@/services';
import FIREWALL_RULE_DESTINATION from '@/enums/FirewallRuleDestination';
import FIREWALL_RULE_SOURCE from '@/enums/FirewallRuleSource';

const state = {
  is_enable: true,
  interface: [],
  rules: [],
  remote_rules: [],
  firewallIp6Enabled: false,
  firewallIp6Rules: [],
};

// getters
const getters = {};

// actions
const actions = {
  async getFirewall({ commit, rootGetters }) {
    if (!rootGetters['Profile/supportFirewall']) {
      return;
    }

    const firewallConfig = await SERVICES.FIREWALL.getFirewall();

    commit('setFirewall', firewallConfig);
  },
  async getFirewallInterfaces({ commit, rootGetters }) {
    let interfaces;

    if (rootGetters['Profile/supportNetworkV3']) {
      const res = await Firewall.getFirewallInterfaces();
      const newInterfaces = [];
      const handleList = (list) => {
        if (!list) {
          return;
        }
        const newList = list.slice()
          .sort((item1, item2) => item1.internalName.localeCompare(item2.internalName));

        newInterfaces.push(...newList);
      };

      handleList(res.result.wan);
      handleList(res.result.lan);
      handleList(res.result.vlan);
      handleList(res.result.bridge);
      interfaces = newInterfaces;
    } else {
      const res = await SERVICES.FIREWALL.getFirewallInterfaces();

      interfaces = (res.result || []).map((item) => ({
        displayInterface: item.display_interface,
        internalName: item.hw_interface,
      }));
    }
    commit('setFirewallInterfaces', interfaces);
  },
  async putFirewallActivate({ commit }, payload) {
    return SERVICES.FIREWALL.putFirewallActivate(payload);
  },
  async postFirewall({ commit }, payload) {
    return SERVICES.FIREWALL.postFirewall(payload);
  },
  async getFirewallIpv6Config({ commit }) {
    const config = await Firewall.getFirewallIpv6Config();

    commit('setFirewallIpv6Config', config);
  },
  async toggleFirewallIp6Enabled({ state, dispatch }) {
    await Firewall.updateFirewallIpv6Config({
      enabled: !state.firewallIp6Enabled,
      rules: state.firewallIp6Rules,
    });
    await dispatch('getFirewallIpv6Config');
  },
  async updateFirewallIpv6Config({ state, dispatch }, rules) {
    await Firewall.updateFirewallIpv6Config({
      enabled: state.firewallIp6Enabled,
      rules,
    });
    await dispatch('getFirewallIpv6Config');
  },
};

// mutations
const mutations = {
  addRule(state, rule) {
    state.rules.unshift(rule);
  },
  setRules(state, rules) {
    state.rules = rules;
  },
  deleteRule(state, payload) {
    state.rules.splice(payload.index, 1);
  },
  editRule(state, payload) {
    state.rules.splice(payload.index, 1, payload.editRule);
  },
  setFirewall(state, result) {
    state.is_enable = result.is_enable;
    state.rules = [];

    if (result.data) {
      const rules = result.data;

      for (let i = 0; i < rules.length; i++) {
        if (!rules[i].src_any) {
          if (rules[i].src_metadata.hw_interface.length !== 0) {
            rules[i].src_type = FIREWALL_RULE_SOURCE.INTERFACE;
          }

          if (rules[i].src_metadata.ip.length !== 0) {
            rules[i].src_type = FIREWALL_RULE_SOURCE.IP;
          }

          if (rules[i].src_metadata.ports
            && rules[i].src_metadata.ports.length !== 0
            && rules[i].src_metadata.ip.length === 0
            && rules[i].src_metadata.hw_interface.length === 0) {
            rules[i].src_type = FIREWALL_RULE_SOURCE.NONE;
          }
        } else {
          rules[i].src_type = FIREWALL_RULE_SOURCE.NONE;
        }

        if (!rules[i].dest_any) {
          if (rules[i].dest_metadata.domain_name.length !== 0) {
            rules[i].dest_type = FIREWALL_RULE_DESTINATION.DOMAIN_NAME;
          }

          if (rules[i].dest_metadata.ip.length !== 0) {
            rules[i].dest_type = FIREWALL_RULE_DESTINATION.IP;
          }

          if (rules[i].dest_metadata.ports
            && rules[i].dest_metadata.ports.length !== 0
            && rules[i].dest_metadata.ip.length === 0
            && rules[i].dest_metadata.domain_name.length === 0) {
            rules[i].dest_type = FIREWALL_RULE_DESTINATION.NONE;
          }
        } else {
          rules[i].dest_type = FIREWALL_RULE_DESTINATION.NONE;
        }

        state.rules.push(rules[i]);
      }

      state.remote_rules = cloneDeep(state.rules);
    }
  },
  setFirewallInterfaces(state, interfaces) {
    state.interface = interfaces.map((iface) => ({
      ...iface,
      value: iface.internalName,
      text: iface.displayInterface,
    }));
  },

  setFirewallIpv6Config(state, { enabled, rules }) {
    state.firewallIp6Enabled = enabled;
    state.firewallIp6Rules = rules;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
