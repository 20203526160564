const state = {
  dirty: false,
  leavingMessage: '',
};

const mutations = {
  setDirty(state, dirty) {
    state.dirty = dirty;
  },
  setLeavingMessage(state, value) {
    state.leavingMessage = value;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
