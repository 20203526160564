<template>
  <v-chart
    :id="chartId"
    :style="chartSize"
    :option="option"
  />
</template>

<script>
import { PieChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from 'echarts/components';
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { cloneDeep } from 'lodash';
import VChart from 'vue-echarts';
import { getPieDataColor } from '@/common/chart';

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

// Best-looking size
const PIE_SIZE = 60;
const outsideRadius = 1.2;

export default {
  name: 'ChartPie',
  components: {
    VChart,
  },
  props: {
    dataset: {
      type: Array,
      required: true,
      validator(dataset) {
        return dataset.every((data) => typeof data === 'object'
          && typeof data.name === 'string'
          && (typeof data.value === 'string' || typeof data.value === 'number'));
      },
    },
    height: {
      type: Number,
      default: 200,
    },
  },
  data() {
    return {
      chartId: `pie_chart_${Date.now()}`,
      option: {
        series: {
          type: 'pie',
          radius: [`${PIE_SIZE}%`, `${PIE_SIZE * outsideRadius}%`],
          center: ['50%', '50%'],
          data: this.formattedDataset,
          labelLine: {
            show: false,
          },
          label: {
            show: false,
          },
        },
      },
      chartSize: {
        width: '100%',
        height: `${this.height}px`,
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const option = cloneDeep(this.option);

      // Update data color must wait for pie chart rendered
      option.series.data = this.dataset.map((data, index) => ({
        ...data,
        itemStyle: {
          color: getPieDataColor(index),
        },
      }));

      // Update pie chart
      // Based on: https://github.com/ecomfe/vue-echarts#props-all-reactive
      this.option = option;
    },
  },
};
</script>
