import { AccessControl } from '@/services';

const state = {
  enableHttp: false,
  accessControl: false,
  editLocalAccountId: 0,
  localAccounts: [],
  qids: [],
};

const actions = {
  async getSetting({ commit }) {
    const res = await AccessControl.getAccessSetting();

    commit('setAccessSetting', res.result);
  },
  async getLocalAccount({ commit }) {
    const res = await AccessControl.getLocalAccount();

    commit('setLocalAccount', res.result);

    return res;
  },
  async putLocalAccount({ commit }, localAccount) {
    await AccessControl.putLocalAccount({
      id: localAccount.id,
      data: {
        user: {
          newPassword: localAccount.new_password,
          newUsername: localAccount.new_username,
          password: localAccount.password,
        },
      },
    });
  },
  async getRemoteAccount({ commit }) {
    const res = await AccessControl.getRemoteAccount();

    commit('setRemoteAccount', res.result);

    return res;
  },

  async deleteQid({ dispatch }) {
    const response = await AccessControl.deleteQid();

    await Promise.all([
      dispatch('getRemoteAccount'),
      dispatch('Initial/getInitStatus', null, { root: true }),
    ]);

    return response;
  },
};

const mutations = {
  setAccessSetting(state, setting) {
    state.enableHttp = setting.enableHttp;
    state.accessControl = setting.accessControl;
  },
  setEditLocalAccountId(state, id) {
    state.editLocalAccountId = id;
  },
  setLocalAccount(state, list) {
    state.localAccounts = list;
  },
  setRemoteAccount(state, payload) {
    if (payload.account !== '') {
      state.qids = [{
        account: payload.account,
        userId: payload.userID,
      }];
    } else {
      state.qids = [];
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
