import { cloneDeep } from 'lodash';
import { Network } from '@/services';
import { isLanIp6Enabled } from '@/common/lan';
import { mergeDataWithKeys } from '@/common/utilities';
import LAN_DEFAULT_GATEWAY from '@/enums/LanDefaultGateway';
import LAN_DHCP_SERVICE_TYPE from '@/enums/LanDhcpServiceType';
import LAN_IP4_TYPE from '@/enums/LanIp4Type';
import LAN_IP6_ADDR_AUTO_CONFIG_TYPE from '@/enums/LanIp6AddrAutoConfigType';
import LAN_IP6_INTERFACE_ID_TYPE from '@/enums/LanIp6InterfaceIdType';
import LAN_IP6_TYPE from '@/enums/LanIp6Type';
import NETWORK_INTERFACE from '@/enums/NetworkInterface';
import PORT_LINK_STATUS from '@/enums/PortLinkStatus';
import PORT_RATE from '@/enums/PortRate';

function createDefaultLan(portName) {
  return {
    enabled: true,

    description: '',
    dhcpService: {
      dnsServers: [],
      serviceType: LAN_DHCP_SERVICE_TYPE.DISABLED,
      endIp: '',
      leaseTime: 86400,
      relayServers: [],
      reservedIps: [],
      startIp: '',
      defaultGatewayType: LAN_DEFAULT_GATEWAY.AUTO,
      defaultGatewayIp: '',
    },
    enableStp: false,
    ip4Address: '',
    ip4Prefix: 24,
    ip4Type: LAN_IP4_TYPE.STATIC,
    ip6AddrAutoConfig: {
      autoConfigType: LAN_IP6_ADDR_AUTO_CONFIG_TYPE.DISABLED,
      dnsServers: [],
      endIp: '',
      startIp: '',
      leaseTime: 86400,
      lifetime: 3600,
    },
    ip6Address: '',
    ip6InterfaceIdType: LAN_IP6_INTERFACE_ID_TYPE.EUI64,
    ip6PdPrefix: '',
    ip6Prefix: 64,
    ip6Type: LAN_IP6_TYPE.DISABLED,
    mtu: 1500,
    ip6Uplink: '',
    label: '',
    name: `LAN${portName}`,
    portName,
    speed: PORT_RATE.AUTO,
  };
}

const storeState = {
  lans: [],
  editedLans: [],

  // unused currently, just for implementing `dispatch('Network/Ports/applyEditingAndPutLater')`
  hasEditedLanPortNames: [],

  lansStatus: [],
  editedConfig: {},
  addedLan: null,
  lanPortConsts: {
    dataKeys: [
      'enabled',
      'label',
      'name',
      'portName',

      'description',
      'enableStp',
      'dhcpService',
      'ip4Address',
      'ip4Prefix',
      'ip4Type',
      'mtu',
      'speed',

      // IPv6
      'ip6Type',
      'ip6Address',
      'ip6InterfaceIdType',
      'ip6PdPrefix',
      'ip6Uplink',
      'ip6Prefix',
      'ip6AddrAutoConfig',
    ],
  },
};

const storeGetters = {
  findLanStatus: (state) => (portName) => {
    const lanStatus = state.lansStatus.find((status) => status.portName === portName);

    // status will not exist if LAN is disabled
    return lanStatus ?? {
      ip6LinkLocalAddress: '',
      status: PORT_LINK_STATUS.DOWN,
      throughputRx: 0,
      throughputTx: 0,
      throughputPktRx: 0,
      throughputPktTx: 0,
    };
  },
  editedLan(state) {
    const editedLan = state.editedLans.find((lan) => lan.portName === state.editedConfig.portName);

    return editedLan ?? state.addedLan;
  },
  editedOriginalLan(state, getters) {
    if (state.hasEditedLanPortNames.includes(state.editedConfig.portName)) {
      return getters.editedLan;
    }

    return state.lans.find((lan) => lan.portName === state.editedConfig.portName)
      || createDefaultLan(state.editedConfig.portName);
  },
  enabledLans(state) {
    return state.lans.filter((item) => item.enabled);
  },
  enabledLansStatus(state) {
    return state.lansStatus.filter((item) => state.lans
      .find((port) => port.portName === item.portName)?.enabled);
  },
  validIp6LansStatus(state, getters) {
    return getters.enabledLansStatus.filter((lan) => isLanIp6Enabled(lan));
  },
};

const mutations = {
  initLans(state, lans) {
    lans.sort((item1, item2) => parseInt(item1.portName, 10) - parseInt(item2.portName, 10));
    state.editedLans = cloneDeep(lans);
    state.lans = lans;
    state.hasEditedLanPortNames = [];
  },
  resetEditedLans(state) {
    state.editedLans = cloneDeep(state.lans);
  },
  setLansStatus(state, payload) {
    state.lansStatus = payload
      .sort((item1, item2) => parseInt(item1.portName, 10) - parseInt(item2.portName, 10));
  },
  initEditedState(state, portName) {
    let editedLan = state.editedLans.find((lan) => lan.portName === portName);

    if (!editedLan) {
      editedLan = createDefaultLan(portName);
      state.addedLan = editedLan;
    }
    state.editedConfig = mergeDataWithKeys(state.lanPortConsts.dataKeys, editedLan, {});
  },
  resetEditedState(state) {
    state.editedConfig = {};
    state.addedLan = null;
  },
  modifyLan(state, { target, editedConfig }) {
    mergeDataWithKeys(state.lanPortConsts.dataKeys, editedConfig, target);
  },
  checkRemoveLan(state, portName) {
    const idx = state.editedLans.findIndex((port) => port.portName === portName);

    if (idx > -1) {
      state.editedLans.splice(idx, 1);
    }
  },
  checkAddedLan(state) {
    if (state.addedLan) {
      const idx = state.editedLans.findIndex((lan) => lan.portName === state.addedLan.portName);

      if (idx > -1) {
        state.editedLans.splice(idx, 1);
      }
      state.editedLans.push(state.addedLan);
    }
  },
  toggleLanEnabled(state, portName) {
    const lan = state.editedLans.find((port) => port.portName === portName);

    if (lan) {
      lan.enabled = !lan.enabled;
    }
  },
  markLanHasEdited(state, portName) {
    if (!state.hasEditedLanPortNames.includes(portName)) {
      state.hasEditedLanPortNames.push(portName);
    }
  },
};

const actions = {
  startEditing({ state, commit, getters }, portName) {
    commit('initEditedState', portName);
    commit('Network/LanConfig/startEditing', {
      interfaceId: portName,
      mode: NETWORK_INTERFACE.LAN,
      editedConfig: state.editedConfig,
      originalLanConfig: getters.editedOriginalLan,
    }, { root: true });
  },
  endEditing({ commit }) {
    commit('resetEditedState');
    commit('Network/LanConfig/endEditing', undefined, { root: true });
  },

  applyEditing({ getters, commit }, editedConfig) {
    const target = getters.editedLan;

    commit('checkAddedLan');
    commit('modifyLan', { target, editedConfig });
  },
  applyEditingAndPutLater({ commit, dispatch }, editedConfig) {
    dispatch('applyEditing', editedConfig);
    commit('markLanHasEdited', editedConfig.portName);
  },

  async putLan({ dispatch }, lanPort) {
    await Network.putLan(lanPort);
    await Promise.all([
      dispatch('Network/Ports/getPorts', null, { root: true }),
      dispatch('Network/Ports/getPortsStatus', null, { root: true }),
    ]);
  },

  async changeLanPortSpeed({ dispatch }, speed) {
    await Network.changeLanPortSpeed(speed);
    await Promise.all([
      dispatch('Network/Ports/getPorts', null, { root: true }),
      dispatch('Network/Ports/getPortsStatus', null, { root: true }),
    ]);
  },
};

export default {
  namespaced: true,
  state: storeState,
  getters: storeGetters,
  mutations,
  actions,
};
