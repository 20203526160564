import { cloneDeep } from 'lodash';
import { Nat } from '@/services';
import { mergeDataWithKeys } from '@/common/utilities';

function createDefaultPortForwardingRule() {
  return {
    allowIps: '',
    description: '',
    enabled: true,
    hostIp: '',
    lanServicePort: '',
    wanInterfaceID: '',
    protocol: '',
    wanServicePort: '',
  };
}

const storeState = {
  portForwardingRules: [],
  editedPortForwardingRules: [],
  editedRuleId: null,
  addMode: false,
  addedPortForwardingRule: null,
  portForwardingConsts: {
    ruleDataKeys: [
      'ruleId',
      'allowIps',
      'description',
      'enabled',
      'hostIp',
      'lanServicePort',
      'wanInterfaceID',
      'protocol',
      'wanServicePort',
    ],
  },
};

const storeGetters = {
  getProtocalText: () => (protocol) => {
    if (protocol === 'all') {
      return 'TCP + UDP';
    }

    return protocol.toUpperCase();
  },
  isAddMode(state) {
    return state.addMode;
  },
  findPortForwardingRule: (state) => (ruleId, srcRules) => {
    srcRules = srcRules || state.portForwardingRules;
    const rule = srcRules.find((item) => item.ruleId === ruleId);

    return rule || null;
  },
  editedPortForwardingRule(state, getters) {
    const editedRule = getters.isAddMode
      ? createDefaultPortForwardingRule()
      : getters.findPortForwardingRule(state.editedRuleId, state.editedPortForwardingRules);

    return editedRule || null;
  },
  editedOriginalPortForwardingRule(state, getters) {
    const originalRule = getters.isAddMode
      ? createDefaultPortForwardingRule()
      : getters.findPortForwardingRule(state.editedRuleId);

    return originalRule || null;
  },
  wanServiceItems(state, getters, rootState) {
    return rootState.ServicePort.customPortList;
  },
  getWanServicePortText: (state, getters) => (protocol, port) => {
    const customPort = getters.wanServiceItems
      .find((item) => item.protocol === protocol && item.port === port);

    if (!customPort) {
      return '';
    }

    return `${customPort.service} (${getters.getProtocalText(protocol)}${port ? ` ${port}` : ''})`;
  },
  isPortForwardingRulesMax(state) {
    return state.portForwardingRules.length >= 32;
  },
};

const mutations = {
  initPortForwardingRules(state, portForwardingRules) {
    state.editedPortForwardingRules = cloneDeep(portForwardingRules);
    state.portForwardingRules = portForwardingRules;
  },
  startEditing(state, ruleId) {
    state.editedRuleId = ruleId;
  },
  endEditing(state) {
    state.editedRuleId = null;
  },
  startAdding(state) {
    state.addMode = true;
  },
  endAdding(state) {
    state.addMode = false;
    state.addedPortForwardingRule = null;
  },
  modifyPortForwardingRule(state, { target, editedConfig }) {
    mergeDataWithKeys(state.portForwardingConsts.ruleDataKeys, editedConfig, target);
  },
  setAddedPortForwardingRule(state, editedConfig) {
    state.addedPortForwardingRule = mergeDataWithKeys(
      state.portForwardingConsts.ruleDataKeys,
      editedConfig,
      {},
    );
  },
};

const actions = {
  async getPortForwardingRules({ commit }) {
    const rulesRes = await Nat.getPortForwarding();

    commit('initPortForwardingRules', rulesRes.result);
  },
  async togglePortForwardingRuleEnabled({ state, getters, dispatch }, ruleId) {
    const targetRule = getters.findPortForwardingRule(ruleId);
    const newRule = mergeDataWithKeys(state.portForwardingConsts.ruleDataKeys, targetRule, {});

    newRule.enabled = !newRule.enabled;
    await dispatch('putPortForwardingRule', newRule);
  },
  async sendEditedPortForwardingRule({ state, getters, dispatch }) {
    if (getters.isAddMode) {
      await dispatch('postPortForwardingRule', state.addedPortForwardingRule);
    } else {
      await dispatch('putPortForwardingRule', getters.editedPortForwardingRule);
    }
  },
  async putPortForwardingRule({ state }, portForwardingRule) {
    const rule = cloneDeep(portForwardingRule);

    delete rule.ruleId;

    await Nat.putPortForwarding(portForwardingRule.ruleId, rule);
  },
  async postPortForwardingRule({ state }, portForwardingRule) {
    await Nat.postPortForwarding(portForwardingRule);
  },
  async deletePortForwardingRule({ state }, ruleId) {
    await Nat.deletePortForwarding(ruleId);
  },
  applyEditing({ getters, commit }, editedConfig) {
    if (getters.isAddMode) {
      commit('setAddedPortForwardingRule', editedConfig);
    } else {
      const target = getters.editedPortForwardingRule;

      commit('modifyPortForwardingRule', { target, editedConfig });
    }
  },
};

export default {
  namespaced: true,
  state: storeState,
  getters: storeGetters,
  mutations,
  actions,
};
