import MODEL from '@/enums/Model';

/**
 * Features used for frontend.
 * Note: It only contains features some models do not support.
 *       Features supported by all models are not listed here.
 */
const DEFAULT_PROFILE = {
  wireless: true,
  apMode: true,
  upnp: true,
  ipv6: true,
  usb: true,
  networkv3: true, // TODO: remove related code since all model support this feature
  iptv: true,
  mesh: true,
  firewall: true,
  customPort: true,
};

export default {
  [MODEL.QHORA]: {
    ...DEFAULT_PROFILE,
    mesh: false,
  },
  [MODEL.QMIRO]: {
    ...DEFAULT_PROFILE,
    iptv: false,
    firewall: false,
    customPort: false,
  },
  [MODEL.QMIROPLUS]: {
    ...DEFAULT_PROFILE,
    usb: false,
    iptv: false,
    firewall: false,
    customPort: false,
  },
  [MODEL.QHORA321]: {
    ...DEFAULT_PROFILE,
    wireless: false,
    apMode: false,
    upnp: false,
    usb: false,
    iptv: false,
    mesh: false,
  },
  [MODEL.QHORA322]: {
    ...DEFAULT_PROFILE,
    wireless: false,
    apMode: false,
    upnp: false,
    iptv: false,
    mesh: false,
  },
};
