import SERVICES, { VpnServer } from '@/services';
import { RESERVED_PORT_SERVICE_NAME } from '@/common/consts';
import { i18n } from '@/lang';

const state = {
  l2tpConfig: null,
  openvpnConfig: null,
  qbeltConfig: null,
  qvpnUsers: [],
  qvpnOnlineUsers: [],
  wireguardConfig: {},
  wireguardUsers: [],
  vpnConsts: {
    maxUser: 20,
    maxActiveUser: 10,
  },
};

const storeGetters = {
  isVpnServerEnabled(state) {
    // vpn client and vpn server (not including wireguard) can not enable at same time
    return state.qbeltConfig.enable || state.l2tpConfig.enable || state.openvpnConfig.enable;
  },
  isVpnUsersEmpty(state) {
    // only qbelt, l2tp, openvpn service should add user account before enable server
    return state.qvpnUsers.length === 0;
  },
  userNameList(state) {
    return state.qvpnUsers.map((user) => user.userName);
  },
  profileNameList(state) {
    return state.wireguardUsers.map((user) => user.name);
  },
  peerPrivKeyList(state) {
    return state.wireguardUsers.map((user) => user.peerPrivKey);
  },
  wireguardActiveUsers(state) {
    return state.wireguardUsers.filter((user) => user.enabled);
  },
  isTotalUserMax(state) {
    return (state.qvpnUsers.length + state.wireguardUsers.length) >= state.vpnConsts.maxUser;
  },
  isActiveUserMax(state, getters) {
    const qvpnCount = state.qvpnUsers.filter((user) => user.enabled).length;

    return (qvpnCount + getters.wireguardActiveUsers.length) >= state.vpnConsts.maxActiveUser;
  },
  allVpnSubnets(state) {
    const handleTunnelIp = (ip) => {
      const ipParts = ip.split('.');

      if (ipParts.length !== 4) {
        return ip;
      }
      const ipPreParts = ipParts.slice(0, 3);

      return [...ipPreParts, '2'].join('.');
    };
    const handleTitle = (title) => i18n.t('ID_QVPN_SERVER', { title: i18n.t(title) });
    const subnets = [];

    if (state.qbeltConfig) {
      subnets.push({
        vpnName: RESERVED_PORT_SERVICE_NAME.QBELT,
        title: handleTitle('ID_VPN_SETTINGS_QBELT_TITLE'),
        ip4Address: handleTunnelIp(state.qbeltConfig.tunnelIp),
        ip4Prefix: 24,
      });
    }

    if (state.l2tpConfig) {
      subnets.push({
        vpnName: RESERVED_PORT_SERVICE_NAME.L2TP,
        title: handleTitle('ID_VPN_SETTINGS_L2TP_TITLE'),
        ip4Address: handleTunnelIp(state.l2tpConfig.tunnelIp),
        ip4Prefix: 24,
      });
    }

    if (state.openvpnConfig) {
      subnets.push({
        vpnName: RESERVED_PORT_SERVICE_NAME.OPENVPN,
        title: handleTitle('ID_VPN_SETTINGS_OPENVPN_TITLE'),
        ip4Address: handleTunnelIp(state.openvpnConfig.tunnelIp),
        ip4Prefix: 24,
      });
    }

    if (state.wireguardConfig.clientIPPool) {
      subnets.push({
        vpnName: RESERVED_PORT_SERVICE_NAME.WIREGUARD,
        title: handleTitle('ID_VPN_SETTINGS_WIREGUARD_TITLE'),
        ip4Address: handleTunnelIp(state.wireguardConfig.clientIPPool),
        ip4Prefix: 24,
      });
    }

    return subnets;
  },
};

const mutations = {
  setL2tpConfig(state, config) {
    state.l2tpConfig = config;
  },
  setOpenvpnConfig(state, config) {
    state.openvpnConfig = config;
  },
  setQbeltConfig(state, config) {
    state.qbeltConfig = config;
  },
  setQvpnUsers(state, users) {
    state.qvpnUsers = users;
  },
  setQvpnOnlineUsers(state, users) {
    state.qvpnOnlineUsers = users;
  },
  setWireguardConfig(state, config) {
    state.wireguardConfig = config;
  },
  setWireguardUsers(state, users) {
    state.wireguardUsers = users;
  },
};

const actions = {
  async fetchL2tpConfig({ commit }, editingConfig) {
    const config = await SERVICES.VPN.getL2tpConfig();

    commit('setL2tpConfig', config);
  },
  async fetchOpenvpnConfig({ commit }) {
    const config = await SERVICES.VPN.getOpenvpnConfig();

    commit('setOpenvpnConfig', config);
  },
  async fetchQbeltConfig({ commit }) {
    const config = await SERVICES.VPN.getQbeltConfig();

    commit('setQbeltConfig', config);
  },
  async updateL2tpConfig({ commit }, editingConfig) {
    const config = await SERVICES.VPN.updateL2tpConfig(editingConfig);

    commit('setL2tpConfig', config);
  },
  async updateOpenvpnConfig({ commit }, editingConfig) {
    const config = await SERVICES.VPN.updateOpenvpnConfig(editingConfig);

    commit('setOpenvpnConfig', config);
  },
  async updateQbeltConfig({ commit }, editingConfig) {
    const config = await SERVICES.VPN.updateQbeltConfig(editingConfig);

    commit('setQbeltConfig', config);
  },
  async fetchOnlineUsers({ commit }) {
    const users = await SERVICES.VPN.getOnlineUsers();

    commit('setQvpnOnlineUsers', users);
  },
  async fetchUsers({ commit }) {
    const users = await SERVICES.VPN.getUsers();

    commit('setQvpnUsers', users);
  },
  async createUser({ commit }, user) {
    const users = await SERVICES.VPN.createUser(user);

    commit('setQvpnUsers', users);
  },
  async updateUser({ commit }, user) {
    const users = await SERVICES.VPN.updateUser(user);

    commit('setQvpnUsers', users);
  },
  async deleteUser({ commit }, userName) {
    const users = await SERVICES.VPN.deleteUser(userName);

    commit('setQvpnUsers', users);
  },
  async getWireguardConfig({ commit }) {
    const res = await VpnServer.getWireguardConfig();

    commit('setWireguardConfig', res.result);
  },
  async updateWireguardConfig({ commit }, payload) {
    const res = await VpnServer.updateWireguardConfig(payload);

    commit('setWireguardConfig', res.result);
  },
  async getWireguardUsers({ commit }) {
    const res = await VpnServer.getWireguardUsers();

    commit('setWireguardUsers', res.result);
  },
  async createWireguardUser({ commit }, payload) {
    const res = await VpnServer.createWireguardUser(payload);

    commit('setWireguardUsers', res.result);
  },
  async deleteWireguardUser({ commit }, payload) {
    const res = await VpnServer.deleteWireguardUser(payload);

    commit('setWireguardUsers', res.result);
  },
  async updateWireguardUser({ commit }, payload) {
    const res = await VpnServer.updateWireguardUser(payload);

    commit('setWireguardUsers', res.result);
  },
};

export default {
  namespaced: true,
  state,
  getters: storeGetters,
  mutations,
  actions,
};
