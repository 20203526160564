import { mapActions, mapMutations } from 'vuex';
import { SET_DISPLAY_LANGUAGE } from '@/store/mutation-types';
import { hasAuthorization } from '@/common/utilities';
import { LANGUAGE_CODE } from '@/lang';

export default {
  computed: {
    supportLangs() {
      return [
        { code: LANGUAGE_CODE.AUTO, text: this.$t('ID_WIFI_AUTO') },
        { code: LANGUAGE_CODE.ENG, text: 'English' },
        { code: LANGUAGE_CODE.SCH, text: '简体中文' },
        { code: LANGUAGE_CODE.TCH, text: '繁體中文' },
        { code: LANGUAGE_CODE.CZE, text: 'Czech' },
        { code: LANGUAGE_CODE.DAN, text: 'Dansk' },
        { code: LANGUAGE_CODE.GER, text: 'Deutsch' },
        { code: LANGUAGE_CODE.SPA, text: 'Español' },
        { code: LANGUAGE_CODE.FRE, text: 'Français' },
        { code: LANGUAGE_CODE.ITA, text: 'Italiano' },
        { code: LANGUAGE_CODE.JPN, text: '日本語' },
        { code: LANGUAGE_CODE.KOR, text: '한글' },
        { code: LANGUAGE_CODE.NOR, text: 'Norsk' },
        { code: LANGUAGE_CODE.POL, text: 'Polski' },
        { code: LANGUAGE_CODE.RUS, text: 'Русский' },
        { code: LANGUAGE_CODE.FIN, text: 'Suomi' },
        { code: LANGUAGE_CODE.SWE, text: 'Svenska' },
        { code: LANGUAGE_CODE.DUT, text: 'Nederlands' },
        { code: LANGUAGE_CODE.TUR, text: 'Turk dili' },
        { code: LANGUAGE_CODE.THA, text: 'ไทย' },
        { code: LANGUAGE_CODE.POR, text: 'Português' },
        { code: LANGUAGE_CODE.HUN, text: 'Magyar' },
        { code: LANGUAGE_CODE.GRK, text: 'Ελληνικά' },
        { code: LANGUAGE_CODE.ROM, text: 'Română' },
      ];
    },
  },
  methods: {
    ...mapActions('Initial', ['patchLanguage', 'getInitStatus']),
    ...mapMutations('Initial', [SET_DISPLAY_LANGUAGE]),

    async changeLang(lang) {
      if (lang === '') {
        lang = LANGUAGE_CODE.ENG;
      }
      let locale = '';

      if (this.$store.state.Initial.machineInfo.language !== lang && hasAuthorization()) {
        await this.patchLanguage({ language: lang });
        await this.getInitStatus();
      } else {
        // change UI display
        this[SET_DISPLAY_LANGUAGE]({ lang });
      }

      if (lang === LANGUAGE_CODE.AUTO) {
        locale = this.browserSelectLanguage();
      } else {
        locale = lang;
      }

      if (this.$i18n.locale !== locale && locale.length !== 0) {
        if (!Object.prototype.hasOwnProperty.call(this.$i18n.messages, locale)) {
          try {
            const res = await import(
              /*
                webpackExclude: /\.ENG\.json$/,
                webpackChunkName: "locale-datas",
                webpackMode: "lazy-once"
              */
              `@/lang/locales/${locale}.json`
            );

            this.$i18n.setLocaleMessage(locale, res.default);
          } catch (err) {
            window.location.reload();
          }
        }

        this.$i18n.locale = locale;
      }
    },
    browserSelectLanguage() {
      const browLang = navigator.language.toLowerCase();
      let resLang;

      switch (browLang) {
        case 'zh-tw':
        case 'zh-hk':
          resLang = LANGUAGE_CODE.TCH;
          break;
        case 'zh-cn':
        case 'zh-sg':
          resLang = LANGUAGE_CODE.SCH;
          break;
        case 'en':
        case 'en-us':
        case 'en-gb':
        case 'en-au':
        case 'en-ca':
        case 'en-nz':
        case 'en-ie':
        case 'en-za':
        case 'en-jm':
        case 'en-bz':
        case 'en-tt':
          resLang = LANGUAGE_CODE.ENG;
          break;
        case 'cs':
          resLang = LANGUAGE_CODE.CZE;
          break;
        case 'da':
          resLang = LANGUAGE_CODE.DAN;
          break;
        case 'de':
        case 'de-ch':
        case 'de-at':
        case 'de-lu':
        case 'de-li':
          resLang = LANGUAGE_CODE.GER;
          break;
        case 'es':
        case 'es-ar':
        case 'es-gt':
        case 'es-cr':
        case 'es-pa':
        case 'es-do':
        case 'es-mx':
        case 'es-ve':
        case 'es-co':
        case 'es-pe':
        case 'es-ec':
        case 'es-cl':
        case 'es-uy':
        case 'es-py':
        case 'es-bo':
        case 'es-sv':
        case 'es-hn':
        case 'es-ni':
        case 'es-pr':
          resLang = LANGUAGE_CODE.SPA;
          break;
        case 'fr':
        case 'fr-be':
        case 'fr-ca':
        case 'fr-ch':
        case 'fr-lu':
          resLang = LANGUAGE_CODE.FRE;
          break;
        case 'it':
        case 'it-ch':
          resLang = LANGUAGE_CODE.ITA;
          break;
        case 'ja':
          resLang = LANGUAGE_CODE.JPN;
          break;
        case 'ko':
          resLang = LANGUAGE_CODE.KOR;
          break;
        case 'no':
          resLang = LANGUAGE_CODE.NOR;
          break;
        case 'pl':
          resLang = LANGUAGE_CODE.POL;
          break;
        case 'ru':
        case 'ru-mo':
          resLang = LANGUAGE_CODE.RUS;
          break;
        case 'fi':
          resLang = LANGUAGE_CODE.FIN;
          break;
        case 'sv':
        case 'sv-fi':
          resLang = LANGUAGE_CODE.SWE;
          break;
        case 'nl':
        case 'nl-be':
          resLang = LANGUAGE_CODE.DUT;
          break;
        case 'tr':
          resLang = LANGUAGE_CODE.TUR;
          break;
        case 'th':
          resLang = LANGUAGE_CODE.THA;
          break;
        case 'pt-br':
        case 'pt':
          resLang = LANGUAGE_CODE.POR;
          break;
        case 'hu':
          resLang = LANGUAGE_CODE.HUN;
          break;
        case 'el':
          resLang = LANGUAGE_CODE.GRK;
          break;
        case 'ro':
        case 'ro-mo':
          resLang = LANGUAGE_CODE.ROM;
          break;
        default:
          resLang = LANGUAGE_CODE.ENG;
      }

      return resLang;
    },
  },
};
