
<transition
  name="fade"
  appear
>
  <div
    v-if="visible"
    :key="wrapperKey"
    class="q-loading-wrapper"
    :class="{ 'q-loading-wrapper-hide-backdrop': !showBackdrop }"
  >
    <div class="q-loading-container">
      <div
        class="q-loading-content"
        :class="{ 'q-loading-cancelable': !!cancel }"
      >
        <div
          class="q-loading-spinner-wrapper"
          :class="{ 'has-detail': !!detail }"
        >
          <LoadingSpinner />
        </div>
        <div
          style="min-height: 30px"
          class="df fd-column jc-center"
        >
          <div class="fz-17">
            {{ $t(text) }}
          </div>
          <div
            v-if="detail"
            class="fz-14 q-loading-detail"
          >
            {{ $t(detail) }}
          </div>
        </div>
        <div
          v-if="!!cancel"
          class="pl-25-px"
        >
          <ButtonText
            label="ID_CANCEL"
            class="q-loading-cancel-button"
            @click="handleCancel"
          />
        </div>
      </div>
    </div>
  </div>
</transition>
