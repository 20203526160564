import { cloneDeep } from 'lodash';
import { Network } from '@/services';
import { handleLanRequest } from '@/services/utilities/lan';
import { handleWanRequest, handleWanResponse } from '@/services/utilities/wan';
import { IPTV_INTERFACE_NAME_DISPLAY } from '@/common/consts';
import { mergeDataWithKeys } from '@/common/utilities';
import IPTV_LABEL from '@/enums/IptvLabel';
import LAN_DEFAULT_GATEWAY from '@/enums/LanDefaultGateway';
import LAN_DHCP_SERVICE_TYPE from '@/enums/LanDhcpServiceType';
import LAN_IP4_TYPE from '@/enums/LanIp4Type';
import LAN_IP6_ADDR_AUTO_CONFIG_TYPE from '@/enums/LanIp6AddrAutoConfigType';
import LAN_IP6_INTERFACE_ID_TYPE from '@/enums/LanIp6InterfaceIdType';
import LAN_IP6_TYPE from '@/enums/LanIp6Type';
import NETWORK_INTERFACE from '@/enums/NetworkInterface';
import VLAN_IFACE_TYPE from '@/enums/VlanIfaceType';
import WAN_ADV_IP6_REGION from '@/enums/WanAdvIp6Region';
import WAN_IP4_TYPE from '@/enums/WanIp4Type';
import WAN_IP6_TYPE from '@/enums/WanIp6Type';

const storeState = {
  vlanIfaces: [],
  guestWirelessVlanIfaceInterfaceName: '',
  editedVlanIfaces: [],
  vlanIfacesStatus: [],
  editedConfig: {},
  addedVlanIface: null,
  vlanIfaceConsts: {
    dataKeys: [
      'description',
      'enabled',
      'labels',
      'lan',
      'name',
      'priority',
      'tags',
      'type',
      'untags',
      'vlanId',
      'vlanIfId',
      'wan',
      'lineRateRx',
      'lineRateTx',
    ],
  },
};

function createDefaultVlanIfaceWan() {
  return {
    advIp6Region: WAN_ADV_IP6_REGION.GLOBAL,
    dialOnDemand: false,
    dslite: {
      aftrAddress: '',
      b4Address: '',
    },
    ip4Address: '',
    ip4DnsServers: [],
    ip4Gateway: '',
    ip4Prefix: 24,
    ip4Type: WAN_IP4_TYPE.DHCP,
    ip6Address: '',
    ip6DnsServers: [],
    ip6Gateway: '',
    ip6PdEnabled: true,
    ip6Prefix: 64,
    ip6Type: WAN_IP6_TYPE.DISABLED,
    mtu: 1500,
    password: '',
    username: '',
  };
}

function createDefaultVlanIfaceLan() {
  return {
    dhcpService: {
      dnsServers: [],
      endIp: '',
      leaseTime: 86400,
      relayServers: [],
      reservedIps: [],
      serviceType: LAN_DHCP_SERVICE_TYPE.DISABLED,
      startIp: '',
      defaultGatewayType: LAN_DEFAULT_GATEWAY.AUTO,
      defaultGatewayIp: '',
    },
    enableStp: true,
    ip4Address: '',
    ip4Prefix: 24,
    ip4Type: LAN_IP4_TYPE.STATIC,
    ip6AddrAutoConfig: {
      autoConfigType: LAN_IP6_ADDR_AUTO_CONFIG_TYPE.DISABLED,
      dnsServers: [],
      endIp: '',
      leaseTime: 86400,
      lifetime: 3600,
      startIp: '',
    },
    ip6Address: '',
    ip6InterfaceIdType: LAN_IP6_INTERFACE_ID_TYPE.EUI64,
    ip6PdPrefix: '',
    ip6Prefix: 64,
    ip6Type: LAN_IP6_TYPE.DISABLED,
    ip6Uplink: '',
    mtu: 1500,
  };
}

function createDefaultVlanIface() {
  return {
    description: '',
    enabled: true,
    labels: [],
    lan: createDefaultVlanIfaceLan(),
    name: '',
    priority: 0,
    tags: [],
    type: VLAN_IFACE_TYPE.LAN,
    untags: [],
    vlanId: null,
    vlanIfId: '',
    wan: createDefaultVlanIfaceWan(),
    lineRateRx: 0,
    lineRateTx: 1000000000,
  };
}

const storeGetters = {
  isAddMode(state) {
    return state.addedVlanIface !== null;
  },
  editedVlanIface(state, getters) {
    if (getters.isAddMode) {
      return state.addedVlanIface;
    }

    return state.editedVlanIfaces
      .find((vlanIface) => vlanIface.vlanIfId === state.editedConfig.vlanIfId) ?? null;
  },
  editedOriginalVlanIface(state, getters) {
    if (getters.isAddMode) {
      return createDefaultVlanIface();
    }

    return state.vlanIfaces
      .find((vlanIface) => vlanIface.vlanIfId === state.editedConfig.vlanIfId) ?? null;
  },
  isEditingGuestWirelessVlanIface(state, getters) {
    return getters.isGuestWirelessVlanIface(getters.editedOriginalVlanIface);
  },
  isEditingIptvVlanIface(state, getters) {
    const labels = getters.editedVlanIface?.labels ?? [];

    return labels.includes(IPTV_LABEL.IPTV)
      || labels.includes(IPTV_LABEL.VOIP);
  },
  findVlanIfaceStatus: (state) => (vlanIfId) => {
    const vlanIfaceStatus = state.vlanIfacesStatus.find((status) => status.vlanIfId === vlanIfId);

    return vlanIfaceStatus ?? { wan: {} };
  },
  getVlanIfaceDisplayText: (state) => (vlanIfId) => {
    const vlanIface = state.vlanIfaces.find((item) => item.vlanIfId === vlanIfId);

    return vlanIface?.name ?? '';
  },
  enabledVlanIfaces(state) {
    return state.vlanIfaces.filter((item) => item.enabled);
  },
  enabledVlanIfacesStatus(state) {
    return state.vlanIfacesStatus.filter((item) => state.vlanIfaces
      .find((vlanIface) => vlanIface.vlanIfId === item.vlanIfId)?.enabled);
  },
  validWanVlanIfaces(state) {
    return state.vlanIfaces
      .filter((vlanIface) => vlanIface.type === VLAN_IFACE_TYPE.WAN
        && (vlanIface.tags.length + vlanIface.untags.length) > 0);
  },
  handleVlanIfaceRequest: (state, getters, rootState, rootGetters) => (config) => {
    config = cloneDeep(config);
    delete config.vlanIfId;

    if (config.type === VLAN_IFACE_TYPE.WAN) {
      delete config.lan;
      config.wan = handleWanRequest(config.wan);
    } else if (config.type === VLAN_IFACE_TYPE.LAN) {
      delete config.wan;
      config.lan = handleLanRequest(config.lan, config.enabled);
    }
    config.name = rootGetters['Network/getInterfaceOriginalName'](config.name);

    return config;
  },

  editedConfigInvalidMessage(state, getters, rootState, rootGetters) {
    const config = state.editedConfig;

    if (config.vlanIfId === undefined) {
      return '';
    }

    if (config.type === VLAN_IFACE_TYPE.LAN) {
      const isLast = rootGetters['Network/checkLanInterfaceNotLast'](config.vlanIfId) !== true;

      if (isLast && config.tags.length + config.untags.length === 0) {
        return 'ID_LAN_INTERFACE_AT_LEAST_ONE_MSG';
      }
    }

    return '';
  },

  defaultVlanId(state, getters, rootState, rootGetters) {
    const currentNetworkProfile = rootGetters['Network/currentNetworkProfile'];

    if (!currentNetworkProfile) {
      return -1;
    }
    const idx = currentNetworkProfile.reservedVlanIdsDesc.indexOf('default');

    return currentNetworkProfile.reservedVlanIds[idx];
  },
  guestWirelessVlanId(state, getters, rootState, rootGetters) {
    const currentNetworkProfile = rootGetters['Network/currentNetworkProfile'];

    if (!currentNetworkProfile) {
      return -1;
    }
    const idx = currentNetworkProfile.reservedVlanIdsDesc.indexOf('guest_wifi');

    return currentNetworkProfile.reservedVlanIds[idx] ?? -1;
  },
  isGuestWirelessVlanIface: (state, getters) => (vlanIface) => {
    const vlanId = getters.guestWirelessVlanId;

    return vlanIface.vlanId === vlanId;
  },
};

const mutations = {
  modifyEditedConfig(state, { key, value, target }) {
    target = target || state.editedConfig;
    target[key] = value;
  },
  initEditedState(state, vlanIfId) {
    const editedVlanIface = state.editedVlanIfaces
      .find((vlanIface) => vlanIface.vlanIfId === vlanIfId);

    state.editedConfig = mergeDataWithKeys(state.vlanIfaceConsts.dataKeys, editedVlanIface, {});
  },
  resetEditedState(state) {
    state.editedConfig = {};
    state.addedVlanIface = null;
  },
  initAddVlanIface(state) {
    state.editedConfig = createDefaultVlanIface();
    state.addedVlanIface = createDefaultVlanIface();
  },
  modifyVlanIface(state, { target, editedConfig }) {
    mergeDataWithKeys(state.vlanIfaceConsts.dataKeys, editedConfig, target);
  },

  initVlanIfaces(state, vlanIfaces) {
    vlanIfaces.forEach((vlanIface) => {
      if (!vlanIface.wan) {
        vlanIface.wan = createDefaultVlanIfaceWan();
      }
      vlanIface.wan = handleWanResponse(vlanIface.wan);

      if (!vlanIface.lan) {
        vlanIface.lan = createDefaultVlanIfaceLan();
      }

      vlanIface.tags.sort((item1, item2) => parseInt(item1, 10) - parseInt(item2, 10));
      vlanIface.untags.sort((item1, item2) => parseInt(item1, 10) - parseInt(item2, 10));

      if (vlanIface.labels.includes(IPTV_LABEL.IPTV)
        || vlanIface.labels.includes(IPTV_LABEL.VOIP)) {
        if (vlanIface.labels.includes(IPTV_LABEL.INTERNET)) {
          vlanIface.name += IPTV_INTERFACE_NAME_DISPLAY.INTERNET;
        } else if (vlanIface.labels.includes(IPTV_LABEL.IPTV)) {
          vlanIface.name += IPTV_INTERFACE_NAME_DISPLAY.IPTV;
        } else if (vlanIface.labels.includes(IPTV_LABEL.VOIP)) {
          vlanIface.name += IPTV_INTERFACE_NAME_DISPLAY.VOIP;
        }
      }
    });
    vlanIfaces.sort((item1, item2) => item1.vlanId - item2.vlanId);
    state.editedVlanIfaces = cloneDeep(vlanIfaces);
    state.vlanIfaces = vlanIfaces;
  },
  resetEditedVlanIfaces(state) {
    state.editedVlanIfaces = cloneDeep(state.vlanIfaces);
  },
  setVlanIfacesStatus(state, vlanIfacesStatus) {
    state.vlanIfacesStatus = vlanIfacesStatus
      .sort((item1, item2) => item1.vlanId - item2.vlanId);
  },

  setGuestWirelessVlanIfaceInterfaceName(state, value) {
    state.guestWirelessVlanIfaceInterfaceName = value;
  },
};

const actions = {
  initEditStore({ state, getters, commit }) {
    const vlanIfId = state.editedConfig.vlanIfId || null;

    commit('Network/WanConfig/startEditing', {
      interfaceId: vlanIfId,
      mode: NETWORK_INTERFACE.VLAN_IFACE,
      editedConfig: state.editedConfig.wan,
      originalWanConfig: getters.editedOriginalVlanIface.wan,
    }, { root: true });
    commit('Network/LanConfig/startEditing', {
      interfaceId: vlanIfId,
      mode: NETWORK_INTERFACE.VLAN_IFACE,
      editedConfig: state.editedConfig.lan,
      originalLanConfig: getters.editedOriginalVlanIface.lan,
    }, { root: true });
  },
  startEditing({ commit, dispatch }, vlanIfId) {
    commit('initEditedState', vlanIfId);
    dispatch('initEditStore');
  },
  endEditing({ commit }) {
    commit('Network/WanConfig/endEditing', undefined, { root: true });
    commit('Network/LanConfig/endEditing', undefined, { root: true });
    commit('resetEditedState');
  },
  startAdding({ commit, dispatch }) {
    commit('initAddVlanIface');
    dispatch('initEditStore');
  },
  applyEditing({ state, getters, commit }, editedConfig) {
    const target = getters.isAddMode ? state.addedVlanIface : getters.editedVlanIface;

    commit('modifyVlanIface', { target, editedConfig });
  },

  async getVlanIfaces({ commit, getters }) {
    const vlans = await Network.getVlanIfaces();

    vlans.forEach((vlanIface) => {
      if (getters.isGuestWirelessVlanIface(vlanIface)) {
        commit('setGuestWirelessVlanIfaceInterfaceName', vlanIface.name);
        vlanIface.name = 'Guest Wireless Network';
      }
    });
    commit('initVlanIfaces', vlans);
  },
  async getVlanIfacesStatus({ commit }) {
    const res = await Network.getVlanIfacesStatus();

    commit('setVlanIfacesStatus', res.result);
  },
  async putVlanIface({ getters }, vlanIfaceConfig) {
    const config = getters.handleVlanIfaceRequest(vlanIfaceConfig);

    try {
      await Network.putVlanIface(vlanIfaceConfig.vlanIfId, config);
    } catch (error) {
      // Ignore "faild to fetch"
      if (error.error_code !== 90000) {
        throw error;
      }
    }
  },
  async postVlanIface({ getters }, vlanIfaceConfig) {
    const config = getters.handleVlanIfaceRequest(vlanIfaceConfig);

    await Network.postVlanIface(config);
  },
  /**
   * Add or edit VLAN interface
   * @param {Object} context - store context
   */
  async sendEditedVlanIface({ state, getters, dispatch }) {
    if (getters.isAddMode) {
      await dispatch('postVlanIface', state.addedVlanIface);
    } else {
      await dispatch('putVlanIface', getters.editedVlanIface);
    }
  },
  async removeVlanIfaceInterface({ state, dispatch }, { vlanIfId, interfaceId }) {
    const vlanIface = state.vlanIfaces.find((item) => item.vlanIfId === vlanIfId);

    if (vlanIface) {
      const newVlanIface = cloneDeep(vlanIface);
      const check = (portList) => {
        const idx = portList.indexOf(interfaceId);

        if (idx > -1) {
          portList.splice(idx, 1);

          return true;
        }

        return false;
      };

      if (!check(newVlanIface.tags)) {
        check(newVlanIface.untags);
      }
      await dispatch('putVlanIface', newVlanIface);
    }
  },
  async postRemoveVlanIfacesPort({ state }, { portName, vlanIfIds }) {
    await Network.postRemoveVlanIfacesPort(portName, vlanIfIds);
  },
};

export default {
  namespaced: true,
  state: storeState,
  getters: storeGetters,
  mutations,
  actions,
};
