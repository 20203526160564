import { mergeDataWithKeys } from '@/common/utilities';

const storeState = {
  mode: '',
  interfaceId: null,
  originalLanConfig: null,
  editedConfig: {},
  lanConsts: {
    dataKeys: [
      'enableStp',
      'dhcpService',
      'ip4Address',
      'ip4Prefix',
      'ip4Type',
      'mtu',

      // IPv6
      'ip6Type',
      'ip6Address',
      'ip6InterfaceIdType',
      'ip6PdPrefix',
      'ip6Uplink',
      'ip6Prefix',
      'ip6AddrAutoConfig',
    ],
  },
};

const mutations = {
  startEditing(state, {
    interfaceId, mode, editedConfig, originalLanConfig,
  }) {
    state.interfaceId = interfaceId;
    state.mode = mode;
    state.originalLanConfig = originalLanConfig;
    state.editedConfig = editedConfig;
  },
  endEditing(state) {
    state.interfaceId = null;
    state.mode = '';
    state.originalLanConfig = null;
    state.editedConfig = {};
  },
  modifyEditedConfig(state, { key, value, target }) {
    target = target || state.editedConfig;
    target[key] = value;
  },
  modifyLanConfig(state, { target, editedConfig }) {
    mergeDataWithKeys(state.lanConsts.dataKeys, editedConfig, target);
  },
};

export default {
  namespaced: true,
  state: storeState,
  mutations,
};
