<template>
  <button
    class="q-button"
    @click="$emit('click', $event)"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'ButtonBase',
};
</script>
