const WAN_IP4_TYPE = {
  DHCP: 'dhcp',
  PPPOE: 'pppoe',
  STATIC: 'static',
  DSLITE: 'dslite',
  MAPE: 'mape',
  DISABLED: 'disabled',
};

export default WAN_IP4_TYPE;
