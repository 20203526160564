<template>
  <transition
    name="fade"
    appear
  >
    <div
      v-if="innerVisible"
      :id="id"
      class="q-modal-wrapper"
      :class="{ 'q-modal-wrapper-resizing': resizing }"
      @mousemove="wrapperOnMouseMove"
      @mouseup="endResizing"
    >
      <div
        class="q-modal-container"
        :class="containerClassList"
        :style="containerStyle"
      >
        <div
          class="q-modal-content"
          :class="`q-modal-theme-${theme}`"
          :style="contentStyle"
        >
          <header
            v-if="!noTitle"
            class="q-modal-header"
            :class="headerClassList"
          >
            <div>{{ $t(title) }}</div>
            <ButtonIcon
              v-if="!force"
              class="q-modal-button-close"
              icon="ic_close"
              label="ID_CLOSE"
              @click="closeModal"
            />
          </header>
          <div
            v-if="$slots.default"
            class="q-modal-body"
          >
            <slot name="modal-body-before" />
            <div :class="customBodyClass || 'ptb-20-px plr-15-px'">
              <slot />
            </div>
            <ButtonIcon
              v-if="noTitle && !force"
              class="q-modal-button-close inner-body"
              icon="ic_close"
              label="ID_CLOSE"
              @click="closeModal"
            />
          </div>
          <slot
            name="modal-footer-wrapper"
            :cancel="closeModal"
          >
            <footer
              v-if="!hideFooter"
              class="q-modal-footer"
            >
              <div class="df ai-center jc-end">
                <slot
                  name="modal-footer"
                  :cancel="closeModal"
                >
                  <slot name="modal-footer-start" />
                  <ButtonText
                    :label="submitLabel"
                    :disabled="submitDisabled"
                    @click="submit"
                  />
                  <ButtonText
                    v-if="showCancelButton && !force"
                    label="ID_CANCEL"
                    theme="secondary"
                    @click="closeModal"
                  />
                </slot>
              </div>
            </footer>
          </slot>
        </div>
        <div
          class="q-modal-container-left"
          @mousedown="startResizing($event, -1)"
        />
        <div
          class="q-modal-container-right"
          @mousedown="startResizing($event, 1)"
        />
      </div>
    </div>
  </transition>
</template>

<script>
import { debounce } from 'lodash';
import { mapGetters } from 'vuex';
import { convertPixelsToRem } from '@/common/utilities';
import ButtonIcon from '@/components/Button/ButtonIcon.vue';
import ButtonText from '@/components/Button/ButtonText.vue';

const MODAL_WRAPPER_SIZE_DEFAULT_WIDTH_MAPPING = {
  sm: 360,
  md: 500,
  lg: 800,
  alert: 450,
};

/**
 * State cycle and Events of <ModalBase>:
 *
 * -- (1) ------------------------------------
 *  hide -> show                 @show
 *       -> DOM updated          @after-show
 *       -> (modal is visible)
 *
 * -------------------------------------------
 *  (1)  -> user close modal     @cancel
 *       -> hide                 @hide
 *       -> DOM updated          @after-hide
 *
 * -------------------------------------------
 *  (1)  -> $modal.hide(id)
 *       -> hide                 @hide
 *       -> DOM updated          @after-hide
 *
 *  > @cancel will not emit if using `$modal.hide` to close modal
 *
 * -------------------------------------------
 *  (1)  -> component destroyed  @after-hide
 *
 *  > @hide and @cancel will not emit if component is destroyed directly.
      (ex: user click link in modal to leave page)
 *
 * -------------------------------------------
 * > note:
 * > @cancel and @submit only work if no use custom footer,
 *   but can access `cancel` method (will call @cancel) via slot scope of custom footer.
 *   (custom footer: `v-slot:modal-footer` or `v-slot:modal-footer-wrapper`)
 */

export default {
  name: 'ModalBase',
  components: {
    ButtonIcon,
    ButtonText,
  },
  props: {
    /**
     * The priority is higher than `props.id`.
     * If use `props.visible`, @cancel is used to close the window so must be given
     */
    visible: {
      type: Boolean,
      default: false,
    },

    // If `props.visible` is not given, `props.id` must be given.
    id: {
      type: String,
    },

    // The header will hide if `props.title` is not given
    title: {
      type: String,
      default: '',
    },

    // If `props.force` is `true`, the user only can submit and can not close modal.
    force: {
      type: Boolean,
      default: false,
    },

    size: {
      type: String,
      default: 'lg',
      validator(value) {
        return ['sm', 'md', 'lg', 'alert'].includes(value);
      },
    },
    height: {
      type: String,
      default: null,
    },

    // Custom modal width
    width: {
      type: String,
      default: null,
    },
    headerTheme: {
      type: String,
      default: 'primary',
      validator(value) {
        return ['primary', 'white'].includes(value);
      },
    },
    theme: {
      type: String,
      default: 'primary',
      validator(value) {
        return ['primary', 'information'].includes(value);
      },
    },

    customBodyClass: {
      type: String,
      default: null,
    },

    /**
     * Only work if no use custom footer:
     * - submitDisabled
     * - submitLabel
     * - showCancelButton
     */
    submitDisabled: {
      type: Boolean,
      default: false,
    },
    submitLabel: {
      type: String,
      default: 'ID_OK',
    },
    showCancelButton: {
      type: Boolean,
      default: false,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hasInit: false,
      hidden: true,

      /**
       * `size` prop is expected to be a constant.
       * So it will not watch `size` to update `modalWidth`.
       */
      modalMinWidth: 0,
      modalWidth: 0,
      resizing: false,
      direction: 1,
      mouseLastX: 0,
    };
  },
  computed: {
    ...mapGetters('Plugin/Modal', ['checkVisible']),
    innerVisible() {
      /**
       * Note: Modal invoked in the watch hook would contain the modal ID before the component
       * is initialized. Use hasInit to ensure the innerVisible be true when the modal is opened.
       */
      return this.hasInit && (this.visible || this.checkVisible(this.id));
    },
    containerClassList() {
      return {
        [`q-modal-size-${this.size}`]: true,
      };
    },
    headerClassList() {
      return {
        [`q-modal-header-bg-${this.headerTheme}`]: true,
      };
    },
    noTitle() {
      return !this.title;
    },
    containerStyle() {
      const modalWidthWithRem = convertPixelsToRem(Math.floor(this.modalWidth));

      return {
        'max-width': `${modalWidthWithRem}rem`,
      };
    },
    contentStyle() {
      const heightWithRem = convertPixelsToRem(Math.floor(Number(this.height)));

      return {
        height: heightWithRem ? `${heightWithRem}rem` : 'auto',
      };
    },
  },
  watch: {
    innerVisible(value) {
      if (value) {
        this.$emit('show');
        this.$nextTick(() => {
          this.$emit('after-show');
          this.hidden = false;
        });
      } else {
        this.$emit('hide');
        this.$nextTick(() => {
          this.afterHide();
        });
      }
    },
    id(newValue, oldValue) {
      if (this.innerVisible && typeof newValue === 'string') {
        this.$store.commit('Plugin/Modal/show', newValue);
      }
      this.$store.commit('Plugin/Modal/hide', oldValue);
    },
  },
  created() {
    const widthNumber = this.width && Number(this.width);
    const modalWidth = widthNumber && widthNumber > 0
      ? widthNumber
      : MODAL_WRAPPER_SIZE_DEFAULT_WIDTH_MAPPING[this.size];

    this.modalMinWidth = modalWidth;
    this.modalWidth = modalWidth;
    this.hasInit = true;
  },
  destroyed() {
    if (!this.hidden) {
      this.afterHide();
    }

    if (typeof this.id === 'string') {
      this.$modal.hide(this.id);
    }
  },
  methods: {
    closeModal() {
      if (typeof this.id === 'string') {
        this.$modal.hide(this.id);
      }
      this.$emit('cancel');
    },
    submit() {
      this.$emit('submit');

      if (!this.showCancelButton) {
        this.closeModal();
      }
    },
    afterHide() {
      this.$emit('after-hide');
      this.hidden = true;
    },
    /**
     * Start resizing.
     * @param {MouseEvent} evt - mousedown event object
     * @param {number} direction - direction of dragging
     */
    startResizing(evt, direction) {
      evt.preventDefault();
      this.mouseLastX = evt.clientX;
      this.resizing = true;
      this.direction = direction;
    },
    endResizing() {
      this.resizing = false;
    },
    wrapperOnMouseMove: debounce(function (evt) {
      if (this.resizing) {
        const offset = evt.clientX - this.mouseLastX;
        const newWidth = this.modalWidth + (offset * 2 * this.direction);

        this.modalWidth = Math.max(newWidth, this.modalMinWidth);
        this.mouseLastX = evt.clientX;
      }
    }, 10),
  },
};
</script>

<style lang="scss" scoped>
.q-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: color("black", 0.75);

  &.q-modal-wrapper-resizing {
    cursor: ew-resize;
  }
}

.q-modal-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: calc(100% - rem(40px));
  margin: rem(20px);
  transition: 0.15s ease;

  & > .q-modal-container-left, & > .q-modal-container-right {
    position: absolute;
    top: 0;
    z-index: 1;
    width: 1rem;
    height: 100%;
    cursor: ew-resize;
  }
  & > .q-modal-container-left {
    left: -0.75rem;
  }
  & > .q-modal-container-right {
    right: -0.75rem;
  }
}

.q-modal-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;

  // To force the children to follow the content's curve corner
  // Based on: https://stackoverflow.com/a/3724210
  overflow: hidden;
  background-color: color("white");
  border-radius: rem(5px);;
}

.q-modal-header {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: rem(14px 30px);
  font-size: rem(20px);
  line-height: rem(24px);

  &.q-modal-header-bg-primary {
    color: color("white");
    background-color: theme-color("primary");
  }
  &.q-modal-header-bg-white {
    padding-bottom: 0;
    color: color("black");
    background-color: color("white");
  }
}

.q-modal-body {
  position: relative;
  flex: 1 1 auto;
  overflow-y: auto;
  overscroll-behavior: contain;
}

.q-modal-button-close {
  position: absolute;
  top: rem(5px);
  right: rem(5px);

  &.inner-body {
    top: rem(3px);
    right: rem(3px);
  }
}

.q-modal-footer {
  padding: rem(15px 30px 25px);
  background-color: color("white");

  & ::v-deep button + button {
    margin-left: rem(10px);
  }
}

.q-modal-header, .q-modal-footer {
  flex-shrink: 0;
}

.q-modal-theme-information {
  .q-modal-header {
    padding-top: rem(20px);
    background-color: modal-information-color('header');
  }
  .q-modal-body {
    background-color: modal-information-color('content');
  }
}

// ensure css priority greater than global css of page-tabs
.q-modal-container > .q-modal-content > .q-modal-body {
  & ::v-deep .nav.nav-tabs {
    padding-left: rem(15px);
    background-color: transparent;
    border-bottom: 1px solid theme-color("dark-border", 0.25);
  }
  & ::v-deep .nav-tabs .nav-link {
    min-width: rem(80px);
    padding: rem(5px) rem(15px);
    color: color("black");
    text-align: center;
    border-width: 0;
    border-bottom-width: rem(4px);
    &.active {
      background-color: transparent;
      border-color: theme-color("primary");
    }
  }
  & ::v-deep .nav-item {
    padding-right: rem(5px);
  }
  & ::v-deep .tab-content {
    padding: rem(5px 0);
    margin: 0;
  }
}

.fade-enter, .fade-leave-to {
  opacity: 0;
  transform: translateY(-3%);
}
.fade-enter-active, .fade-leave-active {
  transition: 0.2s ease;
}
.fade-enter-to, .fade-leave {
  opacity: 1;
  transform: translateY(0);
}
</style>
