import { Client } from '@/services';
import { SET_CLIENTS } from '@/store/mutation-types';
import { convertBackhaulToWanMacAddress } from '@/common/miroClient';
import { checkMacAddressValid } from '@/common/utilities';
import CLIENT_STATUS from '@/enums/ClientStatus';

/**
 * Convert the suffix MAC address of Miro interface to an integer
 *
 * The last five sets of the MAC address are the suffix MAC address
 * EX: The following two MAC addresses are belong to the same Miro device
 * - 00:00:00:00:00:00
 * - 01:00:00:00:00:00
 * @param {string} macAddress - MAC address of the Miro interface
 * @returns {number} Integer converted from the suffix MAC address
 */
function convertSuffixMacAddressToInteger(macAddress) {
  const DEFAULT_VALUE = 0;

  if (!checkMacAddressValid(macAddress)) {
    return DEFAULT_VALUE;
  }

  const RADIX = 16;
  const SUFFIX_MAC_ADDRESS_START_INDEX = 1;
  const splittedMacAddress = macAddress.split(':');
  const suffixMacAddressString = splittedMacAddress
    .slice(SUFFIX_MAC_ADDRESS_START_INDEX)
    .join('');

  return parseInt(suffixMacAddressString, RADIX);
}

/**
 * Get the list of clients exclude the Miro clients.
 * @param {Object[]} clients - List of clients from user devices
 * @param {Object[]} miroClients - List of clients from Miro devices
 * @returns {Object[]} List of clients exclude the Miro clients.
 */
function getClientsExcludeMiro(clients, miroClients) {
  const miroSuffixMacAddressIntegerList = miroClients
    .flatMap((miroClient) => {
      const MIRO_DEVICE_INTERFACE_AMOUNT = 5;
      const wanMacAddress = convertBackhaulToWanMacAddress(miroClient.mac_bh);
      const suffixMacAddressInteger = convertSuffixMacAddressToInteger(wanMacAddress);
      const suffixMacAddressIntegerList = [];

      for (let i = 0; i < MIRO_DEVICE_INTERFACE_AMOUNT; i++) {
        suffixMacAddressIntegerList.push(suffixMacAddressInteger + i);
      }

      return suffixMacAddressIntegerList;
    });

  return clients.filter((client) => {
    const suffixMacAddressInteger = convertSuffixMacAddressToInteger(client.macAddr);

    return !miroSuffixMacAddressIntegerList.includes(suffixMacAddressInteger);
  });
}

const state = {
  clients: [],
};

const actions = {
  async getClients({
    commit,
    dispatch,
    rootGetters,
    rootState,
  }) {
    if (rootGetters['Profile/supportMesh']) {
      const [clients] = await Promise.all([
        Client.getClients(),

        // TODO: Remove it when backend implement it
        dispatch('MIRO/getMiroListAll', null, { root: true }),
      ]);
      const meshClients = getClientsExcludeMiro(clients, rootState.MIRO.miroList);

      commit(SET_CLIENTS, meshClients);
    } else {
      const clients = await Client.getClients();

      commit(SET_CLIENTS, clients);
    }
  },
};

const getters = {
  onlineClients(state) {
    return state.clients.filter((client) => (client.status === CLIENT_STATUS.CONNECTED));
  },

  offlineClients(state) {
    return state.clients.filter((client) => (client.status !== CLIENT_STATUS.CONNECTED));
  },
};

const mutations = {
  [SET_CLIENTS](state, clients) {
    state.clients = clients;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
