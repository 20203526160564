import SERVICES from '@/services';

const state = {
  upnpInfo: {},
  upnpService: {},
  upnpWanStatus: 0,
};

const mutations = {
  setUpnp(state, payload) {
    state.upnpInfo = payload;
  },
  setUpnpService(state, payload) {
    state.upnpService = payload;
  },
  setUpnpWan(state, payload) {
    state.upnpWanStatus = payload.status;
  },
};

const actions = {
  async getUpnp({ commit }) {
    const res = await SERVICES.UPNP.getUpnp();

    commit('setUpnp', res.result);
  },
  async getUpnpService({ commit }) {
    const res = await SERVICES.UPNP.getUpnpService();

    commit('setUpnpService', res.result);
  },
  async getUpnpWan({ commit }) {
    const res = await SERVICES.UPNP.getUpnpWan();

    commit('setUpnpWan', res.result);
  },
  async putUpnp({ dispatch, commit }, payload) {
    await SERVICES.UPNP.putUpnp(payload);
    await dispatch('getAllUpnpInfo');
  },

  async getAllUpnpInfo({ dispatch }) {
    await Promise.all([
      dispatch('getUpnp'),
      dispatch('getUpnpWan'),
      dispatch('getUpnpService'),
    ]);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
