import apiPath from './apiPath';
import { qFetch } from './utilities';

export default {
  // miro
  async getMiroList(payload) {
    return qFetch.get(`${apiPath.MiroList}?page=${payload.page}&limit=${payload.limit}`);
  },
  async getMiroListAll(payload) {
    return qFetch.get(apiPath.MiroList);
  },
  async patchMiro(payload) {
    return qFetch.patch(`${apiPath.MiroList}/${payload.id}`, payload.data);
  },
  async postSatellite(payload) {
    return qFetch.post(apiPath.Satellite, payload);
  },
  async getSatellite() {
    return qFetch.get(apiPath.Satellite);
  },
  async postWirelessLocation(payload) {
    return qFetch.post(apiPath.WirelessLocation, payload);
  },
};
