<template>
  <ModalConfirm
    :id="id"
    type="warning"
  >
    <template #message>
      <div class="fz-18 fw-bold">
        {{ $t('ID_FIRMWARE_UPGRADE_BACKUP_FAILED_TITLE') }}
      </div>
      <div class="mt-5-px">
        {{
          retryBackupCount < BACKUP_RETRIES_MAXIMUM
            ? $t('ID_FIRMWARE_UPGRADE_BACKUP_FAILED_MSG')
            : $t('ID_FIRMWARE_UPGRADE_BACKUP_RETRY_FAILED_MSG')
        }}
      </div>
    </template>
    <template #modal-footer>
      <ButtonText
        v-if="retryBackupCount < BACKUP_RETRIES_MAXIMUM"
        label="ID_FIRMWARE_UPGRADE_BACKUP_RETRY"
        theme="secondary"
        @click="handleContinueUpgrade(false)"
      />
      <ButtonText
        v-else
        label="ID_DOWNLOAD"
        theme="secondary"
        @click="downloadSystemBackup"
      />
      <ButtonText
        label="ID_FIRMWARE_UPGRADE_BACKUP_SKIP"
        theme="secondary"
        class="ml-10-px"
        @click="handleContinueUpgrade(true)"
      />
    </template>
  </ModalConfirm>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import FirmwareUpgrade from '@/mixins/FirmwareUpgrade';
import SystemBackup from '@/mixins/SystemBackup';
import ButtonText from '@/components/Button/ButtonText.vue';
import ModalConfirm from '@/components/Modal/ModalConfirm.vue';

export default {
  name: 'FirmwareBackupFailedConfirmModal',
  components: {
    ModalConfirm,
    ButtonText,
  },
  mixins: [FirmwareUpgrade, SystemBackup],
  data() {
    return {
      id: 'firmware-restore-failed-confirm',
      BACKUP_RETRIES_MAXIMUM: 2,
    };
  },
  computed: {
    ...mapState('Firmware', ['retryBackupCount']),
  },
  methods: {
    ...mapActions('Firmware', ['continueUpgrade']),

    /**
     * Handle continuing the firmware upgrade.
     * @param {boolean} isBackupSkipped - If true, skip the backup step for the firmware upgrade
     * @returns {Promise<void>}
     */
    async handleContinueUpgrade(isBackupSkipped) {
      this.$modal.hide(this.id);
      this.$loading.show({
        text: 'ID_MIROS_WAITING_AUTO_UPDATE',
      });

      try {
        await this.handleFirmwareUpdate(() => this.continueUpgrade(isBackupSkipped));
      } catch (error) {
        this.handleFirmwareUpgradeError(error);
      } finally {
        this.$loading.hide();
      }
    },
  },
};
</script>
