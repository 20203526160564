import SERVICES from '@/services';
import {
  SET_PARENTAL_CONTROL_CLIENTS,
  SET_ROLES,
  START_EDITING,
} from '@/store/mutation-types';

const storeState = {
  roles: [],
  clients: [],
  isEditing: false,
  editRole: {},
};

const mutations = {
  [SET_PARENTAL_CONTROL_CLIENTS](state, clients) {
    state.clients = clients;
  },
  [SET_ROLES](state, roles) {
    state.roles = roles;
  },
  [START_EDITING](state, roleId) {
    if (roleId === null) {
      state.isEditing = false;
      state.editRole = {};
    } else {
      state.isEditing = true;
      state.editRole = state.roles.find((role) => role.id === roleId);
    }
  },
};

const actions = {
  async getClients({ commit }) {
    const res = await SERVICES.PARENTALCONTROL.getClientList();

    commit(SET_PARENTAL_CONTROL_CLIENTS, res.result);
  },
  async getRoles({ commit }, payload) {
    const roles = await SERVICES.PARENTALCONTROL.getRoles();

    commit(SET_ROLES, roles);
  },
  async createRole({ dispatch, commit }, payload) {
    await SERVICES.PARENTALCONTROL.createRole(payload);
    await dispatch('getRoles');
  },
  async setRoleStatus({ dispatch, commit }, payload) {
    await SERVICES.PARENTALCONTROL.setRoleStatus(payload.id, payload.status);
    await dispatch('getRoles');
  },
  async updateRole({ dispatch, commit }, { id, data }) {
    await SERVICES.PARENTALCONTROL.updateRole(id, data);
    await dispatch('getRoles');
  },
  async updateRoleClients({ dispatch, commit }, payload) {
    await SERVICES.PARENTALCONTROL.updateRoleClients(payload.id, payload.data);
    await dispatch('getRoles');
    await dispatch('getClients');
  },
  async deleteRole({ dispatch, commit }, id) {
    await SERVICES.PARENTALCONTROL.deleteRole(id);
    await dispatch('getRoles');
  },
  async deleteRoleClient({ dispatch, commit }, { roleId, macAddress }) {
    await SERVICES.PARENTALCONTROL.deleteRoleClient(roleId, macAddress);
    await dispatch('getRoles');
  },
};

export default {
  namespaced: true,
  state: storeState,
  actions,
  mutations,
};
