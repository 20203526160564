const state = {
  loading: false,
  key: 0,
  captureStatus: '',
  remainTime: 0,
  captureEnums: {
    status: {
      stop: 'Stop',
      run: 'Run',
    },
  },
};

const mutations = {
  setCaptureStatus(state, captureStatus) {
    state.captureStatus = captureStatus;
  },
  showProgress(state, remainTime) {
    if (!state.loading) {
      state.key += 1;
    }
    state.loading = true;
    state.remainTime = remainTime || 0;
  },
  hideProgress(state) {
    state.loading = false;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
