
<InputValidationBase
  v-slot="{ validated, valid }"
  :rules="rules"
  :disabled="innerDisabled"
  :immediate="immediate"
  :invalid-message="invalidMessage"
  class="df fd-column ai-start"
  :value="value"
>
  <q-row
    v-if="$slots.behind || short"
    class="w-full"
    align-v="center"
  >
    <q-col
      cols="6"
      class="q-input-col-main"
    >
      <div class="q-input-wrapper">
        <slot
          :input-class="getInputClass(validated, valid)"
          :input-disabled="innerDisabled"
        />
        <LoadingSpinner
          v-if="loading"
          class="loading-icon"
          :width="24"
          :height="24"
        />
      </div>
    </q-col>
    <q-col
      cols="6"
      class="q-input-col-after"
    >
      <slot name="behind" />
    </q-col>
  </q-row>
  <div
    v-else
    class="q-input-wrapper"
  >
    <slot
      :input-class="getInputClass(validated, valid)"
      :input-disabled="innerDisabled"
    />
    <LoadingSpinner
      v-if="loading"
      class="loading-icon"
      :width="24"
      :height="24"
    />
  </div>
</InputValidationBase>
