<template>
  <transition
    name="fade"
    appear
  >
    <div
      v-if="visible"
      :key="wrapperKey"
      class="q-loading-wrapper"
      :class="{ 'q-loading-wrapper-hide-backdrop': !showBackdrop }"
    >
      <div class="q-loading-container">
        <div
          class="q-loading-content"
          :class="{ 'q-loading-cancelable': !!cancel }"
        >
          <div
            class="q-loading-spinner-wrapper"
            :class="{ 'has-detail': !!detail }"
          >
            <LoadingSpinner />
          </div>
          <div
            style="min-height: 30px"
            class="df fd-column jc-center"
          >
            <div class="fz-17">
              {{ $t(text) }}
            </div>
            <div
              v-if="detail"
              class="fz-14 q-loading-detail"
            >
              {{ $t(detail) }}
            </div>
          </div>
          <div
            v-if="!!cancel"
            class="pl-25-px"
          >
            <ButtonText
              label="ID_CANCEL"
              class="q-loading-cancel-button"
              @click="handleCancel"
            />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ButtonText from '@/components/Button/ButtonText.vue';
import LoadingSpinner from './LoadingSpinner.vue';

export default {
  name: 'LoadingBase',
  components: {
    LoadingSpinner,
    ButtonText,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    wrapperKey: {
      type: [String, Number],
      default: '__DEFAULT_KEY__',
    },
    text: {
      type: String,
      default: '',
    },
    detail: {
      type: String,
      default: '',
    },
    showBackdrop: {
      type: Boolean,
      default: false,
    },
    cancel: {
      type: Function,
      default: null,
    },
  },
  methods: {
    async handleCancel() {
      await this.cancel();
    },
  },
};
</script>

<style lang="scss" scoped>
.q-loading-cancel-button {
  padding: rem(4px 16px);
  background-color: #0048b7 !important;
  border-radius: rem(3px);

  &:hover {
    background-color: #0042a7 !important;
  }

  &:active {
    background-color: #003587 !important;
  }
}

.q-loading-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: color("black", 0.75);

  &.q-loading-wrapper-hide-backdrop {
    pointer-events: none;
    background-color: transparent;
  }
}

.q-loading-container {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: rem(600px);
  margin: rem(20px);
}

.q-loading-content {
  display: flex;
  justify-content: center;
  min-width: rem(200px);
  max-width: 100%;
  padding: rem(20px 60px 20px 30px);
  color: loading-color('text');
  pointer-events: auto;
  background-color: loading-color('background');
  border-radius: rem(6px);

  &.q-loading-cancelable {
    padding-right: rem(25px);
  }
}

.fade-enter, .fade-leave-to {
  opacity: 0;
  transform: translateY(-3%);
}
.fade-enter-active, .fade-leave-active {
  transition: 0.2s ease;
}
.fade-enter-to, .fade-leave {
  opacity: 1;
  transform: translateY(0);
}

.q-loading-detail {
  color: #86c4f0;
}

.q-loading-spinner-wrapper {
  flex-grow: 0;
  padding-right: rem(25px);

  &.has-detail {
    padding-top: rem(8px);
  }
}
</style>
