<template>
  <LoadingBase
    id="app-loading"
    :visible="visible"
    :text="displayText"
    :detail="detail"
    :show-backdrop="showBackdrop"
    :wrapper-key="key"
    :cancel="cancel ? handleCancel : null"
  />
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import LoadingBase from '@/components/Loading/LoadingBase.vue';

export default {
  name: 'AppLoading',
  components: {
    LoadingBase,
  },
  computed: {
    ...mapState('Plugin/Loading', ['visible', 'key', 'detail', 'showBackdrop', 'cancel']),
    ...mapGetters('Plugin/Loading', ['displayText']),
  },
  methods: {
    async handleCancel() {
      await this.cancel();
      this.$loading.hide();
    },
  },
};
</script>
