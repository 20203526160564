const storeState = {
  occupiedPortsStatus: [],
};

const mutations = {
  setOccupiedPortsStatus(state, occupiedPortsStatus) {
    occupiedPortsStatus.forEach((status) => status.occupiedIfNames.sort());
    state.occupiedPortsStatus = occupiedPortsStatus
      .sort((item1, item2) => parseInt(item1.portName, 10) - parseInt(item2.portName, 10));
  },
};

export default {
  namespaced: true,
  state: storeState,
  mutations,
};
