import { TermsOfUse } from '@/services';
import { SET_TERMS_OF_USE_INFO } from '@/store/mutation-types';
import {
  checkNewTermsOfUseVersion,
  setNewTermsOfUseVersionState,
  setShouldCheckTermsOfUseVersionState,
} from '@/common/termsOfUse';

const state = {
  consentedVersion: '',
  officialSupportedLanguages: [],
  termsOfUseOfficialVersion: '',
  shouldCheckVersion: true,
};

const getters = {
  hasNewTermsOfUseVersion(state) {
    const hasNewVersion = checkNewTermsOfUseVersion(
      state.consentedVersion,
      state.termsOfUseOfficialVersion,
    );

    setNewTermsOfUseVersionState(hasNewVersion);

    return hasNewVersion;
  },
};

const mutations = {
  [SET_TERMS_OF_USE_INFO](state, termsOfUseInfo) {
    state.consentedVersion = termsOfUseInfo.consentedVersion || '';
    state.officialSupportedLanguages = termsOfUseInfo.officialSupportedLanguages || [];
    state.shouldCheckVersion = termsOfUseInfo.shouldCheckVersion ?? true;
    state.termsOfUseOfficialVersion = termsOfUseInfo.officialVersion
      || termsOfUseInfo.localOfficialVersion;

    setShouldCheckTermsOfUseVersionState(state.shouldCheckVersion);
  },
};

const actions = {
  async fetchTermsOfUseInfo({ commit }) {
    const termsOfUseInfo = await TermsOfUse.getTermsOfUseInfo();

    commit(SET_TERMS_OF_USE_INFO, termsOfUseInfo);
  },

  async patchTermsOfUseInfo({ commit, dispatch }, payload) {
    await TermsOfUse.patchTermsOfUseInfo(payload);

    // Change the state here instead of depending on the "hasNewTermsOfUseVersion"
    // Reason: The state would be used in the router guard
    ///        which would use the state before invoking hasNewTermsOfUseVersion in component
    setNewTermsOfUseVersionState(false);
    await dispatch('fetchTermsOfUseInfo');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
