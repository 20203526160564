<template>
  <ButtonBase
    v-q-tooltip="label"
    :aria-label="$t(label)"
    :type="type"
    class="q-button-icon"
    :class="icon"
    @click="$emit('click', $event)"
  />
</template>

<script>
import ButtonBase from './ButtonBase.vue';

export default {
  name: 'ButtonIcon',
  components: {
    ButtonBase,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'button',
      validator(type) {
        return ['button', 'submit'].includes(type);
      },
    },
    icon: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.q-button-icon {
  padding: 0;
  vertical-align: middle;
  background-position: center;

  &:disabled {
    opacity: 0.5;
  }

  &:active:not(:disabled) {
    opacity: 0.75;
  }
}
</style>
