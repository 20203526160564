import { Iptv } from '@/services';
import { mergeDataWithKeys } from '@/common/utilities';

const storeState = {
  iptvSetting: {},
  editedConfig: {},
  iptvConsts: {
    dataKeys: [
      'igmpDownstreamIface',
      'iptvPorts',
      'ispProfileName',
      'mode',
      'vlanSetting',
      'voipPorts',
      'wanIface',
    ],
  },
  iptvEnums: {
    ispProfileName: {
      bridge: 'bridge',
      manual: 'manual',
      movistarIgmp: 'movistarIgmp',
    },
    mode: {
      disable: 'disable',
      igmpProxy: 'igmpProxy',
      ispProfile: 'ispProfile',
    },
  },
};

const storeGetters = {
  originalConfig(state) {
    return state.iptvSetting;
  },
  isIgmpProxy: (state) => (config) => {
    if (config.mode === state.iptvEnums.mode.igmpProxy) {
      return true;
    }

    return config.mode === state.iptvEnums.mode.ispProfile
      && config.ispProfileName === state.iptvEnums.ispProfileName.movistarIgmp;
  },

  iptvBridge(state, getters, rootState) {
    if (state.iptvSetting.mode === state.iptvEnums.mode.ispProfile
      && state.iptvSetting.ispProfileName === state.iptvEnums.ispProfileName.bridge) {
      return rootState.Network.Bridge.bridges
        .find((bridge) => bridge.bridgeId === state.iptvSetting.wanIface) ?? null;
    }

    return null;
  },
};

const mutations = {
  initIptvSetting(state, config) {
    // give default value to properties not exist
    const iptvSetting = {
      igmpDownstreamIface: '',
      iptvPorts: [],
      ispProfileName: state.iptvEnums.ispProfileName.bridge,
      mode: state.iptvEnums.mode.disable,
      vlanSetting: {
        iptv: {
          priority: 0,
          vlanId: null,
        },
        voip: {
          priority: 0,
          vlanId: null,
        },
        wan: {
          priority: 0,
          vlanId: null,
        },
        wanTagged: true,
      },
      voipPorts: [],
      wanIface: '',
      ...config,
    };

    state.iptvSetting = iptvSetting;
  },
  initEditingState(state) {
    state.editedConfig = mergeDataWithKeys(state.iptvConsts.dataKeys, state.iptvSetting, {});
  },
  modifyEditedConfig(state, { key, value, target }) {
    target = target || state.editedConfig;
    target[key] = value;

    if (target === state.editedConfig && key === 'mode') {
      target.iptvPorts = [];
      target.voipPorts = [];
    }
  },
};

const actions = {
  async getIptvSetting({ commit, rootGetters }) {
    if (!rootGetters['Profile/supportIptv']) {
      return;
    }

    const res = await Iptv.getIptvSetting();

    commit('initIptvSetting', res.result);
  },
  async patchCurrentIptvSetting({ state, getters }) {
    const config = { mode: state.editedConfig.mode };
    const mergeKeys = [];

    if (config.mode !== state.iptvEnums.mode.disable) {
      mergeKeys.push('wanIface');

      if (config.mode === state.iptvEnums.mode.ispProfile) {
        config.ispProfileName = state.editedConfig.ispProfileName;

        if (config.ispProfileName === state.iptvEnums.ispProfileName.manual) {
          mergeKeys.push('vlanSetting', 'iptvPorts', 'voipPorts');
        } else if (config.ispProfileName === state.iptvEnums.ispProfileName.bridge) {
          mergeKeys.push('iptvPorts');
        } else if (config.ispProfileName === state.iptvEnums.ispProfileName.movistarIgmp) {
          mergeKeys.push('igmpDownstreamIface');
        }
      } else if (config.mode === state.iptvEnums.mode.igmpProxy) {
        mergeKeys.push('igmpDownstreamIface');
      }
    }
    mergeDataWithKeys(mergeKeys, state.editedConfig, config);

    return Iptv.patchIptvSetting(config);
  },
  async clearIptvSetting({ state }) {
    const config = { mode: state.iptvEnums.mode.disable };

    return Iptv.patchIptvSetting(config);
  },
};

export default {
  namespaced: true,
  state: storeState,
  getters: storeGetters,
  actions,
  mutations,
};
