import { cloneDeep } from 'lodash';
import { Nat } from '@/services';
import { mergeDataWithKeys } from '@/common/utilities';

const storeState = {
  dmzRules: [],
  editedDmzRules: [],
  editedWanInterface: null,
  dmzConsts: {
    ruleDataKeys: [
      'wanInterfaceID',
      'hostIp',
      'enabled',
    ],
  },
};

const storeGetters = {
  findDmzRule: (state) => (wanInterface, srcRules) => {
    srcRules = srcRules || state.dmzRules;
    const dmzRule = srcRules.find((rule) => rule.wanInterfaceID === wanInterface);

    return dmzRule || null;
  },
  editedDmzRule(state, getters) {
    return getters.findDmzRule(state.editedWanInterface, state.editedDmzRules);
  },
  editedOriginalDmzRule(state, getters) {
    return getters.findDmzRule(state.editedWanInterface);
  },
};

const mutations = {
  initDmzRules(state, dmzRules) {
    state.editedDmzRules = cloneDeep(dmzRules);
    state.dmzRules = dmzRules;
  },
  startEditing(state, wanInterface) {
    state.editedWanInterface = wanInterface;
  },
  endEditing(state) {
    state.editedWanInterface = null;
  },
  modifyDmzRule(state, { target, editedConfig }) {
    mergeDataWithKeys(state.dmzConsts.ruleDataKeys, editedConfig, target);
  },
};

const actions = {
  async getDmzRules({ commit }) {
    const dmzRes = await Nat.getDmz();

    commit('initDmzRules', dmzRes.result);
  },
  async toggleDmzRuleEnabled({ state, getters, dispatch }, wanInterface) {
    const targetRule = getters.findDmzRule(wanInterface);
    const newRule = mergeDataWithKeys(state.dmzConsts.ruleDataKeys, targetRule, {});

    newRule.enabled = !newRule.enabled;
    await dispatch('putDmzRule', newRule);
  },
  async putEditedDmzRule({ getters, dispatch }) {
    await dispatch('putDmzRule', getters.editedDmzRule);
  },
  async deleteDmzRule({ state }, wanInterface) {
    await Nat.deleteDmz(wanInterface);
  },
  async putDmzRule({ state }, dmzRule) {
    await Nat.putDmz([dmzRule]);
  },
  applyEditing({ getters, commit }, editedConfig) {
    const target = getters.editedDmzRule;

    commit('modifyDmzRule', { target, editedConfig });
  },
};

export default {
  namespaced: true,
  state: storeState,
  getters: storeGetters,
  mutations,
  actions,
};
