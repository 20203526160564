import apiPath from '../apiPath';
import { qFetch } from '../utilities';

export default {
  /**
   * Get system machine info.
   * @returns {Promise<Object>}
   */
  async getMachineInfo() {
    return qFetch.get(apiPath.v2.MachineInfo);
  },
  /**
   * Get whether password is default
   * @returns {Promise<Object>}
   */
  async getPasswordDefault() {
    return qFetch.get(apiPath.v2.PasswordDefault);
  },
  /**
   * Set time zone.
   * @returns {Promise<Object>}
   */
  async patchTimeZone() {
    return qFetch.patch(apiPath.v2.MachineInfo, {
      data: {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    });
  },
  /**
   * Finish initialization.
   * @returns {Promise<Object>}
   */
  async postFinishInitial() {
    return qFetch.post(apiPath.v2.FinishInitial);
  },
};
