import { cloneDeep } from 'lodash';
import { Network } from '@/services';
import { handleLanRequest } from '@/services/utilities/lan';
import { handleWanRequest, handleWanResponse } from '@/services/utilities/wan';
import { isIptvBridge } from '@/common/bridge';
import { IPTV_INTERFACE_NAME_DISPLAY } from '@/common/consts';
import { mergeDataWithKeys } from '@/common/utilities';
import BRIDGE_TYPE from '@/enums/BridgeType';
import IPTV_LABEL from '@/enums/IptvLabel';
import LAN_DEFAULT_GATEWAY from '@/enums/LanDefaultGateway';
import LAN_DHCP_SERVICE_TYPE from '@/enums/LanDhcpServiceType';
import LAN_IP4_TYPE from '@/enums/LanIp4Type';
import LAN_IP6_ADDR_AUTO_CONFIG_TYPE from '@/enums/LanIp6AddrAutoConfigType';
import LAN_IP6_INTERFACE_ID_TYPE from '@/enums/LanIp6InterfaceIdType';
import LAN_IP6_TYPE from '@/enums/LanIp6Type';
import NETWORK_INTERFACE from '@/enums/NetworkInterface';
import WAN_ADV_IP6_REGION from '@/enums/WanAdvIp6Region';
import WAN_IP4_TYPE from '@/enums/WanIp4Type';
import WAN_IP6_TYPE from '@/enums/WanIp6Type';

const storeState = {
  bridges: [],
  editedBridges: [],
  bridgesStatus: [],
  editedConfig: {},
  addedBridge: null,
  bridgeConsts: {
    dataKeys: [
      'description',
      'enabled',
      'labels',
      'wan',
      'lan',
      'name',
      'type',
      'bridgeId',
      'interfaces',
      'lineRateTx',
      'lineRateRx',
    ],
    defaultBridgeBridgeId: 'br_v0',
  },
};

function createDefaultBridgeWan() {
  return {
    advIp6Region: WAN_ADV_IP6_REGION.GLOBAL,
    dialOnDemand: false,
    dslite: {
      aftrAddress: '',
      b4Address: '',
    },
    ip4Address: '',
    ip4DnsServers: [],
    ip4Gateway: '',
    ip4Prefix: 24,
    ip4Type: WAN_IP4_TYPE.DHCP,
    ip6Address: '',
    ip6DnsServers: [],
    ip6Gateway: '',
    ip6PdEnabled: true,
    ip6Prefix: 64,
    ip6Type: WAN_IP6_TYPE.DISABLED,
    mtu: 1500,
    password: '',
    username: '',
  };
}

function createDefaultBridgeLan() {
  return {
    dhcpService: {
      dnsServers: [],
      endIp: '',
      leaseTime: 86400,
      relayServers: [],
      reservedIps: [],
      serviceType: LAN_DHCP_SERVICE_TYPE.DISABLED,
      startIp: '',
      defaultGatewayType: LAN_DEFAULT_GATEWAY.AUTO,
      defaultGatewayIp: '',
    },
    enableStp: true,
    ip4Address: '',
    ip4Prefix: 24,
    ip4Type: LAN_IP4_TYPE.STATIC,
    ip6AddrAutoConfig: {
      autoConfigType: LAN_IP6_ADDR_AUTO_CONFIG_TYPE.DISABLED,
      dnsServers: [],
      endIp: '',
      leaseTime: 86400,
      lifetime: 3600,
      startIp: '',
    },
    ip6Address: '',
    ip6InterfaceIdType: LAN_IP6_INTERFACE_ID_TYPE.EUI64,
    ip6PdPrefix: '',
    ip6Prefix: 64,
    ip6Type: LAN_IP6_TYPE.DISABLED,
    ip6Uplink: '',
    mtu: 1500,
  };
}

function createDefaultBridge() {
  return {
    description: '',
    enabled: true,
    labels: [],
    interfaces: [],
    wan: createDefaultBridgeWan(),
    lan: createDefaultBridgeLan(),
    name: '',
    type: BRIDGE_TYPE.LAN,
    bridgeId: '',
    lineRateTx: 0,
    lineRateRx: 0,
  };
}

const storeGetters = {
  isAddMode(state) {
    return state.addedBridge !== null;
  },
  editedBridge(state, getters) {
    if (getters.isAddMode) {
      return state.addedBridge;
    }

    return state.editedBridges.find((bridge) => bridge.bridgeId === state.editedConfig.bridgeId)
      ?? null;
  },
  editedOriginalBridge(state, getters) {
    if (getters.isAddMode) {
      return createDefaultBridge();
    }

    return state.bridges.find((bridge) => bridge.bridgeId === state.editedConfig.bridgeId) ?? null;
  },
  isEditingIptvBridge(state, getters) {
    return getters.editedBridge ? isIptvBridge(getters.editedBridge) : false;
  },
  findBridgeStatus: (state) => (bridgeId) => {
    const bridgeStatus = state.bridgesStatus.find((status) => status.bridgeId === bridgeId);

    return bridgeStatus ?? { wan: {} };
  },
  getBridgeDisplayText: (state) => (bridgeId) => {
    const bridge = state.bridges.find((item) => item.bridgeId === bridgeId);

    return bridge?.name ?? '';
  },
  enabledBridges(state) {
    return state.bridges.filter((item) => item.enabled);
  },
  enabledBridgesStatus(state) {
    return state.bridgesStatus.filter((item) => state.bridges
      .find((bridge) => bridge.bridgeId === item.bridgeId)?.enabled);
  },
  handleBridgeRequest: (state, getters, rootState, rootGetters) => (config) => {
    config = cloneDeep(config);
    delete config.bridgeId;

    if (config.type === BRIDGE_TYPE.WAN) {
      delete config.lan;
      config.wan = handleWanRequest(config.wan);
    } else if (config.type === BRIDGE_TYPE.LAN) {
      delete config.wan;
      config.lan = handleLanRequest(config.lan, config.enabled);
    }
    config.name = rootGetters['Network/getInterfaceOriginalName'](config.name);

    return config;
  },

  editedConfigInvalidMessage(state, getters, rootState, rootGetters) {
    const config = state.editedConfig;

    if (config.bridgeId === undefined) {
      return '';
    }
    const isLast = rootGetters['Network/checkLanInterfaceNotLast'](config.bridgeId) !== true;

    if (isLast && config.interfaces.length === 0) {
      return 'ID_LAN_INTERFACE_AT_LEAST_ONE_MSG';
    }

    return '';
  },
};

const mutations = {
  modifyEditedConfig(state, { key, value, target }) {
    target = target || state.editedConfig;
    target[key] = value;
  },
  initEditedState(state, bridgeId) {
    const editedBridge = state.editedBridges.find((bridge) => bridge.bridgeId === bridgeId);

    state.editedConfig = mergeDataWithKeys(state.bridgeConsts.dataKeys, editedBridge, {});
  },
  resetEditedState(state) {
    state.editedConfig = {};
    state.addedBridge = null;
  },
  initAddBridge(state) {
    state.editedConfig = createDefaultBridge();
    state.addedBridge = createDefaultBridge();
  },
  modifyBridge(state, { target, editedConfig }) {
    mergeDataWithKeys(state.bridgeConsts.dataKeys, editedConfig, target);
  },

  initBridges(state, bridges) {
    bridges.forEach((bridge) => {
      if (!bridge.wan) {
        bridge.wan = createDefaultBridgeWan();
      }
      bridge.wan = handleWanResponse(bridge.wan);

      if (!bridge.lan) {
        bridge.lan = createDefaultBridgeLan();
      }

      bridge.interfaces.sort((item1, item2) => parseInt(item1, 10) - parseInt(item2, 10));

      if (bridge.labels.includes(IPTV_LABEL.IPTV)) {
        bridge.name += IPTV_INTERFACE_NAME_DISPLAY.IPTV;
      }
    });
    state.editedBridges = cloneDeep(bridges);
    state.bridges = bridges;
  },
  resetEditedBridges(state) {
    state.editedBridges = cloneDeep(state.bridges);
  },
  setBridgesStatus(state, bridgesStatus) {
    state.bridgesStatus = bridgesStatus;
  },
};

const actions = {
  initEditStore({ state, getters, commit }) {
    const bridgeId = state.editedConfig.bridgeId || null;

    commit('Network/WanConfig/startEditing', {
      interfaceId: bridgeId,
      mode: NETWORK_INTERFACE.BRIDGE,
      editedConfig: state.editedConfig.wan,
      originalWanConfig: getters.editedOriginalBridge.wan,
    }, { root: true });
    commit('Network/LanConfig/startEditing', {
      interfaceId: bridgeId,
      mode: NETWORK_INTERFACE.BRIDGE,
      editedConfig: state.editedConfig.lan,
      originalLanConfig: getters.editedOriginalBridge.lan,
    }, { root: true });
  },
  startEditing({ commit, dispatch }, bridgeId) {
    commit('initEditedState', bridgeId);
    dispatch('initEditStore');
  },
  endEditing({ commit }) {
    commit('Network/LanConfig/endEditing', undefined, { root: true });
    commit('resetEditedState');
  },
  startAdding({ commit, dispatch }) {
    commit('initAddBridge');
    dispatch('initEditStore');
  },
  applyEditing({ state, getters, commit }, editedConfig) {
    const target = getters.isAddMode ? state.addedBridge : getters.editedBridge;

    commit('modifyBridge', { target, editedConfig });
  },

  async getBridges({ commit, rootGetters }) {
    if (!rootGetters['Profile/supportNetworkV3']) {
      return;
    }

    const bridges = await Network.getBridges();

    commit('initBridges', bridges);
  },
  async getBridgesStatus({ commit, rootGetters }) {
    if (!rootGetters['Profile/supportNetworkV3']) {
      return;
    }

    const res = await Network.getBridgesStatus();

    commit('setBridgesStatus', res.result);
  },
  async putBridge({ getters }, bridgeConfig) {
    const config = getters.handleBridgeRequest(bridgeConfig);

    try {
      await Network.putBridge(bridgeConfig.bridgeId, config);
    } catch (error) {
      // Ignore "faild to fetch"
      if (error.error_code !== 90000) {
        throw error;
      }
    }
  },
  async postBridge({ getters }, bridgeConfig) {
    const config = getters.handleBridgeRequest(bridgeConfig);

    await Network.postBridge(config);
  },
  /**
   * Add or edit Bridge
   * @param {Object} context - store context
   */
  async sendEditedBridge({ state, getters, dispatch }) {
    if (getters.isAddMode) {
      await dispatch('postBridge', state.addedBridge);
    } else {
      await dispatch('putBridge', getters.editedBridge);
    }
  },
  async removeBridgeInterface({ state, dispatch }, { bridgeId, interfaceId }) {
    const bridge = state.bridges.find((item) => item.bridgeId === bridgeId);

    if (bridge) {
      const newBridge = cloneDeep(bridge);
      const idx = bridge.interfaces.indexOf(interfaceId);

      if (idx > -1) {
        newBridge.interfaces.splice(idx, 1);
      }
      await dispatch('putBridge', newBridge);
    }
  },
};

export default {
  namespaced: true,
  state: storeState,
  getters: storeGetters,
  mutations,
  actions,
};
