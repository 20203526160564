
<ModalBase
  :id="id"
  :visible="visible"
  :title="title"
  :force="force"
  size="alert"
  header-theme="white"
  @hide="$emit('hide')"
  @cancel="$emit('cancel')"
  @after-hide="$emit('after-hide')"
>
  <div class="pl-15-px pr-10-px pt-10-px">
    <slot>
      <div
        v-if="message || $slots.message"
        class="df pr-25-px"
      >
        <div
          :class="imageClass"
          class="w-32-px h-32-px mr-20-px flex-shrink-0"
        />
        <div>
          <div
            v-if="messageTitle"
            class="mb-5-px fz-18 lh-27 fw-700"
          >
            {{ $t(messageTitle) }}
          </div>
          <slot
            name="message"
            :message="innerMessage"
          >
            <div
              v-if="Array.isArray(innerMessage)"
              class="space-y-15-px"
            >
              <div
                v-for="item in innerMessage"
                :key="item"
                class="ws-pl"
              >
                {{ item }}
              </div>
            </div>
            <span
              v-else
              class="ws-pl"
            >
              {{ innerMessage }}
            </span>
          </slot>
          <div
            v-if="$slots['message-extra']"
            class="pt-25-px"
          >
            <slot name="message-extra" />
          </div>
        </div>
      </div>
    </slot>
  </div>

  <template #modal-footer-wrapper="{ cancel }">
    <div class="df ai-center jc-end ptb-20-px plr-25-px">
      <slot
        name="modal-footer"
        :cancel="cancel"
      >
        <ButtonText
          :label="confirmLabel"
          :disabled="confirmDisabled"
          class="mr-10-px"
          theme="secondary"
          @click="confirm"
        />
        <ButtonText
          v-if="showCancelButton"
          :label="cancelLabel"
          theme="secondary"
          @click="cancel"
        />
      </slot>
    </div>
  </template>
</ModalBase>
