import { handleLocalAccountPasswordRequest } from '@/services/modules/utilities/auth';
import {
  setAuthorization,
  convertSecondToMillisecond,
  convertMillisecondToSecond,
} from '@/common/utilities';
import apiPath from './apiPath';
import { qFetch } from './utilities';

/**
 * Handle the account info from GUI to API
 *
 * @param {Object} account - Account info from GUI
 * @returns {Object} Formatted account info for API
 */
function handleLoginRequest(account) {
  const payload = {
    username: account.username,

    // Use the deprecated syntax unescape to support the UTF8-strings(chinese, other languages...)
    // Based on: https://stackoverflow.com/a/26603875
    // TODO: Find a better way to replace "unescape"
    password: window.btoa(unescape(encodeURIComponent(account.password))),

    // True when duplicated login or register local/QID account after login successful
    force: account.isForce || false,
    remember_me: account.isRememberMe || false,
    qid_login: account.isQid || false,
  };

  if (account.qurouterToken) {
    payload.qurouter_token = account.qurouterToken;
  }

  return payload;
}

/**
 * Handle the account info from API to GUI
 *
 * @param {Object} account - Account info from API
 * @returns {Object} Formatted account info for GUI
 */
function handleLoginResponse(account) {
  return {
    isFirstLoginQid: account.IsFirstLoginQID,
    IsLocalAccountDefaultCredential: account.IsLocalAccountDefaultCred,
    accessToken: account.access_token,
    hadSession: account.had_session,
    isQid: account.is_qid,
  };
}

export default {
  /**
   * Login with user account
   * Note: The login function is for the users who login from QuRouter device
   *
   * @param {Object} account - User account for login
   * @returns {Object} Formatted account status
   */
  async login(account) {
    const payload = handleLoginRequest(account);

    if (account.localAccount) {
      payload.local_account = {
        username: account.localAccount.username,

        // Use window.btoa because, local account only allows ASCII
        password: handleLocalAccountPasswordRequest(account.localAccount.password),
      };

      if (account.localAccount.oldPassword) {
        payload.local_account.old_password = handleLocalAccountPasswordRequest(
          account.localAccount.oldPassword,
        );
      }
    }

    const { result } = await qFetch.post(apiPath.login, payload);

    return handleLoginResponse(result);
  },

  /**
   * Login with user QID
   * Note: The login function is for the users login from QuRouter QPKG
   *
   * @param {Object} account - User account for login
   * @returns {Object} Formatted account status
   */
  async qidLogin(account) {
    const payload = handleLoginRequest(account);
    const { result } = await qFetch.post(apiPath.qidLogin, payload);

    return handleLoginResponse(result);
  },

  /**
   * Logout from server
   *
   * @returns {Promise<void>}
   */
  async logout() {
    qFetch.post(apiPath.logout);
  },

  /**
   * Get a new token with current token
   * @returns {Promise<void>}
   */
  async refreshToken() {
    const { result } = await qFetch.post(apiPath.v2.RefreshToken);

    setAuthorization(result.newToken);
  },

  /**
   * Get the time to allow the user to remain idle in the GUI
   * @returns {Promise<number>} Allowed idle time for user
   */
  async getAllowedIdleTime() {
    const { result } = await qFetch.get(apiPath.v2.IdleTime);

    return convertSecondToMillisecond(result.expireTime);
  },

  /**
   * Update the allowed idle time for the user
   * @param {number} allowedIdleTime - Allowed idle time to be updated
   * @returns {Promise<number>} Updated allowed idle time for the user
   */
  async updateAllowedIdleTime(allowedIdleTime) {
    const { result } = await qFetch.put(apiPath.v2.IdleTime, {
      data: {
        expireTime: convertMillisecondToSecond(allowedIdleTime),
      },
    });

    return convertSecondToMillisecond(result.expireTime);
  },
};
