import { Wireless } from '@/services';

const storeState = {
  wps: {},
};

const mutations = {
  initWps(state, wps) {
    state.wps = wps;
  },
  modifyWpsConfig(state, payload) {
    Object.entries(payload).forEach(([key, value]) => {
      state.wps[key] = value;
    });
  },
};

const actions = {
  async getWps({ commit }) {
    const wpsRes = await Wireless.getWps();

    commit('initWps', wpsRes.result);
  },
  async putCurrentWps({ state }) {
    await Wireless.putWps(state.wps);
  },
  async triggerWps() {
    await Wireless.postWpsTrigger();
  },
};

export default {
  namespaced: true,
  state: storeState,
  mutations,
  actions,
};
