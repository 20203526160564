
<transition
  name="fade"
  appear
>
  <div
    v-if="visible"
    id="app-toast"
    :key="key"
    class="q-toast-wrapper"
  >
    <div class="q-toast-container">
      <div class="q-toast-content">
        {{ $t(text) }}
      </div>
    </div>
  </div>
</transition>
