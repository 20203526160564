/* eslint-disable import/prefer-default-export */

import WAN_IP6_TYPE from '@/enums/WanIp6Type';

/**
 * Check enabled/disabled state for WAN interface config.
 * @param {Object} wan - WAN interface config
 * @returns {boolean} True if WAN interface config is enabled
 */
export function isWanIp6Enabled(wan) {
  return !!wan?.ip6Type && wan.ip6Type !== WAN_IP6_TYPE.DISABLED;
}
