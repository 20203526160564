import SERVICES from '@/services';
import * as types from '@/store/mutation-types';

const actions = {
  async fetchUserRules({ commit, rootGetters }) {
    if (!rootGetters['Dashboard/isInternetConnected']) {
      commit(types.SET_USER_RULES, []);

      return;
    }

    const userRules = await SERVICES.QOS.getQosUserRules();

    commit(types.SET_USER_RULES, userRules);
  },
  async fetchSegmentRules({ commit, rootGetters }) {
    if (!rootGetters['Dashboard/isInternetConnected']) {
      commit(types.SET_SEGMENT_RULES, []);

      return;
    }

    const segmentRules = await SERVICES.QOS.getQosSegmentRules();

    commit(types.SET_SEGMENT_RULES, segmentRules);
  },
  async fetchPriority({ commit, rootGetters }) {
    if (!rootGetters['Dashboard/isInternetConnected']) {
      commit(types.SET_PRIORITY_PERCENTAGES, []);

      return;
    }

    const priority = await SERVICES.QOS.getQosPriority();

    commit(types.SET_PRIORITY_PERCENTAGES, priority.percentages);
    commit(types.SET_REBOOT_REQUIRED, priority.rebootTag);
  },
  async fetchDpiApp({ commit, rootGetters }) {
    if (!rootGetters['Dashboard/isInternetConnected']) {
      commit(types.SET_DPI_APP, []);

      return;
    }

    const dpiApp = await SERVICES.QOS.getQunmsDpiApp();

    commit(types.SET_DPI_APP, dpiApp);
  },
  async fetchDpiClientOs({ commit, rootGetters }) {
    if (!rootGetters['Dashboard/isInternetConnected']) {
      commit(types.SET_DPI_CLIENT_OSS, []);

      return;
    }
    const clientOss = await SERVICES.QOS.getQunmsDpiClientOss();

    commit(types.SET_DPI_CLIENT_OSS, clientOss);
  },
};

const mutations = {
  [types.SET_USER_RULES](state, userRules) {
    state.userRules = userRules;
  },
  [types.SET_SEGMENT_RULES](state, segmentRules) {
    state.segmentRules = segmentRules;
  },
  [types.SET_PRIORITY_PERCENTAGES](state, priorityPercentages) {
    state.priorityPercentages = priorityPercentages;
  },
  [types.SET_REBOOT_REQUIRED](state, rebootTag) {
    state.rebootRequired = !!(rebootTag);
  },
  [types.SET_DPI_APP](state, dpiApp) {
    state.dpiApp = dpiApp;
  },
  [types.SET_DPI_CLIENT_OSS](state, clientOss) {
    state.clientOss = clientOss;
  },
};

const state = {
  userRules: null,
  segmentRules: null,
  priorityPercentages: null,
  rebootRequired: false,
  dpiApp: null,
  clientOss: null,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
